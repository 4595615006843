import { gql } from "graphql-request";
import type {
	FaceBoundingBox,
	TransportPictureConfiguration,
} from "@brickme/project-core/src";
import type { PageResult } from "~/utils/page-result.ts";

// Note: used both as a domain model and transport dto. Should split those maybe
type FullSavedProject = {
	readonly id: string;
	readonly sourceImageKey: string;
	readonly sourceImageUrl: string;
	readonly faceBoundingBoxes: readonly FaceBoundingBox[] | null | undefined;
	readonly backgroundMaskImageUrl: string | null | undefined;
	readonly colorisationImageUrl: string | null | undefined;
	readonly enhanceFacesImageUrl: string | null | undefined;
	readonly mediumImageUrl: string | null | undefined;
	readonly currentPicture: TransportPictureConfiguration & {
		readonly buildNumberOfColours: number;
	};
	readonly otherVersions: readonly TransportPictureConfiguration[];
	readonly updatedAt: string;
};

type GetSavedDesignsQuery = {
	readonly viewer: {
		readonly projectsImport: {
			readonly status: "running" | "complete";
		} | null;
		readonly savedDesigns: PageResult<FullSavedProject>;
	};
};

type GetSavedDesignsVariables = {
	readonly limit: number;
	readonly pageToken?: string;
};

const transportPictureOutputFragment = gql`
	fragment transportPictureConfigurationOutput on PictureConfiguration {
		updatedAt

		imageZoom
		imageZoomOffset {
			x
			y
		}

		basePlateSize
		numberOfBasePlates {
			width
			height
		}

		brightness
		contrast
		saturation

		detailFilter {
			sigma
			radiusRatio
		}

		removeBackground {
			newColour
		}
		enhanceFaces
		fixFaceColours

		paletteMode {
			type
			numberOfColours
			palette
		}

		pen {
			type
			value
		}
	}
`;

const transportSavedProjectFragment = gql`
	fragment transportSavedProject on SavedProject {
		id
		sourceImageKey
		sourceImageUrl
		backgroundMaskImageUrl
		colorisationImageUrl
		enhanceFacesImageUrl
		mediumImageUrl
		faceBoundingBoxes {
			x
			y
			width
			height
		}
		currentPicture {
			...transportPictureConfigurationOutput
			buildNumberOfColours
		}
		otherVersions {
			...transportPictureConfigurationOutput
		}
		updatedAt
	}
	${transportPictureOutputFragment}
`;

// TODO: Maybe some fragments for transportProjectOutput
// Seems like an error prone area, e.g. when adding a new field
const getSavedDesignsQuery = gql`
	query getSavedDesignsQuery($limit: Int!, $pageToken: String) {
		viewer {
			id
			projectsImport {
				status
			}
			savedDesigns(limit: $limit, pageToken: $pageToken) {
				nextToken
				items {
					...transportSavedProject
				}
			}
		}
	}
	${transportSavedProjectFragment}
`;

export type {
	FullSavedProject,
	GetSavedDesignsQuery,
	GetSavedDesignsVariables,
};
export { transportSavedProjectFragment, transportPictureOutputFragment };
export default getSavedDesignsQuery;
