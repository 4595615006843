/* eslint-disable no-param-reassign */
import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	numberOfBrickColours,
	corePictureToTransportPicture,
} from "@brickme/project-core/src";
import type {
	CompleteProjectMutation,
	CompleteProjectMutationVariables,
} from "~/api/complete-project-mutation.generated.ts";
import completeProjectMutation from "~/api/complete-project-mutation.graphql";
import type { State as WorkspaceState } from "~/features/workspace/state.ts";
import type { State as ReferenceState } from "~/features/reference/state.ts";
import type { State as SaveProjectState } from "~/features/save-project/store-slice.ts";
import type { State as UserState } from "~/features/user/store-slice.ts";
import { isSignedOutUserStatus } from "~/features/user/store-slice.ts";
import { getPossibleFrameVariantInStockForPicture } from "~/features/preview/frame.ts";
import { getAddOnVariantCompatibleWithPicture } from "~/features/preview/add-on.ts";
import { getNumberOfHangingStripPacksForPicture } from "~/features/preview/hanging-strips.ts";
import timeoutPromise from "~/utils/timeout-promise.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import type { OnceOffLoad } from "~/utils/loading.ts";
import {
	ensureLoadedOnceOffLoad,
	errorOnceOffLoad,
	isOnceOffLoaded,
	loadedOnceOffLoad,
	loadingOnceOffLoad,
} from "~/utils/loading.ts";
import createSerialisedBuild from "./create-serialised-build.ts";
import type { HangingOption } from "./hanging-option.ts";
import selectDefaultHangingType from "./select-default-hanging-type.ts";

const name = "order";

type AdjacentState = {
	readonly workspace: WorkspaceState;
	readonly reference: ReferenceState;
	readonly saveProject: SaveProjectState;
	readonly user: UserState;
	readonly order: State;
};

type CartItem = {
	readonly variantId: string;
	readonly quantity: number;
	readonly properties?: Record<string, string>;
};

async function getSignedInSourceImageName(
	getState: () => AdjacentState,
): Promise<string> {
	const {
		saveProject: { openedProjectSaveState },
	} = getState();
	// Still uploading, creating or updating. Wait for it
	if (
		openedProjectSaveState === undefined ||
		openedProjectSaveState.type === "uploadedSourceImage" ||
		openedProjectSaveState.type === "uploadingSourceImage" ||
		openedProjectSaveState.type === "creatingApiProject" ||
		openedProjectSaveState.type === "updatingToBackend"
	) {
		await timeoutPromise(500);
		return getSignedInSourceImageName(getState);
	}

	// Ordering of this conditional after the above one required for TS.
	// Probably because of the nested await
	if (
		openedProjectSaveState.type === "loadingApiProject" ||
		openedProjectSaveState.type === "createApiProjectError" ||
		openedProjectSaveState.type === "uploadSourceImageError"
	) {
		throw new Error(`Invalid state, got ${openedProjectSaveState.type}`);
	}

	return openedProjectSaveState.sourceImageKey;
}

async function getSignedOutSourceImageName(
	getState: () => AdjacentState,
): Promise<string> {
	const {
		saveProject: { openedProjectSaveState },
	} = getState();
	// Still uploading, wait for it
	if (
		openedProjectSaveState === undefined ||
		openedProjectSaveState.type === "uploadingSourceImage"
	) {
		await timeoutPromise(500);
		return getSignedOutSourceImageName(getState);
	}

	if (openedProjectSaveState.type !== "uploadedSourceImage") {
		throw new Error("Source image not uploaded");
	}
	return openedProjectSaveState.tempSourceKey;
}

async function getSourceImageName(
	getState: () => AdjacentState,
): Promise<string> {
	const {
		user: { user },
	} = getState();
	if (user.type === "signedIn" || user.type === "guest") {
		return getSignedInSourceImageName(getState);
	}
	if (isSignedOutUserStatus(user)) {
		return getSignedOutSourceImageName(getState);
	}
	throw new Error("Must be signed in");
}

const completeProject = createAsyncThunk<
	readonly CartItem[],
	undefined,
	StoreThunkApiConfig<AdjacentState>
>(`${name}/completeProject`, async (_, { extra: { apiClient }, getState }) => {
	const {
		reference: { systemPalette, preassemblyOptions, frames, hangingStrips },
		workspace: { openProject },
		order: { hangingOption, wantPreassembly },
	} = getState();
	if (!openProject) {
		throw new Error("No open project");
	}

	const systemPaletteData = ensureLoadedOnceOffLoad(
		systemPalette,
		"System palette not loaded",
	);

	const {
		build: openBuild,
		project: { currentPicture },
	} = openProject;
	if (openBuild.operationsMissingSources.length > 0) {
		throw new Error("Can't complete yet, still working");
	}

	// Frame
	const frame =
		isOnceOffLoaded(frames) && hangingOption?.type === "frame"
			? frames.data.find((f) => f.id === hangingOption.id)
			: undefined;
	const frameVariant = frame
		? getPossibleFrameVariantInStockForPicture(currentPicture, frame)
		: undefined;

	// Hanging strips
	const chosenHangingStrips =
		isOnceOffLoaded(hangingStrips) && hangingOption?.type === "hangingStrips"
			? hangingStrips.data
			: undefined;

	// Preassembly
	const preassemblies = isOnceOffLoaded(preassemblyOptions)
		? preassemblyOptions.data
		: [];
	const preassemblyVariant = wantPreassembly
		? getAddOnVariantCompatibleWithPicture(preassemblies, currentPicture)
		: undefined;

	const { build, palette } = createSerialisedBuild(
		systemPaletteData,
		openBuild.image,
		currentPicture.paletteMode,
	);
	const sourceImageName = await getSourceImageName(getState);
	const numberOfColours = numberOfBrickColours(openProject.build.brickCounts);
	const result = await apiClient.request<
		CompleteProjectMutation,
		CompleteProjectMutationVariables
	>(completeProjectMutation, {
		input: {
			sourceImageName,
			picture: corePictureToTransportPicture(currentPicture, numberOfColours),
			palette,
			build,
		},
	});
	if (!result) {
		throw new Error("There was an error creating the design");
	}

	const {
		completeProject: { shopifyProductVariantId, customProperties },
	} = result;

	return [
		{
			variantId: shopifyProductVariantId,
			quantity: 1,
			properties: customProperties
				? Object.fromEntries(
						customProperties.map(({ name, value }) => [name, value]),
					)
				: undefined,
		},
		...(frameVariant
			? [{ variantId: frameVariant.productVariantId, quantity: 1 }]
			: []),
		...(chosenHangingStrips
			? [
					{
						variantId: chosenHangingStrips.productVariantId,
						quantity: getNumberOfHangingStripPacksForPicture(currentPicture),
					},
				]
			: []),
		...(preassemblyVariant
			? [{ variantId: preassemblyVariant.productVariantId, quantity: 1 }]
			: []),
	];
});

type State = {
	readonly completion: OnceOffLoad<readonly CartItem[]>;
	readonly hangingOption?: HangingOption;
	readonly wantPreassembly: boolean;
};

const initialState: State = {
	completion: { type: "pending" },
	hangingOption: undefined,
	wantPreassembly: false,
};

const slice = createSlice({
	name,
	initialState,
	reducers: {
		setHangingOptionFrame: (
			state: Draft<State>,
			action: PayloadAction<string | undefined>,
		) => {
			if (action.payload) {
				state.hangingOption = {
					type: "frame",
					id: action.payload,
				};
			} else {
				state.hangingOption = undefined;
			}
		},
		removeHangingOption: (state: Draft<State>) => {
			state.hangingOption = undefined;
		},
		setHangingOption: (
			state: Draft<State>,
			action: PayloadAction<HangingOption | undefined>,
		) => {
			state.hangingOption = action.payload;
		},
		setHangingOptionHangingStrips: (state: Draft<State>) => {
			state.hangingOption = { type: "hangingStrips" };
		},
		setWantPreassembly: (
			state: Draft<State>,
			action: PayloadAction<boolean>,
		) => {
			state.wantPreassembly = action.payload;
		},
	},
	extraReducers: {
		[completeProject.pending.type]: (state: Draft<State>) => {
			state.completion = loadingOnceOffLoad;
		},
		[completeProject.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof completeProject)["fulfilled"]>>,
		) => {
			state.completion = loadedOnceOffLoad(action.payload);
		},
		[completeProject.rejected.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof completeProject)["rejected"]>,
		) => {
			state.completion = errorOnceOffLoad(
				action.error.message ?? "There was an error",
			);
		},
		[selectDefaultHangingType.fulfilled.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof selectDefaultHangingType)["fulfilled"]>,
		) => {
			state.hangingOption = action.payload;
		},
	},
});

export { completeProject };
export const {
	setHangingOptionFrame,
	setHangingOption,
	setWantPreassembly,
	setHangingOptionHangingStrips,
	removeHangingOption,
} = slice.actions;
export const initialOrderState = initialState;
export default slice.reducer;
