// In it's own file to prevent circular dependencies
import { createAsyncThunk } from "@reduxjs/toolkit";
import { captureMessage } from "@sentry/react";
import {
	createBrickedImageFromSourceNoCacheSync,
	loadBitmapFromUrl,
	createBitmapMaskFromBitmap,
	createFacesMask,
} from "@brickme/project-core/src";
import {
	ensureLoadedOnceOffLoad,
	loadedOnceOffLoad,
	pendingOnceOffLoad,
} from "~/utils/loading.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import type { State as ReferenceState } from "~/features/reference/state.ts";
import encodedImageArrayBufferToBitmap from "~/utils/encoded-image-array-buffer-to-bitmap.ts";
import type {
	OpenProject,
	CreatorProject,
	CreatorProjectSource,
} from "./state.ts";
import creatorProjectToBuildSources from "./creator-project-to-build-sources.ts";
import { backOff } from "exponential-backoff";

async function loadCreatorProjectSourceFromUrl(
	url: string,
): Promise<CreatorProjectSource> {
	const response = await backOff(() => fetch(url), {
		startingDelay: 500,
		numOfAttempts: 5,
		timeMultiple: 2,
		maxDelay: 4_000,
	});
	const originalArrayBuffer = await response.arrayBuffer();
	const responseContentType = response.headers.get("content-type");
	if (!responseContentType) {
		console.error(`No content type in image url ${url}`);
		captureMessage(`No content type in image url ${url}`);
	}

	const mimeType = responseContentType ?? "image/jpeg";
	const encodedImage = {
		buffer: originalArrayBuffer,
		mimeType,
	};

	return {
		bitmap: await encodedImageArrayBufferToBitmap(
			originalArrayBuffer,
			mimeType,
		),
		originalEncodedImage: encodedImage,
		// We've got the original buffer, don't want to re-encode into smallest.
		smallestEncodedImage: encodedImage,
	};
}

type StartProjectOptions = {
	readonly project: Omit<
		CreatorProject,
		| "backgroundMask"
		| "enhanceFacesImage"
		| "colorisationImage"
		| "sourceImage"
		| "facesMask"
	>;
	readonly sourceImageBitmapOrUrl: CreatorProjectSource | string;
	readonly backgroundMaskImageUrl: string | undefined;
	readonly colorisationImageUrl: string | undefined;
	readonly enhanceFacesImageUrl: string | undefined;
};

const startProject = createAsyncThunk<
	OpenProject,
	StartProjectOptions,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	"workspace/startProject",
	async (
		{
			project,
			sourceImageBitmapOrUrl,
			backgroundMaskImageUrl,
			colorisationImageUrl,
			enhanceFacesImageUrl,
		},
		{ getState, extra: { openCvLoader } },
	) => {
		// Locks up build pipeline
		if (Number.isNaN(project.currentPicture.updatedAt)) {
			throw new Error("Can't start a project with NaN timestamp");
		}

		const {
			reference: { systemPalette },
		} = getState();
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);

		// TODO: don't need to wait for all sources to start
		const [
			openCv,
			sourceImage,
			backgroundMaskImage,
			enhanceFacesImage,
			colorisationImage,
		] = await Promise.all([
			openCvLoader,
			typeof sourceImageBitmapOrUrl === "string"
				? loadCreatorProjectSourceFromUrl(sourceImageBitmapOrUrl)
				: sourceImageBitmapOrUrl,
			backgroundMaskImageUrl
				? loadBitmapFromUrl(backgroundMaskImageUrl)
				: undefined,
			enhanceFacesImageUrl
				? loadBitmapFromUrl(enhanceFacesImageUrl)
				: undefined,
			colorisationImageUrl
				? loadBitmapFromUrl(colorisationImageUrl)
				: undefined,
		]);
		const backgroundBitmapMask =
			backgroundMaskImage === undefined
				? undefined
				: createBitmapMaskFromBitmap(backgroundMaskImage);
		const facesMask = (() => {
			if (
				backgroundBitmapMask === undefined ||
				project.faceBoundingBoxes === undefined
			) {
				return undefined;
			}
			return createFacesMask(backgroundBitmapMask, project.faceBoundingBoxes);
		})();
		const newProject = {
			...project,
			sourceImage,
			backgroundMask: backgroundBitmapMask
				? loadedOnceOffLoad(backgroundBitmapMask)
				: pendingOnceOffLoad,
			facesMask,
			enhanceFacesImage:
				enhanceFacesImage === undefined
					? pendingOnceOffLoad
					: loadedOnceOffLoad(enhanceFacesImage),
			colorisationImage:
				colorisationImage === undefined
					? pendingOnceOffLoad
					: loadedOnceOffLoad(colorisationImage),
		};
		const build = createBrickedImageFromSourceNoCacheSync(
			{ openCv },
			creatorProjectToBuildSources(newProject),
			systemPaletteData,
			project.currentPicture,
		);

		return {
			build,
			builtCurrentPictureUpdatedAt: Date.now(),
			project: newProject,
		};
	},
);

export type { StartProjectOptions };
export default startProject;
