import { getGtmJavascript, getGtmNoScript } from "./snippets.ts";

type PageViewEvent = {
	readonly event: "PageView";
	readonly page_title: string;
};

type AppRegistrationEvent = {
	readonly event: "AppRegistration";
};

type AppLoginEvent = {
	readonly event: "AppLogin";
};

type ImageUploadEvent = {
	readonly event: "ImageUpload";
};

type SaveImageEvent = {
	readonly event: "SaveImage";
};

type SaveVersionEvent = {
	readonly event: "SaveVersion";
};

type SwapVersionEvent = {
	readonly event: "SwapVersion";
};

type FinishEvent = {
	readonly event: "Finish";
};

type EcommerceProduct = {
	readonly id: string;
	readonly name: string;
};

type AddEcommerceProduct = EcommerceProduct & {
	readonly quantity: number;
	readonly price: string;
};

type AddToCartEvent = {
	readonly event: "addToCart";
	readonly ecommerce: {
		readonly add: {
			readonly products: readonly AddEcommerceProduct[];
		};
	};
};

type RemoveFromCartEvent = {
	readonly event: "removeFromCart";
	readonly ecommerce: {
		readonly remove: {
			readonly products: readonly EcommerceProduct[];
		};
	};
};

type GenericEcommerceEvent = {
	readonly event: "ecommerce";
	readonly ecommerce: {
		readonly detail: {
			readonly products: readonly EcommerceProduct[];
		};
	};
};

type ImageSelectedEvent = {
	readonly event: "ImageSelected";
	readonly source: "custom" | "library";
};

type ShareImageSharedTo =
	| "linked-in"
	| "facebook"
	| "twitter"
	| "pinterest"
	| "email"
	| "link";

type ShareImageEvent = {
	readonly event: "ShareImage";
	readonly shared_to: ShareImageSharedTo;
};

type EditToolToolType = "size" | "adjustments" | "filters" | "draw";

type EditToolsEvent = {
	readonly event: "EditTools";
	readonly tool_type: EditToolToolType;
};

type UseAiFilterEvent = {
	readonly event: "UseAiFilter";
	readonly filter: "remove-background" | "enhance-faces" | "fix-face-colours";
};

type FormSubmissionEvent = {
	readonly event: "FormSubmission";
};

type UserSignUpEvent = {
	readonly event: "UserSignUp";
};

type UserLoginEvent = {
	readonly event: "UserLogin";
};

type DataLayerEvent =
	| PageViewEvent
	| AppRegistrationEvent
	| AppLoginEvent
	| ImageUploadEvent
	| ImageSelectedEvent
	| EditToolsEvent
	| ShareImageEvent
	| SaveImageEvent
	| SaveVersionEvent
	| SwapVersionEvent
	| FinishEvent
	| AddToCartEvent
	| RemoveFromCartEvent
	| GenericEcommerceEvent
	| FormSubmissionEvent
	| UserSignUpEvent
	| UseAiFilterEvent
	| UserLoginEvent;

type GtmDataLayer = {
	push(event: DataLayerEvent): void;
};

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		dataLayer: GtmDataLayer | undefined;
	}
}

type GtmService = {
	readonly dataLayerPush: (data: DataLayerEvent) => void;
};

type InitialiseOptions = {
	readonly gtmId: string;
};

function initialiseGtm({ gtmId }: InitialiseOptions): GtmService {
	const scriptId = `gtm-script-${gtmId}`;

	if (!document.getElementById(scriptId)) {
		// GTM sees these on load then replaces with own implementation
		window.dataLayer = window.dataLayer ?? [];

		const script = document.createElement("script");
		script.innerHTML = getGtmJavascript(gtmId);
		script.id = scriptId;

		const noScript = document.createElement("noscript");
		noScript.innerHTML = getGtmNoScript(gtmId);

		document.head.insertBefore(script, document.head.childNodes[0]);
		document.body.insertBefore(noScript, document.body.childNodes[0]);
	}

	return {
		dataLayerPush(event: DataLayerEvent) {
			if (!window.dataLayer) {
				// eslint-disable-next-line no-console
				console.error("No data layer yet");
				return;
			}
			window.dataLayer.push(event);
		},
	};
}

function createStubGtm(): GtmService {
	return {
		dataLayerPush(event: DataLayerEvent) {
			// eslint-disable-next-line no-console
			console.log("GTM data pushed", event);
		},
	};
}

export type {
	EcommerceProduct,
	ShareImageSharedTo,
	DataLayerEvent,
	GtmService,
	EditToolToolType,
};
export { createStubGtm, initialiseGtm };
