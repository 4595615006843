import { captureException as sentryCaptureException } from "@sentry/react";
import { unknownToString } from "@dhau/lang-extras";

type LoadedOnceOffLoad<T> = {
	readonly type: "loaded";
	readonly data: T;
};

type LoadingOnceOffLoad = {
	readonly type: "loading";
};

type ErrorOnceOffLoad = {
	readonly type: "error";
	readonly message: string;
};

type PendingOnceOffLoad = {
	readonly type: "pending";
};

type OnceOffLoad<T> =
	| PendingOnceOffLoad
	| LoadingOnceOffLoad
	| LoadedOnceOffLoad<T>
	| ErrorOnceOffLoad;

const pendingOnceOffLoad = { type: "pending" as const };
const loadingOnceOffLoad = { type: "loading" as const };

function loadedOnceOffLoad<T>(data: T): LoadedOnceOffLoad<T> {
	return { type: "loaded", data };
}

function errorOnceOffLoad(message: string) {
	return { type: "error" as const, message };
}

function isOnceOffLoaded<T>(
	load: OnceOffLoad<T>,
): load is LoadedOnceOffLoad<T> {
	return load.type === "loaded";
}

function isOnceOffPending<T>(load: OnceOffLoad<T>): load is PendingOnceOffLoad {
	return load.type === "pending";
}

function isOnceOffLoading<T>(load: OnceOffLoad<T>): load is LoadingOnceOffLoad {
	return load.type === "loading";
}

function isOnceOffError<T>(load: OnceOffLoad<T>): load is ErrorOnceOffLoad {
	return load.type === "error";
}

function isOnceOffNotComplete<T>(
	load: OnceOffLoad<T>,
): load is PendingOnceOffLoad | LoadingOnceOffLoad {
	return isOnceOffLoading(load) || isOnceOffPending(load);
}

function ensureLoadedOnceOffLoad<T>(load: OnceOffLoad<T>, message: string): T {
	if (!isOnceOffLoaded(load)) {
		throw new Error(message);
	}
	return load.data;
}

async function waitForLoadedOneOffLoad<T>(
	getLoad: () => OnceOffLoad<T>,
	message: string,
): Promise<T> {
	return new Promise<T>((resolve, reject) => {
		function checkLoad() {
			const load = getLoad();
			if (isOnceOffLoaded(load)) {
				resolve(load.data);
				return;
			}

			if (isOnceOffError(load)) {
				reject(new Error(message));
				return;
			}

			setTimeout(checkLoad, 100);
		}

		checkLoad();
	});
}

async function performOnceOffLoad<T>(
	loader: () => Promise<T>,
): Promise<OnceOffLoad<T>> {
	try {
		const result = await loader();
		return loadedOnceOffLoad(result);
	} catch (e) {
		sentryCaptureException(e);
		return errorOnceOffLoad(unknownToString(e));
	}
}

type LoadedUpdatableLoad<T> = {
	readonly type: "loaded";
	readonly data: T;
};

type LoadingUpdatableLoad<T> = {
	readonly type: "loading";
	readonly data?: T;
};

type ErrorUpdatableLoad<T> = {
	readonly type: "error";
	readonly message: string;
	readonly data?: T;
};

type PendingUpdatableLoad = {
	readonly type: "pending";
	readonly data: undefined;
};

type UpdatableLoad<T> =
	| PendingUpdatableLoad
	| LoadingUpdatableLoad<T>
	| LoadedUpdatableLoad<T>
	| ErrorUpdatableLoad<T>;

function isUpdatableLoading<T>(
	load: UpdatableLoad<T>,
): load is LoadingUpdatableLoad<T> {
	return load.type === "loading";
}

function isUpdatablePending<T>(
	load: UpdatableLoad<T>,
): load is PendingUpdatableLoad {
	return load.type === "pending";
}

function isUpdatableLoaded<T>(
	load: UpdatableLoad<T>,
): load is LoadedUpdatableLoad<T> {
	return load.type === "loaded";
}

function isUpdatableError<T>(
	load: UpdatableLoad<T>,
): load is ErrorUpdatableLoad<T> {
	return load.type === "error";
}

const pendingUpdatableLoad: PendingUpdatableLoad = {
	type: "pending" as const,
	data: undefined,
};

function loadedUpdatableLoad<T>(data: T): LoadedUpdatableLoad<T> {
	return {
		type: "loaded",
		data,
	};
}

function loadingUpdatableLoad<T>(
	previous: UpdatableLoad<T>,
): LoadingUpdatableLoad<T> {
	return {
		type: "loading",
		data: previous.data,
	};
}

function errorUpdatableLoad<T>(
	message: string,
	previousData?: T,
): ErrorUpdatableLoad<T> {
	return {
		type: "error",
		message,
		data: previousData,
	};
}

async function performUpdatableLoad<T, TR>(
	previous: UpdatableLoad<T>,
	loader: () => Promise<TR>,
	mergeData: (r: TR, p: T | undefined) => T,
): Promise<UpdatableLoad<T>> {
	try {
		const result = await loader();
		return loadedUpdatableLoad(mergeData(result, previous.data));
	} catch (e) {
		sentryCaptureException(e);
		return errorUpdatableLoad(unknownToString(e), previous.data);
	}
}

export type { UpdatableLoad, OnceOffLoad };
export {
	pendingOnceOffLoad,
	loadingOnceOffLoad,
	performUpdatableLoad,
	errorUpdatableLoad,
	loadingUpdatableLoad,
	loadedUpdatableLoad,
	ensureLoadedOnceOffLoad,
	performOnceOffLoad,
	isOnceOffLoaded,
	isOnceOffPending,
	isOnceOffLoading,
	isOnceOffError,
	isOnceOffNotComplete,
	pendingUpdatableLoad,
	waitForLoadedOneOffLoad,
	loadedOnceOffLoad,
	errorOnceOffLoad,
	isUpdatableLoaded,
	isUpdatablePending,
	isUpdatableLoading,
	isUpdatableError,
};
