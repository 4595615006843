const kMaxLength = 0x7fffffff;

function createUint8Array(length: number) {
	if (length > kMaxLength) {
		throw new RangeError(`The value "${length}" is invalid for option "size"`);
	}
	return new Uint8Array(length);
}

function uint8ArrayAlloc(size: number, fill?: number) {
	if (size <= 0) {
		return createUint8Array(size);
	}
	if (fill !== undefined) {
		// Only pay attention to encoding if it's a string. This
		// prevents accidentally sending in a number that would
		// be interpreted as a start offset.
		return createUint8Array(size).fill(fill);
	}
	return createUint8Array(size);
}

function checked(length: number) {
	// Note: cannot use `length < K_MAX_LENGTH` here because that fails when
	// length is NaN (which is otherwise coerced to zero.)
	if (length >= kMaxLength) {
		throw new RangeError(
			"Attempt to allocate Uint8Array larger than maximum " +
				"size: 0x" +
				kMaxLength.toString(16) +
				" bytes",
		);
	}
	return length | 0;
}

function uint8ArrayAllocUnsafe(size: number) {
	return createUint8Array(size < 0 ? 0 : checked(size) | 0);
}

function checkOffset(offset: number, ext: number, length: number) {
	if (offset % 1 !== 0 || offset < 0) {
		throw new RangeError("offset is not uint");
	}
	if (offset + ext > length) {
		throw new RangeError("Trying to access beyond buffer length");
	}
}

function readUInt32BE(
	data: Pick<Uint8Array, "length"> & Readonly<Record<number, number>>,
	offset: number,
) {
	// eslint-disable-next-line no-param-reassign
	offset = offset >>> 0;
	checkOffset(offset, 4, data.length);

	return (
		data[offset] * 0x1000000 +
		((data[offset + 1] << 16) | (data[offset + 2] << 8) | data[offset + 3])
	);
}

function uint8ArrayCopy(
	data: Pick<Uint8Array, "copyWithin" | "length" | "subarray">,
	target: Uint8Array,
	targetStart = 0,
	start = 0,
	explicitEnd?: number,
) {
	let end = explicitEnd ?? data.length;
	if (targetStart >= target.length) {
		// eslint-disable-next-line no-param-reassign
		targetStart = target.length;
	}
	if (end > 0 && end < start) {
		end = start;
	}

	// Copy 0 bytes; we're done
	if (end === start) {
		return 0;
	}
	if (target.length === 0 || data.length === 0) {
		return 0;
	}

	// Fatal error conditions
	if (targetStart < 0) {
		throw new RangeError("targetStart out of bounds");
	}
	if (start < 0 || start >= data.length) {
		throw new RangeError("Index out of range");
	}
	if (end < 0) {
		throw new RangeError("sourceEnd out of bounds");
	}

	// Are we oob?
	if (end > data.length) {
		end = data.length;
	}
	if (target.length - targetStart < end - start) {
		end = target.length - targetStart + start;
	}

	const len = end - start;

	if (data === target) {
		// Use built-in when available, missing from IE11
		data.copyWithin(targetStart, start, end);
	} else {
		Uint8Array.prototype.set.call(
			target,
			data.subarray(start, end),
			targetStart,
		);
	}

	return len;
}

function uint8ArrayFromArrayBufferView(view: ArrayBufferView) {
	return new Uint8Array(view.buffer, view.byteOffset, view.byteLength);
}

function checkInt(
	buf: Uint8Array,
	value: number,
	offset: number,
	ext: number,
	max: number,
	min: number,
) {
	if (value > max || value < min) {
		throw new RangeError('"value" argument is out of bounds');
	}
	if (offset + ext > buf.length) {
		throw new RangeError("Index out of range");
	}
}

/* eslint-disable no-param-reassign */
function writeUInt32BE(data: Uint8Array, value: number, offset: number) {
	value = +value;
	offset = offset >>> 0;
	checkInt(data, value, offset, 4, 0xffffffff, 0);
	data[offset] = value >>> 24;
	data[offset + 1] = value >>> 16;
	data[offset + 2] = value >>> 8;
	data[offset + 3] = value & 0xff;
}
/* eslint-enable no-param-reassign */

function uint8ArrayFromArrayBuffer(
	array: ArrayBuffer,
	byteOffset?: number,
	length?: number,
) {
	if (
		byteOffset !== undefined &&
		(byteOffset < 0 || array.byteLength < byteOffset)
	) {
		throw new RangeError('"offset" is outside of buffer bounds');
	}

	if (
		byteOffset !== undefined &&
		array.byteLength < byteOffset + (length || 0)
	) {
		throw new RangeError('"length" is outside of buffer bounds');
	}

	if (byteOffset === undefined && length === undefined) {
		return new Uint8Array(array);
	}
	if (length === undefined) {
		return new Uint8Array(array, byteOffset);
	}

	return new Uint8Array(array, byteOffset, length);
}

export {
	uint8ArrayFromArrayBuffer,
	uint8ArrayCopy,
	uint8ArrayFromArrayBufferView,
	readUInt32BE,
	writeUInt32BE,
	uint8ArrayAlloc,
	uint8ArrayAllocUnsafe,
};
