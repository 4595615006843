/* eslint-disable no-param-reassign */
import type { WritableBitmap } from "./types.ts";
import { iterateBitmapIndex } from "./iterate.ts";

function ensureBitmapOpaque(image: WritableBitmap) {
	iterateBitmapIndex(image, (i) => {
		image.data[i + 3] = 0xff;
	});
}

export default ensureBitmapOpaque;
