import type { Bitmap } from "../bitmap/index.ts";
import type { ImageZoomOffset, PictureConfiguration } from "../model/index.ts";
import { truncatePosition } from "../model/index.ts";

function constrainImageZoomOffset(
	sourceImage: Pick<Bitmap, "width" | "height">,
	{
		numberOfBasePlates,
		basePlateSize,
		imageZoom,
	}: Pick<
		PictureConfiguration,
		"imageZoom" | "numberOfBasePlates" | "basePlateSize"
	>,
	proposed: ImageZoomOffset,
): ImageZoomOffset {
	const numberOfBricks = {
		width: numberOfBasePlates.width * basePlateSize,
		height: numberOfBasePlates.height * basePlateSize,
	};
	// Image zoom is like pretending the source is * imageZoom bigger
	const sourceWidth = sourceImage.width;
	const sourceHeight = sourceImage.height;
	const sourceAspect = sourceWidth / sourceHeight;
	const desiredAspect = numberOfBricks.width / numberOfBricks.height;

	let sourceRectWidth = sourceWidth / imageZoom;
	let sourceRectHeight = sourceHeight / imageZoom;
	if (sourceAspect > desiredAspect) {
		sourceRectWidth = (sourceHeight * desiredAspect) / imageZoom;
	} else {
		sourceRectHeight = sourceWidth / desiredAspect / imageZoom;
	}

	const sourcePixelsPerBrick = sourceRectWidth / numberOfBricks.width;
	const bricksFreeXDimension = Math.floor(
		(sourceWidth - sourceRectWidth) / 2 / sourcePixelsPerBrick,
	);
	const bricksFreeYDimension = Math.floor(
		(sourceHeight - sourceRectHeight) / 2 / sourcePixelsPerBrick,
	);
	return truncatePosition(proposed, bricksFreeXDimension, bricksFreeYDimension);
}

export default constrainImageZoomOffset;
