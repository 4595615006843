import React from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Formik, Form } from "formik";
import TextInputField from "~/components/forms/text-input-field.tsx";

const validationSchema = z.object({
	fullName: z
		.string()
		.regex(/^[{\p{L}}'\-\s]*$/u, "Numbers or special characters not allowed"),
});

type FormValues = z.infer<typeof validationSchema>;

type NameValues = {
	readonly firstName: string;
	readonly middleName: string | undefined;
	readonly lastName: string | undefined;
};

type NameFormProps = {
	readonly isWorking: boolean;
	readonly submitLabel: string;
	readonly onSubmit: (values: NameValues) => Promise<void>;
};

function NameForm({
	isWorking,
	submitLabel,
	onSubmit: providedOnSubmit,
}: NameFormProps) {
	const onSubmit = async ({ fullName }: FormValues) => {
		const components = fullName.split(" ");
		const firstName = components[0];
		if (components.length === 2) {
			const lastName = components[1];
			await providedOnSubmit({ firstName, middleName: undefined, lastName });
			return;
		}

		if (components.length >= 3) {
			const middleName = components[1];
			const lastName = components.slice(2).join(" ");
			await providedOnSubmit({ firstName, middleName, lastName });
			return;
		}

		await providedOnSubmit({
			firstName,
			middleName: undefined,
			lastName: undefined,
		});
	};

	return (
		<Formik
			initialValues={{ fullName: "" }}
			validationSchema={toFormikValidationSchema(validationSchema)}
			onSubmit={onSubmit}
		>
			<Form className="default-modal__email-form email-form">
				<div className="email-form__field">
					<TextInputField
						name="fullName"
						className="email-form__input"
						placeholder="What's your name?"
						disabled={isWorking}
					/>
				</div>
				<button
					style={{ marginTop: 20 }}
					type="submit"
					className="button"
					disabled={isWorking}
				>
					{isWorking ? "Loading" : submitLabel}
				</button>
			</Form>
		</Formik>
	);
}

export type { NameValues };
export default NameForm;
