/* eslint-disable no-param-reassign */
import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import type { State } from "../state.ts";

function setAutoPaletteModeReducer(
	state: Draft<State>,
	action: PayloadAction<number | undefined>,
) {
	// TODO: Race condition here. If already building then won't have latest brickCount
	const { openProject } = state;
	if (!openProject) {
		throw new Error("No project");
	}

	const {
		project: { currentPicture },
	} = openProject;

	if (currentPicture.paletteMode.type === "hybrid") {
		throw new Error("Not yet implemented");
	}

	if (currentPicture.paletteMode.type === "custom") {
		currentPicture.paletteMode = {
			type: "auto",
			numberOfColours:
				action.payload ?? currentPicture.paletteMode.palette.length,
		};
	}
	currentPicture.paletteMode.numberOfColours =
		action.payload === undefined
			? currentPicture.paletteMode.numberOfColours
			: action.payload;
	currentPicture.updatedAt = Date.now();
	openProject.project.updatedAt = currentPicture.updatedAt;
}

export default setAutoPaletteModeReducer;
