import { gql } from "graphql-request";
import type { ShopifyCollection, RelayConnection } from "./common.ts";

const getAllCollectionsQuery = gql`
	query getAllCollectionsQuery {
		collections(first: 100) {
			edges {
				node {
					__typename
					id
					title
				}
			}
		}
	}
`;

type GetAllCollectionsQuery = {
	readonly collections: RelayConnection<
		Pick<ShopifyCollection, "id" | "title">
	>;
};

export type { GetAllCollectionsQuery };
export default getAllCollectionsQuery;
