import React from "react";
import Modal from "~/components/modal.tsx";
import LoginOrSignUpForm from "./login-or-sign-up-form.tsx";

type LoginPromptModalProps = {
	readonly show:
		| {
				readonly title: string;
		  }
		| false;
	readonly onHide: () => void;
	readonly onSuccess: () => void;
};

function LoginPromptModal({ show, onSuccess, onHide }: LoginPromptModalProps) {
	return (
		<Modal show={!!show} title={show ? show.title : ""} onClose={onHide}>
			<LoginOrSignUpForm
				showJustSignedUpMessage
				onSuccess={onSuccess}
				finalSubmitLabel="Sign up"
			/>
		</Modal>
	);
}

export default LoginPromptModal;
