import { gql } from "graphql-request";

export type GetHasSavedDesignStatusQuery = {
	readonly viewer: {
		readonly numberOfSavedProjects: number;
		readonly projectsImport:
			| {
					readonly status: "running" | "complete";
			  }
			| undefined;
	};
};

export default gql`
	query getHasSavedDesignStatus {
		viewer {
			__typename
			id
			numberOfSavedProjects
			projectsImport {
				status
			}
		}
	}
`;
