import { z } from "zod";

type UnixTimestamp = number;
type ISODateTimeString = string;

const unixTimestampSchema = z
	.number()
	.int()
	.positive() as z.Schema<UnixTimestamp>;
const isoDateTimeStringSchema = z.string() as z.Schema<ISODateTimeString>;

export { unixTimestampSchema, isoDateTimeStringSchema };
export type { UnixTimestamp, ISODateTimeString };
