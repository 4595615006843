type GenericPosition<T> = {
	readonly x: T;
	readonly y: T;
};

const originPosition: GenericPosition<number> = { x: 0, y: 0 };

type PixelPosition = GenericPosition<number>;

function addPositions(
	position1: GenericPosition<number>,
	position2: GenericPosition<number>,
): GenericPosition<number> {
	return { x: position1.x + position2.x, y: position1.y + position2.y };
}

function roundPosition(
	position: GenericPosition<number>,
): GenericPosition<number> {
	return { x: Math.round(position.x), y: Math.round(position.y) };
}

function subtractPositions(
	position1: GenericPosition<number>,
	position2: GenericPosition<number>,
): GenericPosition<number> {
	return { x: position1.x - position2.x, y: position1.y - position2.y };
}

function truncateDimension(d: number, limit: number): number {
	if (d > limit) {
		return limit;
	}
	if (d < -limit) {
		// Prevent putting -0 into the system
		if (limit === 0) {
			return 0;
		}
		return -limit;
	}
	return d;
}

function truncatePosition(
	p: GenericPosition<number>,
	limitX: number,
	limitY: number,
): GenericPosition<number> {
	return {
		x: truncateDimension(p.x, limitX),
		y: truncateDimension(p.y, limitY),
	};
}

function multiplyPosition(
	p: GenericPosition<number>,
	scale: number,
): GenericPosition<number> {
	return { x: p.x * scale, y: p.y * scale };
}

function arePositionsClose(
	p1: GenericPosition<number>,
	p2: GenericPosition<number>,
	threshold: number,
): boolean {
	return Math.abs(p1.x - p2.x) < threshold && Math.abs(p1.y - p2.y) < threshold;
}

type GenericSize<T> = {
	readonly width: T;
	readonly height: T;
};

function multiplySize(
	{ width, height }: GenericSize<number>,
	multiplier: number,
): GenericSize<number> {
	return { width: width * multiplier, height: height * multiplier };
}

type Area = {
	readonly m2: number;
};

function areaFromMetres2(m2: number): Area {
	return { m2 };
}

export type { Area, GenericSize, PixelPosition, GenericPosition };
export {
	arePositionsClose,
	multiplyPosition,
	multiplySize,
	roundPosition,
	subtractPositions,
	areaFromMetres2,
	truncatePosition,
	addPositions,
	originPosition,
};
