import { gql } from "graphql-request";
import type { ShopifyMoney, RelayConnection } from "./common.ts";

// TODO: Move frames query into frontend common and share with website
// TODO: Move shopify types into frontend common
// TODO: There's a duplicate of this, elsewhere called "ProductVariant"

type ShopifyProductVariant = {
	readonly id: string;
	readonly availableForSale: boolean;
	readonly quantityAvailable: number;
	readonly price: ShopifyMoney;
	readonly selectedOptions: readonly {
		readonly name: string;
		readonly value: string;
	}[];
};

type ShopifyProduct = {
	readonly title: string;
	readonly handle: string;
	readonly options: readonly {
		readonly name: string;
		readonly values: readonly string[];
	}[];
	readonly variants: RelayConnection<ShopifyProductVariant>;
};

type GetAddOnVariables = {
	readonly query: string;
	readonly countryCode: string;
};

type GetAddOnQuery = {
	readonly products: RelayConnection<ShopifyProduct>;
};

const getAddOnQuery = gql`
	query getAddOnQuery($query: String!, $countryCode: CountryCode!)
	@inContext(country: $countryCode) {
		products(first: 100, query: $query) {
			edges {
				node {
					__typename
					id
					handle
					title
					options {
						name
						values
					}
					variants(first: 100) {
						edges {
							node {
								__typename
								id
								quantityAvailable
								availableForSale
								price {
									amount
									currencyCode
								}
								selectedOptions {
									name
									value
								}
							}
						}
					}
				}
			}
		}
	}
`;
const framesQueryInput = "product_type:accessories AND tag:frame";
const preassemblyQueryInput = "Pre-Assembly AND product_type:services";
const hangingStripsQueryInput = 'tag:"hanging-strips"';

export type {
	GetAddOnVariables,
	GetAddOnQuery,
	ShopifyProductVariant,
	ShopifyProduct,
};
export {
	getAddOnQuery,
	framesQueryInput,
	preassemblyQueryInput,
	hangingStripsQueryInput,
};
