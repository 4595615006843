/* eslint-disable no-param-reassign */
import type { Draft } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { lastOrThrow } from "@dhau/lang-extras";
import type {
	BaseplateSize,
	BrickColour,
	BrickPosition,
	DetailFilter,
	ImageZoomOffset,
	NumberOfBaseplates,
	PenMark,
	PictureConfiguration,
} from "@brickme/project-core/src";
import {
	resetProject,
	setPictureConfiguration,
	startNewProject,
} from "~/features/workspace/store-slice.ts";
import type { State as WorkspaceState } from "~/features/workspace/state.ts";
import type { State as SaveProjectState } from "~/features/save-project/store-slice.ts";
import { updateSaveOpenProject } from "~/features/save-project/store-slice.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import type { State as ReferenceState } from "~/features/reference/state.ts";
import createPerformAction from "./create-perform-action.ts";
import type { Command } from "./command.ts";

const name = "commands";

type CommandSnapshot = {
	readonly projectConfiguration: PictureConfiguration;
	readonly command: Command;
};

type State = {
	readonly commandSnapshots: readonly CommandSnapshot[];
};

const performCommand = createAsyncThunk<
	CommandSnapshot,
	Command,
	StoreThunkApiConfig<{ workspace: WorkspaceState }>
>(`${name}/performCommand`, async (command, { dispatch, getState }) => {
	const {
		workspace: { openProject },
	} = getState();
	if (!openProject) {
		throw new Error("Need open project");
	}

	await dispatch(createPerformAction(command));
	return {
		projectConfiguration: openProject.project.currentPicture,
		command,
	};
});

const undoMostRecentCommand = createAsyncThunk<
	undefined,
	undefined,
	StoreThunkApiConfig<{
		commands: State;
		reference: ReferenceState;
		workspace: WorkspaceState;
	}>
>(`${name}/undoMostRecentCommand`, async (_, { dispatch, getState }) => {
	const { commandSnapshots } = getState().commands;
	const snapshot = lastOrThrow(commandSnapshots, "No commands to undo");
	await dispatch(setPictureConfiguration(snapshot.projectConfiguration));
	return undefined;
});

type PostCommandCallbackConfig = {
	readonly dispatch: any;
	readonly getState: () => {
		workspace: WorkspaceState;
		saveProject: SaveProjectState;
	};
};

function createPerformCommandThunk<T>(
	actionName: string,
	commandFactory: (next: T) => Command,
	postCallback?: (next: T, config: PostCommandCallbackConfig) => void,
) {
	return createAsyncThunk<
		void,
		T,
		StoreThunkApiConfig<{
			workspace: WorkspaceState;
			saveProject: SaveProjectState;
		}>
	>(`${name}/${actionName}`, async (newValue: T, config) => {
		const { dispatch } = config;
		await dispatch(performCommand(commandFactory(newValue)));
		if (postCallback) {
			postCallback(newValue, config);
		}
	});
}

const performSetNumberOfBasePlatesCommand = createPerformCommandThunk(
	"performSetNumberOfBasePlatesCommand",
	(next: NumberOfBaseplates) => ({
		type: "setNumberOfBasePlates",
		next,
	}),
);

const performSetImageZoomCommand = createPerformCommandThunk(
	"performSetImageZoomCommand",
	(next: number) => ({
		type: "setImageZoom",
		next,
	}),
);

const performSetImageZoomOffsetCommand = createPerformCommandThunk(
	"performSetImageZoomOffsetCommand",
	(next: ImageZoomOffset) => ({
		type: "setImageZoomOffset",
		next,
	}),
);

const performSetBasePlateSizeCommand = createPerformCommandThunk(
	"performSetBasePlateSizeCommand",
	(next: BaseplateSize) => ({
		type: "setBasePlateSize",
		next,
	}),
);

const performSetBrightnessCommand = createPerformCommandThunk(
	"performSetBrightnessCommand",
	(next: number) => ({
		type: "setBrightness",
		next,
	}),
);

const performSetContrastCommand = createPerformCommandThunk(
	"performSetContrastCommand",
	(next: number) => ({
		type: "setContrast",
		next,
	}),
);

const performSetSaturationCommand = createPerformCommandThunk(
	"performSetSaturationCommand",
	(next: number) => ({
		type: "setSaturation",
		next,
	}),
);

const performSelectCustomModePaletteBrickCommand = createPerformCommandThunk(
	"performSelectCustomModePaletteBrickCommand",
	(brick: BrickColour) => ({
		type: "selectCustomModePaletteBrick",
		brick,
	}),
);

const performDeselectCustomModePaletteBrickCommand = createPerformCommandThunk(
	"performDeselectCustomModePaletteBrickCommand",
	(brick: BrickColour) => ({
		type: "deselectCustomModePaletteBrick",
		brick,
	}),
);

const performSetAutoPaletteModeCommand = createPerformCommandThunk(
	"performSetAutoPaletteModeCommand",
	(numberOfColours?: number) => ({
		type: "setAutoPaletteMode",
		numberOfColours,
	}),
);

const performSetCustomPaletteModeCommand = createAsyncThunk<
	void,
	undefined,
	StoreThunkApiConfig<{ workspace: WorkspaceState }>
>(`${name}/performSetCustomPaletteModeCommand`, async (_, { dispatch }) => {
	await dispatch(performCommand({ type: "setCustomPaletteMode" }));
});

const performAddPenMark = createPerformCommandThunk(
	"performAddPenMark",
	(penMark: PenMark) => ({
		type: "addPenMarks",
		penMarks: [penMark],
	}),
);

const performAddPaintBucketCommand = createPerformCommandThunk(
	"performAddPaintBucket",
	(position: BrickPosition) => ({
		type: "addPaintBucket",
		position,
	}),
);

const performSetDetailFilterCommand = createPerformCommandThunk(
	"performSetDetailFilter",
	(detailFilter?: DetailFilter) => ({
		type: "setDetailFilter",
		detailFilter,
	}),
);

async function naiveFireOffUpdateCallback(
	newValue: boolean,
	{ dispatch, getState }: PostCommandCallbackConfig,
) {
	if (!newValue) {
		return;
	}

	const { workspace, saveProject } = getState();
	const project = workspace.openProject?.project;
	if (!project) {
		throw new Error("Need open project");
	}

	// Fire off update immediately to make sure backend sources are updated
	// TODO: Some checks here so don't fire off too many updates. At very
	// least, check if have all sources.
	// readonly type: "updatingToBackend"; ??
	if (
		saveProject.openedProjectSaveState === undefined ||
		saveProject.openedProjectSaveState.type === "uploadingSourceImage" ||
		saveProject.openedProjectSaveState.type === "uploadedSourceImage" ||
		saveProject.openedProjectSaveState.type === "creatingApiProject"
	) {
		// Pre-creation of project. Will be taken care of later once createProject called
		return;
	}

	await dispatch(updateSaveOpenProject());
}

const performSetEnhanceFacesCommand = createPerformCommandThunk(
	"performSetEnhanceFaces",
	(enhanceFaces: boolean) => ({
		type: "setEnhanceFaces",
		enhanceFaces,
	}),
	naiveFireOffUpdateCallback,
);

const performSetFixFaceColoursCommand = createPerformCommandThunk(
	"performSetFixFaceColours",
	(fixFaceColours: boolean) => ({
		type: "setFixFaceColours",
		fixFaceColours,
	}),
	naiveFireOffUpdateCallback,
);

const performSetRemoveBackgroundCommand = createPerformCommandThunk(
	"performSetRemoveBackground",
	(value: PictureConfiguration["removeBackground"]) => ({
		type: "setRemoveBackground",
		value,
	}),
	(newValue: PictureConfiguration["removeBackground"], config) =>
		naiveFireOffUpdateCallback(!!newValue, config),
);

const slice = createSlice({
	name,
	initialState: {
		commandSnapshots: [],
	} as State,
	reducers: {},
	extraReducers: {
		[performCommand.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof performCommand)["fulfilled"]>>,
		) => {
			state.commandSnapshots.push(action.payload);
			// TODO: Test this functionality
			// TODO: tryAndMerge
			while (state.commandSnapshots.length > 50) {
				state.commandSnapshots.shift();
			}
		},
		[undoMostRecentCommand.fulfilled.type]: (state: Draft<State>) => {
			state.commandSnapshots.pop();
		},
		[startNewProject.type]: (state: Draft<State>) => {
			state.commandSnapshots = [];
		},
		[resetProject.fulfilled.type]: (state: Draft<State>) => {
			state.commandSnapshots = [];
		},
	},
});

export {
	performCommand,
	performSetBrightnessCommand,
	performSetSaturationCommand,
	performSetContrastCommand,
	performSetNumberOfBasePlatesCommand,
	performSetImageZoomCommand,
	performSetImageZoomOffsetCommand,
	performSetBasePlateSizeCommand,
	performSelectCustomModePaletteBrickCommand,
	performDeselectCustomModePaletteBrickCommand,
	performSetAutoPaletteModeCommand,
	performSetCustomPaletteModeCommand,
	performAddPenMark,
	performAddPaintBucketCommand,
	performSetDetailFilterCommand,
	performSetEnhanceFacesCommand,
	performSetFixFaceColoursCommand,
	performSetRemoveBackgroundCommand,
	undoMostRecentCommand,
};
export default slice.reducer;
