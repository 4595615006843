// Was using .toString(36) and parseInt(..., 36), but realised we could get
// better compression. These use 0-9a-z only, but we can create a greater
// compression by using upper + lower and symbols (that don't need escaping
// in JSON).

import { range } from "lodash-es";

const aToZ = range(97, 123).map((code) => String.fromCharCode(code));

// NOTE: Important that don't change this, otherwise it will break a bunch of pens.
// This is all valid JSON string chars that don't require escaping. And doesn't use "-"
const lookup = [
	...range(0, 10),
	...aToZ,
	...aToZ.map((c) => c.toUpperCase()),
	"_",
	".",
	" ",
	"!",
	"#",
	"$",
	"%",
	"&",
	"'",
	"*",
	"+",
	"/",
	":",
	";",
	"<",
	"=",
	">",
	"?",
	"@",
	"[",
	"]",
	"^",
	"`",
	"{",
	"}",
	"~",
	// Don't use these - allow some characters for users to join
	// ",",
	// "|",
].join("");

const compressionDigitLimit = lookup.length;

function decimalToCompressed(num: number): string {
	if (num < 0) {
		return `-${decimalToCompressed(-num)}`;
	}

	if (num < lookup.length) {
		return lookup[num];
	}

	const rem = num % lookup.length;
	const div = Math.floor(num / lookup.length);
	return decimalToCompressed(div - 1) + lookup[rem];
}

function compressedToDecimal(compressed: string): number {
	if (compressed.length === 0) {
		throw new Error("Empty string cannot be handled as compressed string");
	}

	if (compressed.startsWith("-")) {
		return -1 * compressedToDecimal(compressed.slice(1));
	}

	const lastChar = compressed[compressed.length - 1];
	const rest = compressed.slice(0, compressed.length - 1);
	const lastDec = lookup.indexOf(lastChar);
	return (
		(rest.length > 0 ? compressedToDecimal(rest) + 1 : 0) *
			compressionDigitLimit +
		lastDec
	);
}

function isCompressionCharacter(char: string) {
	return lookup.includes(char);
}

export {
	isCompressionCharacter,
	compressionDigitLimit,
	decimalToCompressed,
	compressedToDecimal,
};
