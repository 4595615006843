type Weight = {
	readonly g: number;
	readonly kg: number;
};

function weightFromKg(kg: number): Weight {
	return { kg, g: kg * 1000 };
}

function weightFromG(g: number): Weight {
	return { kg: g / 1000, g };
}

export type { Weight };
export { weightFromKg, weightFromG };
