import React, { useEffect, useState } from "react";
import { clsx } from "clsx";
import { CSSTransition } from "react-transition-group";
import useToggle from "react-use/esm/useToggle";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { selectOptionalOpenProject } from "~/features/workspace/selectors.ts";
import {
	startNewProject,
	resetProject,
} from "~/features/workspace/store-slice.ts";
import { useWebsiteUrl } from "~/context/website-url.tsx";
import {
	openSavedDesigns,
	openTutorial,
	showLoginPrompt,
	switchToSimpleCreator,
} from "~/features/nav/store-slice.ts";
import { selectIsSimpleCreatorOpen } from "~/features/nav/selectors.ts";
import { selectUser } from "~/features/user/selectors.ts";
import { signOut } from "~/features/user/store-slice.ts";
import { selectVersion } from "~/features/reference/selectors.ts";
import ConfirmStartNewProjectModal from "./confirm-start-new-project-modal.tsx";
import ConfirmExitModal from "./confirm-exit-modal.tsx";
import ConfirmResetProjectModal from "./confirm-reset-project-modal.tsx";
import ConfirmOpenSimpleCreatorModal from "./confirm-open-simple-creator-modal.tsx";

function MainMenu() {
	const dispatch = useAppDispatch();
	const userState = useAppSelector(selectUser);
	const isGuest = userState.type === "guest";
	const isSimpleCreatorOpen = useAppSelector(selectIsSimpleCreatorOpen);
	const version = useAppSelector(selectVersion);

	const contactUrl = useWebsiteUrl("/pages/contact-us");
	const faqUrl = useWebsiteUrl("/pages/frequently-asked-questions");
	const designItForMeUrl = useWebsiteUrl("/products/design-it-for-me-service");
	// TODO: Use context
	const myAccountUrl = import.meta.env.VITE_ACCOUNT_WEBSITE_URL;

	const [isOpen, onToggleMenu] = useToggle(false);
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
	}, [isOpen]);

	// New project
	const hasOpenProject = !!useAppSelector(selectOptionalOpenProject);
	const [isStartConfirmationOpen, setStartConfirmationOpen] = useState(false);
	const onStartNewProject = () => {
		onToggleMenu(false);
		setStartConfirmationOpen(true);
	};
	const onStartNewProjectClose = () => {
		setStartConfirmationOpen(false);
	};
	const onStartNewProjectConfirm = () => {
		dispatch(startNewProject());
		onStartNewProjectClose();
	};

	// Exit
	const [isExitConfirmOpen, setOpenExitConfirm] = useState(false);
	const onBackToHomepage = () => {
		onToggleMenu(false);
		setOpenExitConfirm(true);
	};

	// Reset
	const [isResetConfirmationOpen, setResetConfirmationOpen] = useState(false);
	const onResetProject = () => {
		onToggleMenu(false);
		setResetConfirmationOpen(true);
	};
	const onResetProjectClose = () => {
		setResetConfirmationOpen(false);
	};
	const onResetProjectConfirm = () => {
		onToggleMenu(false);
		dispatch(resetProject());
		onResetProjectClose();
	};

	// Load
	const onLoadSavedProject = () => {
		dispatch(openSavedDesigns());
		onToggleMenu(false);
	};

	// Tutorial
	const onOpenTutorial = () => {
		dispatch(openTutorial());
		onToggleMenu(false);
	};

	// Logout / login
	const promptSignIn = () => {
		dispatch(
			showLoginPrompt({
				title: "Sign in",
			}),
		);
	};
	const onLogout = () => {
		dispatch(signOut());
	};
	const onMenuSignInClick = () => {
		promptSignIn();
		onToggleMenu(false);
	};

	// Simple creator
	const [isSimpleCreatorConfirmOpen, setSimpleCreatorConfirmOpen] =
		useState(false);
	const onOpenSimpleCreator = () => {
		dispatch(switchToSimpleCreator());
		onToggleMenu(false);
		setSimpleCreatorConfirmOpen(false);
	};
	const user = useAppSelector(selectUser);
	const onSwitchToSimpleCreatorClick = () => {
		if (user.type === "signedIn") {
			setSimpleCreatorConfirmOpen(true);
		} else {
			onOpenSimpleCreator();
		}
	};

	return (
		<>
			<nav
				className={clsx([
					"main-header__menu main-menu js-main-menu",
					{ active: isOpen },
				])}
			>
				<button
					type="button"
					className={clsx([
						"main-menu__btn js-main-menu__btn",
						{ active: isOpen },
					])}
					onClick={onToggleMenu}
				>
					<span className="main-menu__btn-inner">
						<span className="main-menu__btn-lines" />
					</span>
				</button>
				{!isSimpleCreatorOpen && (
					<ul
						className={clsx([
							"main-menu__list js-main-menu__list",
							{ active: isOpen },
						])}
					>
						{hasOpenProject && (
							<li className="main-menu__list-item">
								<button
									type="button"
									onClick={onResetProject}
									className="main-menu__list-link"
								>
									Reset
								</button>
							</li>
						)}
						{hasOpenProject && (
							<li className="main-menu__list-item">
								<button
									type="button"
									onClick={onStartNewProject}
									className="main-menu__list-link"
								>
									Start new project
								</button>
							</li>
						)}
						{userState.type === "signedIn" && userState.user.hasSavedDesign && (
							<li className="main-menu__list-item">
								<button
									type="button"
									onClick={onLoadSavedProject}
									className="main-menu__list-link"
								>
									Load saved project
								</button>
							</li>
						)}
						<li className="main-menu__list-item">
							<button
								type="button"
								className="main-menu__list-link"
								onClick={onSwitchToSimpleCreatorClick}
							>
								Switch to simple creator
							</button>
						</li>
						<li className="main-menu__list-item">
							<button
								type="button"
								className="main-menu__list-link"
								onClick={onOpenTutorial}
							>
								Watch tutorial video
							</button>
						</li>
						<li className="main-menu__list-item">
							<a
								href={contactUrl}
								className="main-menu__list-link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Contact us
							</a>
						</li>
						<li className="main-menu__list-item">
							<button
								type="button"
								className="main-menu__list-link"
								onClick={onBackToHomepage}
							>
								Back to homepage
							</button>
						</li>
						{isGuest && (
							<li className="main-menu__list-item">
								<button
									type="button"
									className="main-menu__list-link"
									onClick={onMenuSignInClick}
								>
									Sign in
								</button>
							</li>
						)}
						<li className="main-menu__list-item">
							<div className="main-menu__list-info">Version: {version}</div>
						</li>
					</ul>
				)}
			</nav>
			{isSimpleCreatorOpen && (
				<CSSTransition
					in={isOpen}
					timeout={300}
					className="mobile-main-menu-container"
					clsx="general-fade-in-out"
					mountOnEnter
					unmountOnExit
				>
					<div>
						<div className="mobile-menu-background" />
						<CSSTransition
							in={isOpen}
							timeout={300}
							className="mobile-main-menu"
							clsx="mobile-main-menu"
							appear
						>
							<div>
								<div className="mobile-main-menu-header">
									<h3>MENU</h3>
									<button
										type="button"
										onClick={() => onToggleMenu(false)}
										className="mobile-main-menu-close-button"
									>
										X
									</button>
								</div>
								<ul>
									{hasOpenProject && (
										<li>
											<button type="button" onClick={onResetProject}>
												Reset (start over)
											</button>
										</li>
									)}
									{hasOpenProject && (
										<li>
											<button type="button" onClick={onStartNewProject}>
												Start new project
											</button>
										</li>
									)}
									{userState.type === "signedIn" &&
										userState.user.hasSavedDesign && (
											<li>
												<button type="button" onClick={onLoadSavedProject}>
													Load saved project
												</button>
											</li>
										)}
									<li>
										<a href={faqUrl} target="_blank" rel="noopener noreferrer">
											FAQ
										</a>
									</li>
									<li>
										<a
											href={designItForMeUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											Design it for me!
										</a>
									</li>
									<li>
										<button type="button" onClick={onBackToHomepage}>
											Go to homepage
										</button>
									</li>
									<li>
										{isGuest ? (
											<button type="button" onClick={promptSignIn}>
												Sign in
											</button>
										) : (
											<button type="button" onClick={onLogout}>
												Logout
											</button>
										)}
									</li>
								</ul>
								<div className="mobile-main-menu-info">
									All your designs are automatically saved in{" "}
									<a
										href={myAccountUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										your account
									</a>
								</div>
								<div className="mobile-main-menu-info mobile-main-menu-info-version">
									Version: {version}
								</div>
							</div>
						</CSSTransition>
					</div>
				</CSSTransition>
			)}
			<ConfirmOpenSimpleCreatorModal
				show={isSimpleCreatorConfirmOpen}
				onCancel={() => setSimpleCreatorConfirmOpen(false)}
				onConfirm={onOpenSimpleCreator}
			/>
			<ConfirmExitModal
				show={isExitConfirmOpen}
				onCancel={() => setOpenExitConfirm(false)}
			/>
			<ConfirmStartNewProjectModal
				show={isStartConfirmationOpen}
				onConfirm={onStartNewProjectConfirm}
				onCancel={onStartNewProjectClose}
			/>
			<ConfirmResetProjectModal
				show={isResetConfirmationOpen}
				onConfirm={onResetProjectConfirm}
				onCancel={onResetProjectClose}
			/>
		</>
	);
}

export default MainMenu;
