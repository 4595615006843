import { findLastIndex, head } from "lodash-es";

type Process<T> = {
	readonly generator: Generator<unknown, T>;
	readonly priority: number;
};

type TrackedProcess<T> = {
	readonly process: Process<T>;
	readonly resolve: (result: T) => void;
	readonly reject: (error: Error) => void;
};

type ProcessRun<T = void> = {
	readonly promise: Promise<T>;
	readonly abort: () => void;
};

const abortedError = new Error("Aborted");

function isProcessAbortError(e: unknown): boolean {
	return e === abortedError;
}

const maxFrameTime = 6;

function createProcessRunner() {
	let processes: readonly TrackedProcess<any>[] = [];

	function runHeavyProcesses(): void {
		const start = performance.now();
		do {
			const tracked = head(processes);
			if (!tracked) {
				break;
			}
			const result = tracked.process.generator.next();
			if (result.done) {
				processes = processes.slice(1);
				tracked.resolve(result.value);
			}
		} while (performance.now() - start < maxFrameTime);
		if (import.meta.env.NODE_ENV === "development") {
			const frameTime = performance.now() - start;
			if (frameTime > maxFrameTime + 3) {
				console.warn("Heavy process overflowed frame", frameTime);
			}
		}
		if (processes.length > 0) {
			// TODO: I don't think this actually allows aborting as I think this
			// will run until complete. Will hopefully become obsolete anyway
			// once use workers. Or see (safari doesn't support as of writing)
			// https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API
			// Note: used to be queueMicrotask. Changed 13/11/23 after some research
			// that showed that blocks main thread/animation frames
			window.setTimeout(runHeavyProcesses);
		}
	}

	return <T>(process: Process<T>): ProcessRun<T> => {
		// To guard against issues aborting if user passes in multiple of same process obj
		const clonedProcess = { ...process };
		const promise = new Promise<T>((resolve, reject) => {
			const insertIndex =
				findLastIndex(
					processes,
					(p) => p.process.priority >= process.priority,
				) + 1;
			processes = [
				...processes.slice(0, insertIndex),
				{
					process: clonedProcess,
					resolve,
					reject,
				},
				...processes.slice(insertIndex),
			];
			if (processes.length === 1) {
				// Allow any events that might have fired this to finish processing
				// Note: used to be queueMicrotask. Changed 13/11/23 after some research
				// that showed that blocks main thread/animation frames
				window.setTimeout(runHeavyProcesses);
			}
		});
		const abort = () => {
			const index = processes.findIndex((t) => t.process === clonedProcess);
			if (index === -1) {
				console.warn("Trying to abort process not found");
				return;
			}
			const tracked = processes[index];
			processes = processes.filter((t) => t !== tracked);
			try {
				tracked.process.generator.throw(abortedError);
			} catch (e) {
				if (e !== abortedError) {
					throw e;
				}
			}
			tracked.reject(abortedError);
		};
		return { promise, abort };
	};
}

type RunProcess = ReturnType<typeof createProcessRunner>;

export type { ProcessRun, RunProcess };
export { isProcessAbortError };
export default createProcessRunner;
