import type { FormEvent, ReactNode } from "react";
import React, { useState } from "react";
import useLocalStorage from "react-use/esm/useLocalStorage";

type PasswordProtectedProps = {
	readonly password: string;
	readonly children: ReactNode;
};

const passwordKey = "protectionPassword";

function PasswordProtected({
	password: requiredPassword,
	children,
}: PasswordProtectedProps) {
	const [savedPassword, setSavedPassword] = useLocalStorage(passwordKey, "");
	const [password, setPassword] = useState("");
	const [hasPasswordError, setHasPasswordError] = useState(false);
	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (password === requiredPassword) {
			setHasPasswordError(false);
			setSavedPassword(password);
			return;
		}

		setHasPasswordError(true);
	};

	if (savedPassword === requiredPassword) {
		return <>{children}</>;
	}

	return (
		<div style={{ textAlign: "center" }}>
			<h1 style={{ fontSize: 30, fontWeight: "bold" }}>Brick.me staging</h1>
			<p style={{ marginTop: 15, marginBottom: 15 }}>
				Enter password to proceed
			</p>
			<form onSubmit={onSubmit}>
				<div style={{ marginTop: 15, marginBottom: 15 }}>
					<input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<button
					type="submit"
					style={{
						background: "#bdbdbd",
						border: "1px solid black",
						padding: 5,
					}}
				>
					Submit
				</button>
				{hasPasswordError && (
					<div style={{ color: "red" }}>Incorrect password</div>
				)}
			</form>
			<p style={{ marginTop: 15, marginBottom: 15 }}>
				Looking for the real brick.me site?{" "}
				<a href="https://brick.me" style={{ color: "blue" }}>
					It&apos;s at https://brick.me
				</a>
			</p>
		</div>
	);
}

export { PasswordProtected };
