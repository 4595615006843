import { z } from "zod";
import {
	rgbHexStringToRgbNumber,
	rgbToRgba,
	rgbToLab,
	rgbToHsl,
} from "../utils/colour-conversions.ts";

type BrickColourHexString = string;
type BrickColourRgba = number;

function zRgbHexString() {
	return z.string();
}

const brickColourSchema = z.object({
	identifier: z.number(),
	symbol: z.string().optional(),
	r: z.number(),
	g: z.number(),
	b: z.number(),
	lab: z.object({
		l: z.number(),
		a: z.number(),
		b: z.number(),
	}),
	rgba: z.number().nonnegative().int(),
	isVisuallyDark: z.boolean(),
	hexString: zRgbHexString(),
	// Positive?
	lightness: z.number(),
	// Positive?
	saturation: z.number(),
	// More validation?
	hue: z.number(),
});

type BrickColour = z.infer<typeof brickColourSchema>;

type TransportBrickColour = {
	readonly colour: string;
	readonly identifier: number;
};

const startMask24 = 0xff0000;
const greenMask24 = 0x00ff00;
const endMask24 = 0x0000ff;

/* eslint-disable no-bitwise */
function transportBrickColourToCore(
	transport: TransportBrickColour,
): BrickColour {
	const rgb = rgbHexStringToRgbNumber(transport.colour);
	const r = (rgb & startMask24) >> 16;
	const g = (rgb & greenMask24) >> 8;
	const b = rgb & endMask24;
	const hsl = rgbToHsl(r, g, b);
	// This is a rough hack. Assumes uniform visual perception over rgb space
	// which isn't true.
	// TODO: Find proper method
	const isVisuallyDark = (r + g + b) / 3 < 0xff * 0.5;
	return {
		identifier: transport.identifier,
		rgba: rgbToRgba(rgb, 0xff),
		r,
		g,
		b,
		isVisuallyDark,
		lab: rgbToLab(r, g, b),
		hexString: transport.colour,
		lightness: hsl.l,
		saturation: hsl.s,
		hue: hsl.h,
	};
}
/* eslint-enable no-bitwise */

export type {
	BrickColourHexString,
	BrickColourRgba,
	TransportBrickColour,
	BrickColour,
};
export { zRgbHexString, brickColourSchema, transportBrickColourToCore };
