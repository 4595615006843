function count<T extends string | number>(
	items: readonly T[],
): Record<string, number> {
	const counts: Record<string, number> = {};
	items.forEach((item) => {
		const itemKey = item.toString();
		if (!(itemKey in counts)) {
			counts[itemKey] = 0;
		}
		counts[itemKey] += 1;
	});
	return counts;
}

export { count };
