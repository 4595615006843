import React from "react";
import useAppSelector from "~/hooks/use-app-selector.ts";
import StatusMainPanel from "~/components/ui/status-main-panel.tsx";
import LoadingAnimation from "~/components/ui/loading-animation.tsx";
import { isOnceOffError, isOnceOffNotComplete } from "~/utils/loading.ts";
import { selectSystemPaletteLoad } from "./selectors.ts";

function PaletteLoadStatus() {
	const load = useAppSelector(selectSystemPaletteLoad);

	return (
		<StatusMainPanel>
			{isOnceOffNotComplete(load) && (
				<LoadingAnimation>Loading bricks...</LoadingAnimation>
			)}
			{isOnceOffError(load) && (
				<div className="form-error">Error loading bricks: {load.message}</div>
			)}
		</StatusMainPanel>
	);
}

export default PaletteLoadStatus;
