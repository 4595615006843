import { getPenColoursSet } from "@brickme/project-core/src";
import type { AppState } from "~/store/root-reducer.ts";
import {
	isFrameCompatibleWithProject,
	getFrameVariantForPicture,
} from "~/features/preview/frame.ts";
import { isOnceOffLoaded } from "~/utils/loading.ts";

function selectOptionalOpenProject(state: AppState) {
	return state.workspace.openProject;
}

function selectOpenProject(state: AppState) {
	const openProject = selectOptionalOpenProject(state);
	if (!openProject) {
		throw new Error("No workspace project");
	}
	return openProject;
}

function selectOpenProjectProject(state: AppState) {
	return selectOpenProject(state).project;
}

function selectHasOpenProject(state: AppState) {
	return !!state.workspace.openProject;
}

function selectCurrentPictureConfig(state: AppState) {
	return selectOpenProjectProject(state).currentPicture;
}

function selectCurrentPictureOutput(state: AppState) {
	return selectOpenProject(state).build;
}

function selectCurrentPictureBrickCounts(state: AppState) {
	return selectOpenProject(state).build.brickCounts;
}

function selectCurrentPictureHasPenMarks(state: AppState) {
	const p = selectCurrentPictureConfig(state);
	return getPenColoursSet(p.pen).size > 0;
}

function selectCurrentPicturePaletteMode(state: AppState) {
	return selectCurrentPictureConfig(state).paletteMode;
}

function selectCurrentPictureDetailFilter(state: AppState) {
	return selectCurrentPictureConfig(state).detailFilter;
}

function selectCurrentPictureImageZoom(state: AppState) {
	return selectCurrentPictureConfig(state).imageZoom;
}

function selectCurrentPictureBasePlateSize(state: AppState) {
	return selectCurrentPictureConfig(state).basePlateSize;
}

function selectCurrentPictureEnhanceFaces(state: AppState) {
	return selectCurrentPictureConfig(state).enhanceFaces;
}

function selectCurrentPictureFixFaceColours(state: AppState) {
	return selectCurrentPictureConfig(state).fixFaceColours;
}

function selectCurrentPictureRemoveBackground(state: AppState) {
	return selectCurrentPictureConfig(state).removeBackground;
}

function selectNumberOfPaletteColours(state: AppState) {
	const paletteMode = selectCurrentPicturePaletteMode(state);
	if (paletteMode.type === "auto") {
		return paletteMode.numberOfColours;
	}
	const counts = selectCurrentPictureOutput(state).brickCounts;
	return Object.values(counts).filter((c) => c.count > 0).length;
}

function selectCurrentPictureNumberOfBasePlates(state: AppState) {
	return selectCurrentPictureConfig(state).numberOfBasePlates;
}

function selectOpenProjectBuiltCurrentPictureUpdatedAt(state: AppState) {
	return state.workspace.openProject?.builtCurrentPictureUpdatedAt;
}

function selectCurrentPictureCompatibleFrames(state: AppState) {
	const picture = selectCurrentPictureConfig(state);
	const {
		reference: { frames },
	} = state;
	const allFrames = isOnceOffLoaded(frames) ? frames.data : [];
	return allFrames.filter((f) => isFrameCompatibleWithProject(f, picture));
}

function selectCurrentPictureCompatibleFramesWithInStockPrice(state: AppState) {
	const picture = selectCurrentPictureConfig(state);
	const compatibleFrames = selectCurrentPictureCompatibleFrames(state);
	return compatibleFrames.filter(
		(f) => getFrameVariantForPicture(picture, f).inStock,
	);
}

export {
	selectCurrentPictureBasePlateSize,
	selectOpenProjectBuiltCurrentPictureUpdatedAt,
	selectCurrentPictureCompatibleFrames,
	selectCurrentPictureCompatibleFramesWithInStockPrice,
	selectOptionalOpenProject,
	selectCurrentPictureNumberOfBasePlates,
	selectNumberOfPaletteColours,
	selectCurrentPictureDetailFilter,
	selectCurrentPicturePaletteMode,
	selectCurrentPictureHasPenMarks,
	selectCurrentPictureBrickCounts,
	selectCurrentPictureOutput,
	selectOpenProject,
	selectOpenProjectProject,
	selectHasOpenProject,
	selectCurrentPictureConfig,
	selectCurrentPictureEnhanceFaces,
	selectCurrentPictureFixFaceColours,
	selectCurrentPictureRemoveBackground,
	selectCurrentPictureImageZoom,
};
