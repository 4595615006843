import { gql } from "graphql-request";
import type { TransportProject } from "@brickme/project-core/src";

const updateProjectMutation = gql`
	mutation updateProjectMutation($input: UpdateProjectInput!) {
		updateProject(input: $input)
	}
`;

type UpdateProjectMutation = {
	readonly updateProject: true;
};

type UpdateProjectMutationVariables = {
	readonly input: {
		readonly id: string;
	} & Pick<TransportProject, "currentPicture" | "otherVersions" | "updatedAt">;
};

export type { UpdateProjectMutation, UpdateProjectMutationVariables };
export default updateProjectMutation;
