import { findOnlyOrThrow } from "@dhau/lang-extras";
import {
	rgbHexStringToRgbNumber,
	rgbToRgba,
} from "./utils/colour-conversions.ts";
import type { Palette } from "./model/index.ts";

const basePlateHexColour = "#ffffff";

const redMask24 = 0xff0000;
const greenMask24 = 0x00ff00;
const blueMask24 = 0x0000ff;

const basePlateRgb = rgbHexStringToRgbNumber(basePlateHexColour);

const premadeKit = {
	basePlateSize: 16,
	numberOfBasePlates: 9,
	basePlateColour: {
		rgba: rgbToRgba(basePlateRgb, 0xff),
		r: (basePlateRgb & redMask24) >> 16,
		g: (basePlateRgb & greenMask24) >> 8,
		b: basePlateRgb & blueMask24,
	},
	bricks: [
		{
			colourHex: "#161616",
			amount: 700,
		},
		{
			colourHex: "#f4f4f4",
			amount: 700,
		},
		{
			colourHex: "#d0cec9",
			amount: 700,
		},
		{
			colourHex: "#8c8a88",
			amount: 700,
		},
		{
			colourHex: "#646464",
			amount: 700,
		},
		{
			colourHex: "#333f48",
			amount: 700,
		},
		{
			colourHex: "#b2b4b2",
			amount: 700,
		},
	],
};

function createPremadeKitPalettes(
	systemPalette: Palette,
	numberOfKits: number,
) {
	const kitsPalette = premadeKit.bricks.map(({ colourHex, amount }) => ({
		brick: findOnlyOrThrow(
			systemPalette,
			(b) => b.brick.hexString === colourHex,
		).brick,
		limit: amount * numberOfKits,
	}));
	const nonKitsPalette = systemPalette.filter(
		(b) => !kitsPalette.some((k) => k.brick === b.brick),
	);
	return {
		kitsPalette,
		kitPlusExtrasPalette: [...kitsPalette, ...nonKitsPalette],
	};
}

export { premadeKit, createPremadeKitPalettes };
