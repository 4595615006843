type SourceSpec = {
	readonly backgroundMask: boolean;
	readonly colorisation: boolean;
	readonly enhanceFaces: boolean;
	readonly faceBoundingBoxes: boolean;
};

type GenericPictureConfiguration = {
	readonly removeBackground?: object | null;
	readonly fixFaceColours?: boolean;
	readonly enhanceFaces?: boolean;
};

function getMissingBuildSources(
	{
		removeBackground,
		fixFaceColours,
		enhanceFaces,
	}: GenericPictureConfiguration,
	availableSources: SourceSpec,
): SourceSpec {
	return {
		backgroundMask:
			(!!fixFaceColours || !!removeBackground) &&
			!availableSources.backgroundMask,
		colorisation: !!fixFaceColours && !availableSources.colorisation,
		faceBoundingBoxes: !!fixFaceColours && !availableSources.faceBoundingBoxes,
		enhanceFaces: !!enhanceFaces && !availableSources.enhanceFaces,
	};
}

export default getMissingBuildSources;
