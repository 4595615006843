import { uniqBy, countBy, range } from "lodash-es";
import { z } from "zod";
import type { Bitmap, WritableBitmap } from "../bitmap/index.ts";
import { bitmapChannels } from "../bitmap/index.ts";
import type { DistanceSize } from "./distance.ts";
import { distanceFromMillimeters } from "./distance.ts";
import type { GenericPosition, GenericSize } from "./geom.ts";
import { basePlateSizeSchema } from "./project-primitives.ts";
import type { BrickColourHexString, BrickColour } from "./bricks.ts";
import { brickColourSchema } from "./bricks.ts";
import type { UnixTimestamp } from "./time.ts";
import { weightFromG } from "./weight.ts";
import { readUInt32BE } from "../utils/uint8-array.ts";

type PaletteItem = {
	readonly brick: BrickColour;
	readonly limit: number | undefined;
};

type Palette = readonly PaletteItem[];

const autoPaletteModeSchema = z.object({
	type: z.literal("auto"),
	numberOfColours: z.number().int().nonnegative(),
});

const customPaletteModeSchema = z.object({
	type: z.literal("custom"),
	palette: z.array(brickColourSchema) as z.Schema<readonly BrickColour[]>,
});

const hybridPaletteModeSchema = z.object({
	type: z.literal("hybrid"),
	palette: z.array(brickColourSchema) as z.Schema<readonly BrickColour[]>,
	numberOfExtraColours: z.number().int().nonnegative(),
});

type CustomPaletteMode = z.infer<typeof customPaletteModeSchema>;

type AutoPaletteMode = z.infer<typeof autoPaletteModeSchema>;

type HybridPaletteMode = z.infer<typeof hybridPaletteModeSchema>;

type PaletteMode = AutoPaletteMode | CustomPaletteMode | HybridPaletteMode;

const genericPositionNumber: z.Schema<GenericPosition<number>> = z.object({
	x: z.number().int(),
	y: z.number().int(),
});

type BrickPosition = GenericPosition<number>;

const penMarkSchema = z.object({
	position: genericPositionNumber,
	colour: brickColourSchema,
});

type PenMark = z.infer<typeof penMarkSchema>;

const maxImageZoom = 5;

type NumberOfBricks = GenericSize<number>;
type ImageZoomOffset = GenericPosition<number>;

type PenMarkings = readonly PenMark[];

function getPenColoursSet(pen: PenMarkings): ReadonlySet<BrickColour> {
	return new Set(
		uniqBy(
			pen.map((b) => b.colour),
			(c) => c.rgba,
		),
	);
}

type DetailFilter = {
	readonly sigma: number;
	readonly radiusRatio: number;
};

const genericSizeNumber: z.Schema<GenericSize<number>> = z.object({
	width: z.number().nonnegative(),
	height: z.number().nonnegative(),
});

const pictureConfigurationSchema = z.object({
	imageZoom: z.number(),
	imageZoomOffset: genericPositionNumber,

	basePlateSize: basePlateSizeSchema,
	numberOfBasePlates: genericSizeNumber,
	// Derived from basePlateSize and numberOfBasePlates to save on computation
	/** @deprecated Very small computation cost where needed.
	 * Big potential issues trying to keep in sync */
	numberOfBricks: genericSizeNumber,

	removeBackground: z
		.object({
			newColour: brickColourSchema,
		})
		.or(
			z.object({
				// .literal(true) not supported byt graphql conversion
				noBrick: z.boolean(),
			}),
		)
		.optional(),
	enhanceFaces: z.boolean().optional(),
	fixFaceColours: z.boolean().optional(),

	brightness: z.number(),
	contrast: z.number(),
	saturation: z.number(),

	detailFilter: z
		.object({
			sigma: z.number(),
			radiusRatio: z.number(),
		})
		.optional(),

	paletteMode: autoPaletteModeSchema
		.or(customPaletteModeSchema)
		.or(hybridPaletteModeSchema),

	pen: z.array(penMarkSchema) as z.Schema<
		readonly z.infer<typeof penMarkSchema>[]
	>,

	updatedAt: z.number().int().positive() as z.Schema<UnixTimestamp>,
});

type PictureConfiguration = z.infer<typeof pictureConfigurationSchema>;

type PenOnlyBrickCount = {
	readonly type: "pen-only";
	readonly count: number;
};

type PaletteBrickCount = {
	readonly type: "palette";
	readonly count: number;
};

type BrickCount = PenOnlyBrickCount | PaletteBrickCount;

type BrickCounts = Record<BrickColourHexString, BrickCount>;

type OperationMissingSourcesName =
	| "Fix face colours"
	| "Enhance faces"
	| "Remove background";

type BuildBitmap = Bitmap & {
	readonly noBrickColour: Pick<BrickColour, "rgba"> | undefined;
};

type WritableBuildBitmap = WritableBitmap & Pick<BuildBitmap, "noBrickColour">;

type BrickedPictureOutput = {
	readonly image: BuildBitmap;
	readonly operationsMissingSources: readonly OperationMissingSourcesName[];
	readonly brickCounts: BrickCounts;
};

const realWorldBrickSize = distanceFromMillimeters(8);
const brickWeight = weightFromG(0.18);
const notchRadius = distanceFromMillimeters(4.8);
const notchRidgeWidth = distanceFromMillimeters(0.23);

// Keep dependencies to individual properties to allow lighter hook dependency checks
function realWorldPictureSize(numberOfBricks: NumberOfBricks): DistanceSize {
	return {
		width: distanceFromMillimeters(
			numberOfBricks.width * realWorldBrickSize.mm,
		),
		height: distanceFromMillimeters(
			numberOfBricks.height * realWorldBrickSize.mm,
		),
	};
}

type Option<T> = {
	readonly value: T;
	readonly label: string;
	readonly disabled?: boolean;
};

type OptionsList<T> = readonly Option<T>[];

function numberOfBrickColours(brickCounts: BrickCounts): number {
	return Object.values(brickCounts).filter(({ count }) => count > 0).length;
}

function numberOfBrickColoursInBuildImage(image: Bitmap): number {
	return Object.keys(
		countBy(
			range(0, image.width * image.height).map((i) =>
				readUInt32BE(image.data, i * bitmapChannels),
			),
		),
	).length;
}

function countTotalNumberOfBricks({
	basePlateSize,
	numberOfBasePlates,
}: Pick<PictureConfiguration, "basePlateSize" | "numberOfBasePlates">) {
	return (
		numberOfBasePlates.width *
		basePlateSize *
		numberOfBasePlates.height *
		basePlateSize
	);
}

export type {
	PictureConfiguration,
	DetailFilter,
	BrickCount,
	BrickCounts,
	OptionsList,
	Option,
	BrickedPictureOutput,
	CustomPaletteMode,
	AutoPaletteMode,
	PaletteMode,
	Palette,
	PenMark,
	BrickPosition,
	NumberOfBricks,
	ImageZoomOffset,
	PenMarkings,
	HybridPaletteMode,
	OperationMissingSourcesName,
	BuildBitmap,
	WritableBuildBitmap,
};
export {
	notchRidgeWidth,
	getPenColoursSet,
	maxImageZoom,
	realWorldBrickSize,
	brickWeight,
	notchRadius,
	pictureConfigurationSchema,
	realWorldPictureSize,
	countTotalNumberOfBricks,
	numberOfBrickColours,
	numberOfBrickColoursInBuildImage,
};
