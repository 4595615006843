import { gql } from "graphql-request";

export type GetPicToBrickCategoriesQuery = {
	readonly picToBrickCategories: readonly {
		readonly id: string;
		readonly name: string;
		readonly pics: readonly {
			readonly id: string;
			readonly name: string;
			readonly imageUrl: string;
			readonly thumbnailImageUrl: string;
		}[];
	}[];
};

export default gql`
	query getPicToBrickCategoriesQuery {
		picToBrickCategories {
			id
			name
			pics {
				id
				name
				imageUrl
				thumbnailImageUrl
			}
		}
	}
`;
