import { backOff } from "exponential-backoff";
import type { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";

type TempMediaPresignedUrlQuery = {
	readonly tempMediaPresignedUrl: {
		readonly key: string;
		readonly url: string;
	};
};

type TempMediaPresignedUrlVariables = {
	readonly fileName: string;
	readonly contentType: string;
};

function createFileStorage(apiClient: GraphQLClient) {
	return {
		async saveFile(fileName: string, contentType: string, file: ArrayBuffer) {
			const result = await apiClient.request<
				TempMediaPresignedUrlQuery,
				TempMediaPresignedUrlVariables
			>(
				gql`
					query TempMediaPresignedUrlQuery(
						$fileName: String!
						$contentType: String!
					) {
						tempMediaPresignedUrl(
							fileName: $fileName
							contentType: $contentType
						) {
							key
							url
						}
					}
				`,
				{ fileName, contentType },
			);
			await backOff(
				() =>
					fetch(result.tempMediaPresignedUrl.url, {
						method: "PUT",
						headers: { "Content-Type": contentType },
						body: file,
					}),
				{
					startingDelay: 500,
					numOfAttempts: 5,
					timeMultiple: 2,
					maxDelay: 4_000,
				},
			);
			return result.tempMediaPresignedUrl.key;
		},
	};
}

type FileStorage = ReturnType<typeof createFileStorage>;

export type { FileStorage };
export default createFileStorage;
