import { gql } from "graphql-request";
import type {
	TransportPictureConfiguration,
	FaceBoundingBox,
} from "@brickme/project-core/src";
import { transportPictureOutputFragment } from "./get-saved-designs-query.ts";

type GetBrickArtDesignQuery = {
	readonly brickArtDesign: {
		readonly variantPictures: readonly Omit<
			TransportPictureConfiguration,
			"buildNumberOfColours"
		>[];
		readonly faceBoundingBoxes: readonly FaceBoundingBox[] | null;
		readonly sourceImageUrl: string;
		readonly sourceImageKey: string;
		readonly backgroundMaskImageUrl: string;
		readonly colorisationImageUrl: string;
		readonly enhanceFacesImageUrl: string;
	};
};

type GetBrickArtDesignVariables = {
	readonly id: string;
};

const getBrickArtDesignQuery = gql`
	query getBrickArtDesignQuery($id: ID!) {
		brickArtDesign(id: $id) {
			sourceImageUrl
			sourceImageKey
			faceBoundingBoxes {
				x
				y
				width
				height
			}
			backgroundMaskImageUrl
			colorisationImageUrl
			enhanceFacesImageUrl
			variantPictures {
				...transportPictureConfigurationOutput
			}
		}
	}
	${transportPictureOutputFragment}
`;

export type { GetBrickArtDesignQuery, GetBrickArtDesignVariables };
export default getBrickArtDesignQuery;
