/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
// See http://colorizer.org/ for general color space ref

type LabComponents = {
	readonly l: number;
	readonly a: number;
	readonly b: number;
};

const startMask24 = 0xff0000;
const greenMask24 = 0x00ff00;
const endMask24 = 0x0000ff;

function rgbToRgba(rgb: number, a: number): number {
	const r = (rgb & startMask24) >> 16;
	const g = (rgb & greenMask24) >> 8;
	const b = rgb & endMask24;
	return ((r << 24) | (g << 16) | (b << 8) | a) >>> 0;
}

function rgbaToRgb(rgb: number): number {
	// Must be an easier way
	return ((rgb >> 8) >>> 0) & 0xffffff;
}

// From https://github.com/antimatter15/rgb-lab
function rgbToLab(r16: number, g16: number, b16: number): LabComponents {
	let r = r16 / 0xff;
	let g = g16 / 0xff;
	let b = b16 / 0xff;

	r = r > 0.04045 ? ((r + 0.055) / 1.055) ** 2.4 : r / 12.92;
	g = g > 0.04045 ? ((g + 0.055) / 1.055) ** 2.4 : g / 12.92;
	b = b > 0.04045 ? ((b + 0.055) / 1.055) ** 2.4 : b / 12.92;

	let x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
	let y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1;
	let z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

	x = x > 0.008856 ? x ** (1 / 3) : 7.787 * x + 16 / 116;
	y = y > 0.008856 ? y ** (1 / 3) : 7.787 * y + 16 / 116;
	z = z > 0.008856 ? z ** (1 / 3) : 7.787 * z + 16 / 116;

	return {
		l: Math.round(116 * y - 16),
		a: Math.round(500 * (x - y)),
		b: Math.round(200 * (y - z)),
	};
}

function labToRgb({ l, a, b }: LabComponents): {
	r: number;
	g: number;
	b: number;
} {
	let y = (l + 16) / 116;
	let x = a / 500 + y;
	let z = y - b / 200;

	x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
	y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
	z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

	let red = x * 3.2406 + y * -1.5372 + z * -0.4986;
	let green = x * -0.9689 + y * 1.8758 + z * 0.0415;
	let blue = x * 0.0557 + y * -0.204 + z * 1.057;

	red = red > 0.0031308 ? 1.055 * Math.pow(red, 1 / 2.4) - 0.055 : 12.92 * red;
	green =
		green > 0.0031308
			? 1.055 * Math.pow(green, 1 / 2.4) - 0.055
			: 12.92 * green;
	blue =
		blue > 0.0031308 ? 1.055 * Math.pow(blue, 1 / 2.4) - 0.055 : 12.92 * blue;

	return {
		r: Math.round(Math.max(0, Math.min(1, red)) * 255),
		g: Math.round(Math.max(0, Math.min(1, green)) * 255),
		b: Math.round(Math.max(0, Math.min(1, blue)) * 255),
	};
}

function rgbaNumberToHexString(num: number): string {
	return `#${num.toString(16).padStart(8, "0")}`;
}

function rgbHexStringToRgbNumber(hex: string): number {
	if (hex.length !== 7 || !hex.startsWith("#")) {
		throw new Error(`Invalid hex colour ${hex}`);
	}
	return parseInt(hex.substring(1), 16);
}

function rgbHexStringToRgbaNumber(hex: string): number {
	return rgbToRgba(rgbHexStringToRgbNumber(hex), 0xff);
}

type Hsl = {
	// 0 - 360
	readonly h: number;
	// 0 - 1
	readonly s: number;
	// 0 - 1
	readonly l: number;
};

function bound01(n: number, max: number) {
	n = Math.min(max, Math.max(0, n));

	// Automatically convert percentage into number

	// Handle floating point rounding errors
	if (Math.abs(n - max) < 0.000001) {
		return 1;
	}

	// Convert into [0, 1] range if it isn't already
	return (n % max) / max;
}

// Taken from tinycolor
function rgbToHsl(r: number, g: number, b: number): Hsl {
	r = bound01(r, 0xff);
	g = bound01(g, 0xff);
	b = bound01(b, 0xff);
	// make sure rgb are contained in a set of [0, 255]
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h = (max + min) / 2;
	let s = (max + min) / 2;
	const l = (max + min) / 2;
	if (max == min) {
		h = s = 0; // achromatic
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}
	return {
		h: h * 360,
		s,
		l,
	};
}

// Taken from lib tinycolor
function hslToRgb(h: number, s: number, l: number) {
	let r, g, b: number;
	h = bound01(h, 360);
	s = bound01(s, 1);
	l = bound01(l, 1);

	function hue2rgb(p: number, q: number, t: number) {
		if (t < 0) t += 1;
		if (t > 1) t -= 1;
		if (t < 1 / 6) return p + (q - p) * 6 * t;
		if (t < 1 / 2) return q;
		if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
		return p;
	}
	if (s === 0) {
		r = g = b = l; // achromatic
	} else {
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}
	// TODO: Pretty sure there's a bit shift way to do this
	return {
		r: Math.round(r * 255),
		g: Math.round(g * 255),
		b: Math.round(b * 255),
	};
}

export type { LabComponents };
export {
	rgbaToRgb,
	rgbaNumberToHexString,
	rgbHexStringToRgbaNumber,
	rgbHexStringToRgbNumber,
	rgbToLab,
	rgbToHsl,
	rgbToRgba,
	hslToRgb,
	labToRgb,
};
