import type { ChangeEvent, DragEvent } from "react";
import React, { useCallback, useState } from "react";
import { clsx } from "clsx";
import type { OperatingSystem } from "~/features/reference/operating-system.ts";
import type { BrowserSource } from "~/features/reference/browser-source.ts";
import addImage from "~/styles/img/svg/graphic/add.svg";

type ImageFileUploadProps = {
	readonly os: OperatingSystem;
	readonly browserSource: BrowserSource;
	readonly onSelectFile: (file: File) => void;
};

function ImageFileUpload({
	browserSource,
	os,
	onSelectFile,
}: ImageFileUploadProps) {
	const [isHighlightedForDrop, setHighlightedForDrop] = useState(false);
	const onDragEnterOrOver = useCallback((e: DragEvent<HTMLLabelElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setHighlightedForDrop(true);
	}, []);
	const onDragLeave = useCallback((e: DragEvent<HTMLLabelElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setHighlightedForDrop(false);
	}, []);
	const onDrop = useCallback(
		(e: DragEvent<HTMLLabelElement>) => {
			e.preventDefault();
			e.stopPropagation();
			setHighlightedForDrop(false);
			const { files } = e.dataTransfer;
			if (files.length > 0) {
				onSelectFile(files[0]);
			}
		},
		[onSelectFile],
	);

	const onFileChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { files } = event.currentTarget;
			if (files && files.length >= 1) {
				onSelectFile(files[0]);
			}
		},
		[onSelectFile],
	);

	const isFbAndroid = browserSource === "facebook" && os === "android";

	return (
		// Not sure why isn't picking up properly. Maybe because no string in label?
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label
			className={clsx("choose-image__card file-card file-card_type-drop", {
				"pending-drop": isHighlightedForDrop,
			})}
			onDragEnter={onDragEnterOrOver}
			onDragOver={onDragEnterOrOver}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
			htmlFor="imageUploadField"
		>
			<input
				id="imageUploadField"
				type="file"
				name="image"
				className="file-card__input visually-hidden"
				// Issue with accept attr for in-app fb browser
				// https://stackoverflow.com/questions/27000708/
				// file-upload-control-not-working-in-facebook-in-app-browser
				accept={isFbAndroid ? undefined : "image/*"}
				onChange={onFileChange}
			/>
			<span className="file-card__inner">
				<span className="file-card__graph">
					<img
						src={addImage}
						alt="Add"
						className="file-card__graph-img file-card__graph-img__add"
					/>
				</span>
				<span className="file-card__title">Add your photo</span>
			</span>
		</label>
	);
}

export default ImageFileUpload;
