import { groupBy, compact, pick } from "lodash-es";
import type { GraphQLClient } from "graphql-request";
import type { BaseplateSize, Money } from "@brickme/project-core/src";
import { moneyFromDollars, frameBorderSize } from "@brickme/project-core/src";
import type {
	GetCreatorPicPricesQuery,
	GetCreatorPicPricesVariables,
} from "~/api/get-creator-pic-prices-query.ts";
import getCreatorPicPricesQuery from "~/api/get-creator-pic-prices-query.ts";
import type { ShopifyMoney } from "~/shopify/common.ts";
import type {
	ShopifyProductVariant,
	ShopifyProduct,
	GetAddOnQuery,
	GetAddOnVariables,
} from "~/shopify/get-add-on-query.ts";
import {
	hangingStripsQueryInput,
	preassemblyQueryInput,
	getAddOnQuery,
	framesQueryInput,
} from "~/shopify/get-add-on-query.ts";
import type { PictureAddOnVariant } from "~/model.d.ts";
import { shopifyGraphQlIdToSourceId } from "~/utils/shopify-ids.ts";

function shopifyMoneyToLocal(money: ShopifyMoney): Money {
	return moneyFromDollars(parseFloat(money.amount), money.currencyCode);
}

async function loadPicPrices(
	apiClient: GraphQLClient,
	countryCode: string,
	currencyCode: string,
) {
	const aData = await apiClient.request<
		GetCreatorPicPricesQuery,
		GetCreatorPicPricesVariables
	>(getCreatorPicPricesQuery, { countryCode });
	return aData.creatorPicCountryPrices.prices.map((p) => ({
		...pick(p, "size", "basePlateSize", "colourPremium"),
		discountRatio: parseFloat(p.discountRatio),
		colourPremiumRatio: parseFloat(p.colourPremiumRatio),
		total: moneyFromDollars(parseFloat(p.price), currencyCode),
	}));
}

async function loadHangingStrips(
	shopifyClient: GraphQLClient,
	countryCode: string,
) {
	const result = await shopifyClient.request<GetAddOnQuery, GetAddOnVariables>(
		getAddOnQuery,
		{
			query: hangingStripsQueryInput,
			countryCode,
		},
	);
	const product = result.products.edges[0]?.node;
	if (!product) {
		throw new Error("No hanging strips product found");
	}
	const variant = product.variants.edges[0]?.node;
	if (!product || !variant) {
		return undefined;
	}
	return {
		hangingStrips: {
			title: product.title,
			productVariantId: shopifyGraphQlIdToSourceId(
				"ProductVariant",
				variant.id,
			),
			inStock: variant.availableForSale,
		},
		price: shopifyMoneyToLocal(variant.price),
	};
}

const sizeRegex = /^.+?\((SB|LB) ([0-9]+?)x([0-9]+?)\)$/;

function createPreassemblyPrice(
	product: ShopifyProduct,
	variant: ShopifyProductVariant,
): Readonly<[PictureAddOnVariant, Money]> | undefined {
	const sizeOption = variant.selectedOptions.find((o) => o.name === "Size");
	if (!sizeOption) {
		return undefined;
	}
	const match = sizeOption.value.match(sizeRegex);
	if (!match) {
		return undefined;
	}
	const [, plateType, dimension1, dimension2] = match;
	return [
		{
			productVariantId: shopifyGraphQlIdToSourceId(
				"ProductVariant",
				variant.id,
			),
			productHandle: product.handle,
			inStock: variant.availableForSale,
			basePlateSize: plateType === "LB" ? 32 : 24,
			basePlateDimensions: [parseInt(dimension1, 10), parseInt(dimension2, 10)],
		},
		shopifyMoneyToLocal(variant.price),
	];
}

async function loadPreassemblyPrices(
	shopifyClient: GraphQLClient,
	countryCode: string,
) {
	const preassemblyProductsQueryResult = await shopifyClient.request<
		GetAddOnQuery,
		GetAddOnVariables
	>(getAddOnQuery, {
		query: preassemblyQueryInput,
		countryCode,
	});
	const matchingProduct = preassemblyProductsQueryResult.products.edges.find(
		(p) => p.node.options.some((o) => o.name === "Size"),
	);
	if (!matchingProduct) {
		return { options: [], prices: {} };
	}

	const optionPairs = compact(
		matchingProduct.node.variants.edges.map((e) =>
			createPreassemblyPrice(matchingProduct.node, e.node),
		),
	);
	const prices = Object.fromEntries(
		optionPairs.map(([v, p]) => [v.productVariantId, p]),
	);
	return {
		options: optionPairs.map(([o]) => o),
		prices,
	};
}

function getNormalisedProductTitle(title: string): string {
	return title.split(" - ")[0];
}

const basePlateConfigRegex = /^([0-9]+?)x([0-9]+?)$/;

function getProductBasePlateSize(
	product: ShopifyProduct,
	basePlateSizes: readonly BaseplateSize[],
): BaseplateSize | undefined {
	const result = basePlateSizes.find((s) =>
		product.title.includes(`${s}x${s}`),
	);
	if (result === undefined) {
		console.warn(`Could not find baseplate size for product ${product.title}`);
	}
	return result;
}

function getPictureFrameVariant(
	product: ShopifyProduct,
	variant: ShopifyProductVariant,
	basePlateSizes: readonly BaseplateSize[],
): Readonly<[PictureAddOnVariant, Money]> | undefined {
	const option = variant.selectedOptions.find(
		(o) => o.name === "Baseplate Configuration",
	);
	if (!option) {
		return undefined;
	}
	const match = option.value.match(basePlateConfigRegex);
	if (!match) {
		return undefined;
	}

	// Baseplate size no longer used in this app
	const basePlateSize = getProductBasePlateSize(product, basePlateSizes);
	if (basePlateSize === undefined) {
		return undefined;
	}

	return [
		{
			productVariantId: shopifyGraphQlIdToSourceId(
				"ProductVariant",
				variant.id,
			),
			productHandle: product.handle,
			inStock: variant.availableForSale && variant.quantityAvailable > 0,
			basePlateSize,
			basePlateDimensions: [parseInt(match[1], 10), parseInt(match[2], 10)],
		},
		shopifyMoneyToLocal(variant.price),
	] as const;
}

async function loadFrames(
	shopifyClient: GraphQLClient,
	countryCode: string,
	basePlateSizes: readonly BaseplateSize[],
) {
	const data = await shopifyClient.request<GetAddOnQuery, GetAddOnVariables>(
		getAddOnQuery,
		{ query: framesQueryInput, countryCode },
	);
	const productGroups = groupBy(data.products.edges, (e) =>
		getNormalisedProductTitle(e.node.title).toLowerCase(),
	);
	const frames = Object.values(productGroups).map((group) => {
		const productVariantPairs = group.flatMap((edge) =>
			edge.node.variants.edges.map((vEdge) => [edge.node, vEdge.node] as const),
		);
		const variantPricePairs = compact(
			productVariantPairs.map(([product, variant]) =>
				getPictureFrameVariant(product, variant, basePlateSizes),
			),
		);
		const referenceProduct = productVariantPairs[0][0];
		return {
			id: referenceProduct.handle,
			name: getNormalisedProductTitle(referenceProduct.title),
			borderSize: frameBorderSize,
			colourHex: "#000000",
			variants: variantPricePairs.map(([v]) => v),
			variantPricePairs,
		};
	});
	const prices = Object.fromEntries(
		frames.flatMap((f) =>
			f.variantPricePairs.map(([v, p]) => [v.productVariantId, p]),
		),
	);
	return { frames, prices };
}

export { loadPicPrices, loadHangingStrips, loadFrames, loadPreassemblyPrices };
