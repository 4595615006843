import React from "react";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import Button from "~/components/ui/button.tsx";
import FullHeader from "~/components/header/full-header.tsx";
import { hidePreview, showCreateBrickArt } from "~/features/nav/store-slice.ts";
import useOpenFinishClick from "~/features/order/use-open-finish-click.ts";
import { selectUser } from "~/features/user/selectors.ts";
import { setSaveAndCompareOpen } from "./store-slice.ts";

function PreviewHeader() {
	const dispatch = useAppDispatch();

	// Finish
	const { onOpenFinish: onFinishClick, openFinishDisabled } =
		useOpenFinishClick();

	// Save
	const isSaveAndCompareOpen = useAppSelector(
		(s) => s.preview.isSaveAndCompareOpen,
	);
	const onSaveClick = () => {
		dispatch(setSaveAndCompareOpen(!isSaveAndCompareOpen));
	};

	// Go back
	const onGoBackClick = () => {
		dispatch(hidePreview());
	};

	// Brick art
	const auth = useAppSelector(selectUser);
	const onCreateBrickArtClick = () => {
		dispatch(showCreateBrickArt());
	};

	return (
		<FullHeader
			actions={[
				<Button
					key="finish"
					className="main-header__actions-btn main-header__actions-btn_finish button button_color_red"
					size="small"
					onClick={onFinishClick}
					disabled={openFinishDisabled}
				>
					Finish &amp; Order
				</Button>,
				...(auth.type === "signedIn" && auth.user.artProductCreator
					? [
							<Button
								key="brick-art"
								className="main-header__actions-btn main-header__actions-btn_finish button button_color_red"
								size="small"
								onClick={onCreateBrickArtClick}
							>
								Create Brick Art
							</Button>,
						]
					: []),
				<Button
					key="save"
					className="main-header__actions-btn main-header__actions-btn_save"
					size="small"
					color="darkblue"
					onClick={onSaveClick}
					hideTextOnMobile
					leftIcon={
						<span className="button__text visible_mobile">Compare</span>
					}
				>
					Compare
				</Button>,
			]}
		>
			<h1 className="main-header__info plaintext">
				Click finish to order, or{" "}
				<button type="button" className="link-button" onClick={onGoBackClick}>
					go back
				</button>{" "}
				to make changes
			</h1>
		</FullHeader>
	);
}

export default PreviewHeader;
