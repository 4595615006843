import type { ReactElement } from "react";
import React, { useCallback } from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { selectUser } from "~/features/user/selectors.ts";
import { seenSignUpWelcome } from "~/features/user/store-slice.ts";

type JustSignedUpModalProps = {
	readonly show: boolean;
};

function JustSignedUpModal({ show }: JustSignedUpModalProps): ReactElement {
	const dispatch = useAppDispatch();
	const onSkip = useCallback(() => {
		dispatch(seenSignUpWelcome());
	}, [dispatch]);
	const user = useAppSelector(selectUser);
	const title = (() => {
		if (user.type === "signedIn" && user.user.firstName) {
			return `Welcome, ${user.user.firstName}!`;
		}
		return "Welcome!";
	})();

	return (
		<Modal title={title} show={show} onClose={onSkip}>
			<Modal.Text>
				Thanks for signing-up! We sent you an email with a link to complete your
				account registration and choose a password (if it&apos;s not in your
				inbox, please check your spam folder). Next time you sign-in, we will
				ask for your password.
			</Modal.Text>
			<Modal.Actions
				actions={[{ text: "Got it", onClick: onSkip, type: "ghost" }]}
			/>
		</Modal>
	);
}

export default JustSignedUpModal;
