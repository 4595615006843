const extensions: Record<string, string> = {
	"image/jpeg": "jpg",
	"image/png": "png",
	"image/webp": "webp",
	"image/gif": "gif",
};

function imageMimeTypeToFileExtension(mimeType: string) {
	const extension = extensions[mimeType];
	if (extension === undefined) {
		throw new Error(`Unknown mime type: ${mimeType}`);
	}
	return extension;
}

export { imageMimeTypeToFileExtension };
