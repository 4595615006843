import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import {
	openComplete,
	showCompleteLoginPrompt,
} from "~/features/nav/store-slice.ts";
import { selectUser } from "~/features/user/selectors.ts";

function useOpenFinishClick() {
	const dispatch = useAppDispatch();
	const { type: userType } = useAppSelector(selectUser);

	return {
		onOpenFinish: () => {
			if (userType !== "signedIn") {
				dispatch(showCompleteLoginPrompt());
				return;
			}

			dispatch(openComplete());
		},
		openFinishDisabled: false,
	};
}

export default useOpenFinishClick;
