// See http://alienryderflex.com/hsp.html
const pRedCoefficient = 0.299;
const pGreenCoefficient = 0.587;
const pBlueCoefficient = 0.114;

type Hsp = {
	readonly h: number;
	readonly s: number;
	readonly p: number;
};

type Rgb = {
	readonly r: number;
	readonly g: number;
	readonly b: number;
};

function hspToRgb(h0: number, s0: number, p0: number): Rgb {
	let h = h0 / 360.0;
	const s = s0 / 100.0;
	const p = p0;
	let r: number;
	let g: number;
	let b: number;
	let part: number;
	const minOverMax = 1.0 - s;

	if (minOverMax > 0.0) {
		if (h < 1.0 / 6.0) {
			//  R>G>B
			h = 6.0 * (h - 0.0 / 6.0);
			part = 1.0 + h * (1.0 / minOverMax - 1.0);
			b =
				p /
				Math.sqrt(
					pRedCoefficient / minOverMax / minOverMax +
						pGreenCoefficient * part * part +
						pBlueCoefficient,
				);
			r = b / minOverMax;
			g = b + h * (r - b);
		} else if (h < 2.0 / 6.0) {
			//  G>R>B
			h = 6.0 * (-h + 2.0 / 6.0);
			part = 1.0 + h * (1.0 / minOverMax - 1.0);
			b =
				p /
				Math.sqrt(
					pGreenCoefficient / minOverMax / minOverMax +
						pRedCoefficient * part * part +
						pBlueCoefficient,
				);
			g = b / minOverMax;
			r = b + h * (g - b);
		} else if (h < 3.0 / 6.0) {
			//  G>B>R
			h = 6.0 * (h - 2.0 / 6.0);
			part = 1.0 + h * (1.0 / minOverMax - 1.0);
			r =
				p /
				Math.sqrt(
					pGreenCoefficient / minOverMax / minOverMax +
						pBlueCoefficient * part * part +
						pRedCoefficient,
				);
			g = r / minOverMax;
			b = r + h * (g - r);
		} else if (h < 4.0 / 6.0) {
			//  B>G>R
			h = 6.0 * (-h + 4.0 / 6.0);
			part = 1.0 + h * (1.0 / minOverMax - 1.0);
			r =
				p /
				Math.sqrt(
					pBlueCoefficient / minOverMax / minOverMax +
						pGreenCoefficient * part * part +
						pRedCoefficient,
				);
			b = r / minOverMax;
			g = r + h * (b - r);
		} else if (h < 5.0 / 6.0) {
			//  B>R>G
			h = 6.0 * (h - 4.0 / 6.0);
			part = 1.0 + h * (1.0 / minOverMax - 1.0);
			g =
				p /
				Math.sqrt(
					pBlueCoefficient / minOverMax / minOverMax +
						pRedCoefficient * part * part +
						pGreenCoefficient,
				);
			b = g / minOverMax;
			r = g + h * (b - g);
		} else {
			//  R>B>G
			h = 6.0 * (-h + 6.0 / 6.0);
			part = 1.0 + h * (1.0 / minOverMax - 1.0);
			g =
				p /
				Math.sqrt(
					pRedCoefficient / minOverMax / minOverMax +
						pBlueCoefficient * part * part +
						pGreenCoefficient,
				);
			r = g / minOverMax;
			b = g + h * (r - g);
		}
	} else if (h < 1.0 / 6.0) {
		//  R>G>B
		h = 6.0 * (h - 0.0 / 6.0);
		r = Math.sqrt((p * p) / (pRedCoefficient + pGreenCoefficient * h * h));
		g = r * h;
		b = 0.0;
	} else if (h < 2.0 / 6.0) {
		//  G>R>B
		h = 6.0 * (-h + 2.0 / 6.0);
		g = Math.sqrt((p * p) / (pGreenCoefficient + pRedCoefficient * h * h));
		r = g * h;
		b = 0.0;
	} else if (h < 3.0 / 6.0) {
		//  G>B>R
		h = 6.0 * (h - 2.0 / 6.0);
		g = Math.sqrt((p * p) / (pGreenCoefficient + pBlueCoefficient * h * h));
		b = g * h;
		r = 0.0;
	} else if (h < 4.0 / 6.0) {
		//  B>G>R
		h = 6.0 * (-h + 4.0 / 6.0);
		b = Math.sqrt((p * p) / (pBlueCoefficient + pGreenCoefficient * h * h));
		g = b * h;
		r = 0.0;
	} else if (h < 5.0 / 6.0) {
		//  B>R>G
		h = 6.0 * (h - 4.0 / 6.0);
		b = Math.sqrt((p * p) / (pBlueCoefficient + pRedCoefficient * h * h));
		r = b * h;
		g = 0.0;
	} else {
		//  R>B>G
		h = 6.0 * (-h + 6.0 / 6.0);
		r = Math.sqrt((p * p) / (pRedCoefficient + pBlueCoefficient * h * h));
		b = r * h;
		g = 0.0;
	}

	return {
		r: Math.min(0xff, Math.round(r)),
		g: Math.min(0xff, Math.round(g)),
		b: Math.min(0xff, Math.round(b)),
	};
}

// Expected ranges: 0-1 on all components
function rgbToHsp(r: number, g: number, b: number): Hsp {
	let h: number = 0;
	let s: number = 0;

	//  Calculate the Perceived brightness
	const p = Math.sqrt(
		r * r * pRedCoefficient +
			g * g * pGreenCoefficient +
			b * b * pBlueCoefficient,
	);

	//  Calculate the Hue and Saturation
	if (r !== g || r !== b) {
		//  R is largest
		if (r >= g && r >= b) {
			if (b >= g) {
				h = 6.0 / 6.0 - ((1.0 / 6.0) * (b - g)) / (r - g);
				s = 1.0 - g / r;
			} else {
				h = 0.0 / 6.0 + ((1.0 / 6.0) * (g - b)) / (r - b);
				s = 1.0 - b / r;
			}
		}

		// G is largest
		if (g >= r && g >= b) {
			if (r >= b) {
				h = 2.0 / 6.0 - ((1.0 / 6.0) * (r - b)) / (g - b);
				s = 1 - b / g;
			} else {
				h = 2.0 / 6.0 + ((1.0 / 6.0) * (b - r)) / (g - r);
				s = 1.0 - r / g;
			}
		}

		// B is largest
		if (b >= r && b >= g) {
			if (g >= r) {
				h = 4.0 / 6.0 - ((1.0 / 6.0) * (g - r)) / (b - r);
				s = 1.0 - r / b;
			} else {
				h = 4.0 / 6.0 + ((1.0 / 6.0) * (r - g)) / (b - g);
				s = 1.0 - g / b;
			}
		}
	}

	return {
		h: Math.round(h * 360.0),
		s: s * 100.0,
		p: Math.round(p),
	};
}

export type { Hsp };
export { hspToRgb, rgbToHsp };
