import Cookies from "js-cookie";

const localisationCountryCodeCookieName = "countryCode";

type Options = {
	readonly secure: boolean;
	readonly cookieDomain: string;
};

function createPersistCountryService({ cookieDomain, secure }: Options) {
	let knownValue: string | undefined;
	return {
		get() {
			knownValue = Cookies.get(localisationCountryCodeCookieName);
			return knownValue;
		},
		set(countryCode: string) {
			if (countryCode !== knownValue) {
				const now = new Date();
				const expires = new Date(now.getFullYear() + 100, 0, 1);
				Cookies.set(localisationCountryCodeCookieName, countryCode, {
					expires: expires,
					domain: cookieDomain,
					secure,
					// None doesn't work when unsecure
					sameSite: secure ? "none" : "strict",
				});
				knownValue = countryCode;
			}
		},
	};
}

type PersistCountryService = ReturnType<typeof createPersistCountryService>;

export type { PersistCountryService };
export { createPersistCountryService };
