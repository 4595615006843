import type { Bitmap } from "../bitmap/index.ts";
import type { BitmapMask, PictureConfiguration } from "../model/index.ts";

// This is used in 3 separate places. Each place involves a bunch of
// calculations too. It's likely better to do it once - i.e. scale
// down the backgroundMask into "post resize and crop" version.
// Likely not a huge perf win, but cleaner and does provide some benefit.
// Need to think of a good way to implement it.
//  - operations can return an updated bg mask (cleanest, but complicates
//    API a bit more and less efficient than doing crop + resize in one go)
//  - can provide a post crop + resize mask from start, but then how
//    does each op know which to use? a bit leaky
function compileMaskingProps(
	{ removeBackground, imageZoomOffset, imageZoom }: PictureConfiguration,
	backgroundMask: BitmapMask | undefined,
	intermediateImage: Bitmap,
) {
	if (!backgroundMask || !removeBackground) {
		return undefined;
	}

	const resizeXScale = intermediateImage.width / backgroundMask.width;
	const resizeYScale = intermediateImage.height / backgroundMask.height;
	const usedResizeScale = Math.max(resizeXScale, resizeYScale) * imageZoom;

	const preCropWidth = backgroundMask.width * usedResizeScale;
	const preCropHeight = backgroundMask.height * usedResizeScale;

	const croppedXOffset =
		(preCropWidth - intermediateImage.width) / 2 - imageZoomOffset.x;
	const croppedYOffset =
		(preCropHeight - intermediateImage.height) / 2 - imageZoomOffset.y;

	return {
		croppedXOffset,
		croppedYOffset,
		usedResizeScale,
		foregroundRatio: backgroundMask.foregroundRatio,
	};
}

export default compileMaskingProps;
