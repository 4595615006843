import { unknownToError } from "@dhau/lang-extras";

function runGeneratorSync<T>(generator: Generator<unknown, T>): T {
	let result;
	do {
		result = generator.next();
	} while (!result.done);
	return result.value;
}

type AsyncOptions = {
	readonly abortSignal: AbortSignal;
};

// Maybe 16 ms better?
const maxFrameTime = 10;

/* eslint-disable @typescript-eslint/unified-signatures */
function runGeneratorAsync<T>(generator: Generator<unknown, T>): Promise<T>;
function runGeneratorAsync<T>(
	generator: Generator<unknown, T>,
	options: undefined,
): Promise<T>;
function runGeneratorAsync<T>(
	generator: Generator<unknown, T>,
	options: AsyncOptions,
): Promise<T | undefined>;

// Some notes from tests:
// - queueMicrotask doesn't allow external scripts a chance to abort
// - setTimeout is a larger gap, but allows externals to abort
// If browser has it and in browser context, this might be useful:
// https://developer.mozilla.org/en-US/docs/Web/API/Prioritized_Task_Scheduling_API
// esp. isInputPending
function runGeneratorAsync<T>(
	generator: Generator<unknown, T>,
	options?: AsyncOptions,
): Promise<T | undefined> {
	return new Promise((resolve, reject) => {
		function runStep() {
			if (options?.abortSignal.aborted) {
				resolve(undefined);
				return;
			}

			let result;
			const endTime = performance.now() + maxFrameTime;
			do {
				try {
					result = generator.next();
				} catch (e) {
					reject(unknownToError(e));
					return;
				}
			} while (performance.now() < endTime && !result.done);

			if (result.done) {
				resolve(result.value);
				return;
			}

			setTimeout(runStep, 0);
		}

		runStep();
	});
}

async function runAsyncGenerator<T>(
	generator: AsyncGenerator<unknown, T>,
): Promise<T> {
	let result = await generator.next();
	while (!result.done) {
		// eslint-disable-next-line no-await-in-loop
		result = await generator.next();
	}
	return result.value;
}

/* eslint-disable-next-line require-yield */
function* constantGenerator<T>(value: T): Generator<undefined, T> {
	return value;
}

export {
	constantGenerator,
	runGeneratorSync,
	runGeneratorAsync,
	runAsyncGenerator,
};
