import type {
	PictureConfiguration,
	BaseplateSize,
} from "@brickme/project-core/src";
import {
	originPosition,
	multiplySize,
	getEnhanceDetailOptions,
} from "@brickme/project-core/src";
import { minByOrThrow } from "@dhau/lang-extras";
import isDetailFilterSupported from "~/utils/is-detail-filter-supported.ts";
import getDefaultNumberOfBasePlates from "./get-default-number-of-base-plates.ts";

const defaultAutoPaletteModeNumberOfColours = 20;
const defaultContrast = 0.15;
const defaultSaturation = 0.05;
const defaultBrightness = 0;

function createDefaultPictureConfiguration(
	basePlateSizes: readonly BaseplateSize[],
	imageWidth: number,
	imageHeight: number,
): PictureConfiguration {
	const numberOfBasePlates = getDefaultNumberOfBasePlates(
		imageWidth,
		imageHeight,
	);
	const defaultEnhanceDetailOption = getEnhanceDetailOptions().find(
		(o) => o.value !== undefined,
	)?.value;
	// Want the closest base plate to 24
	const basePlateSize = minByOrThrow(basePlateSizes, (s) => Math.abs(s - 24));
	return {
		imageZoom: 1,
		imageZoomOffset: originPosition,
		basePlateSize,
		numberOfBasePlates,
		numberOfBricks: multiplySize(numberOfBasePlates, basePlateSize),
		paletteMode: {
			type: "auto",
			numberOfColours: defaultAutoPaletteModeNumberOfColours,
		},
		removeBackground: undefined,
		enhanceFaces: false,
		fixFaceColours: false,
		detailFilter: isDetailFilterSupported
			? defaultEnhanceDetailOption
			: undefined,
		brightness: defaultBrightness,
		contrast: defaultContrast,
		saturation: defaultSaturation,
		pen: [],
		updatedAt: Date.now(),
	};
}

export {
	defaultContrast,
	defaultSaturation,
	defaultBrightness,
	defaultAutoPaletteModeNumberOfColours,
};
export default createDefaultPictureConfiguration;
