import React from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import { closeBasePlatConversionWarning } from "./store-slice.ts";

type BasePlateConversionWarningModalProps = {
	readonly show: boolean;
};

function BasePlateConversionWarningModal({
	show,
}: BasePlateConversionWarningModalProps) {
	const dispatch = useAppDispatch();
	const onClose = () => {
		dispatch(closeBasePlatConversionWarning());
	};

	return (
		<Modal show={show} title="Large baseplates are temporarily out of stock">
			<Modal.Text>
				We switched your design to small baseplates. To increase the size of the
				mosaic you can add more baseplates.
			</Modal.Text>
			<Modal.Actions
				actions={[{ text: "Close", onClick: onClose, type: "ghost" }]}
			/>
		</Modal>
	);
}

export default BasePlateConversionWarningModal;
