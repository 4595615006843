/* eslint-disable no-param-reassign */
import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { pick, uniqueId } from "lodash-es";
import type {
	UnixTimestamp,
	PictureConfiguration,
} from "@brickme/project-core/src";
import {
	transportProjectToCore,
	removeTypenames,
	multiplySize,
} from "@brickme/project-core/src";
import type { MeasurementUnits } from "@brickme/project-core/src/model/formatted-project-size.ts";
import { produce } from "immer";
import type { AuthUser, AccessToken } from "~/frontend-common/auth.ts";
import deleteProjectMutation from "~/api/delete-project-mutation.graphql";
import type {
	DeleteProjectMutation,
	DeleteProjectMutationVariables,
} from "~/api/delete-project-mutation.generated.ts";
import type { GetHasSavedDesignStatusQuery } from "~/api/get-has-saved-design-status.ts";
import getHasSavedDesignStatusQuery from "~/api/get-has-saved-design-status.ts";
import type {
	GetSavedDesignByIdQuery,
	GetSavedDesignByIdVariables,
} from "~/api/get-saved-design-by-id.ts";
import getSavedDesignByIdQuery from "~/api/get-saved-design-by-id.ts";
import type {
	FullSavedProject,
	GetSavedDesignsQuery,
	GetSavedDesignsVariables,
} from "~/api/get-saved-designs-query.ts";
import getSavedDesignsQuery from "~/api/get-saved-designs-query.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import type { State as ReferenceState } from "~/features/reference/state.ts";
import { ensurePicPricesLoaded } from "~/features/reference/store-slice.ts";
import {
	ensureLoadedOnceOffLoad,
	pendingUpdatableLoad,
	isUpdatableLoaded,
	loadingUpdatableLoad,
	errorUpdatableLoad,
	performUpdatableLoad,
} from "~/utils/loading.ts";
import startProject from "~/features/workspace/start-project.ts";
import waitForCountries from "~/features/reference/wait-for-countries.ts";
import type { AppAuthUser } from "./app-auth-user.ts";

const name = "user";

type UserStatus =
	| { type: "loadingPrevious" }
	| { type: "anonymous" }
	| { type: "guest" }
	| { type: "checkingEmailAddress" }
	| { type: "checkedEmailAddress"; isUsed: boolean; readonly error?: string }
	| { type: "signingUp" }
	| { type: "checkingSignIn" }
	| { type: "invalidSignIn" }
	| { type: "signedIn"; user: AppAuthUser };

function isSignedOutUserStatus(status: UserStatus): boolean {
	return status.type === "anonymous";
}

type IpAddressValue =
	| {
			readonly value: undefined;
			readonly country: undefined;
			readonly attempted: false;
	  }
	| {
			readonly value: string | undefined;
			readonly country: string | undefined;
			readonly attempted: true;
	  };

type State = {
	readonly deleteSavedProjectStatus?: {
		readonly type: "pending";
		readonly id: string;
	};
	readonly justSignedUp: boolean;
	readonly user: UserStatus;
	readonly measurementsPreference: MeasurementUnits;
	readonly selectedCountryCode: string | undefined;
	readonly ipAddress: IpAddressValue | undefined;
};

function isAdminUser({
	emailAddress,
}: Pick<AppAuthUser, "emailAddress">): boolean {
	return (
		emailAddress.endsWith("brick.me") ||
		emailAddress.endsWith("creatio.com.au") ||
		emailAddress.endsWith("brick-me.com")
	);
}

const loadHasSavedDesignStatus = createAsyncThunk<
	boolean,
	undefined,
	StoreThunkApiConfig<{ user: State }>
>(
	`${name}/loadHasSavedDesignStatus`,
	async (_, { getState, extra: { apiClient } }) => {
		const {
			user: { user },
		} = getState();
		if (user.type !== "signedIn") {
			return false;
		}

		const { viewer } = await apiClient.request<GetHasSavedDesignStatusQuery>(
			getHasSavedDesignStatusQuery,
		);
		return (
			viewer.numberOfSavedProjects > 0 ||
			viewer.projectsImport?.status === "running"
		);
	},
);

const loadAuthenticatedUser = createAsyncThunk<
	undefined | AppAuthUser,
	undefined,
	StoreThunkApiConfig
>(`${name}/loadAuthenticatedUser`, async (_, { extra: { authService } }) => {
	const result = await authService.loadCurrentUser();
	const authUser = result?.authUser;
	if (!authUser) {
		return undefined;
	}

	return {
		...authUser,
		hasSavedDesign: undefined,
		isAdminAccount: isAdminUser(authUser),
		savedDesigns: pendingUpdatableLoad,
	};
});

const refreshAuthenticatedUser = createAsyncThunk<
	AccessToken,
	undefined,
	StoreThunkApiConfig<{ user: State }>
>(
	`${name}/refreshAuthenticatedUser`,
	async (_, { extra: { authService }, getState }) => {
		const { user } = getState();
		if (user.user.type !== "signedIn") {
			throw new Error("Can only refresh token if signed in");
		}

		return authService.refreshAccessToken(user.user.user.jwtRefreshToken.token);
	},
);

const checkEmailAddress = createAsyncThunk<
	boolean,
	string,
	StoreThunkApiConfig
>(
	`${name}/checkEmailAddress`,
	async (emailAddress: string, { extra: { authService } }) =>
		authService.isEmailAddressUsed(emailAddress),
);

const signUp = createAsyncThunk<
	AuthUser,
	{
		readonly emailAddress: string;
		readonly firstName: string;
		readonly middleName: string | undefined;
		readonly lastName: string | undefined;
		readonly showJustSignedUpMessage: boolean;
	},
	StoreThunkApiConfig<{ user: State; reference: ReferenceState }>
>(
	`${name}/signUp`,
	async (
		{ emailAddress, firstName, middleName, lastName },
		{ extra: { authService }, getState, dispatch },
	) => {
		const {
			user: { ipAddress: initialIpAddress },
		} = getState();
		if (!initialIpAddress?.attempted) {
			await dispatch(initialiseIpInfo({ forceFetch: true }));
		}

		const {
			user: { ipAddress: foundIpAddress },
		} = getState();
		const authUser = await authService.signUpWithoutPassword(emailAddress, {
			firstName,
			middleName,
			lastName,
			// Weird little juggle needed for ts compiler
			ipAddress: foundIpAddress?.value
				? {
						country: foundIpAddress.country,
						value: foundIpAddress.value,
					}
				: undefined,
		});
		return authUser;
	},
);

const savedDesignsPageSize = 21;

const loadSavedDesigns = createAsyncThunk<
	AppAuthUser["savedDesigns"],
	undefined,
	StoreThunkApiConfig<{ user: State }>
>(`${name}/loadSavedDesigns`, async (_, { extra: { apiClient }, getState }) => {
	const { user } = getState();
	if (user.user.type !== "signedIn") {
		throw new Error("Expecting signed in user");
	}

	const {
		user: {
			user: { savedDesigns },
		},
	} = user;
	return performUpdatableLoad(
		savedDesigns,
		() =>
			apiClient.request<GetSavedDesignsQuery, GetSavedDesignsVariables>(
				getSavedDesignsQuery,
				{
					limit: savedDesignsPageSize,
				},
			),
		({ viewer }) => {
			if (!viewer) {
				throw new Error("Error retrieving designs");
			}
			const designs = removeTypenames(viewer.savedDesigns.items);
			return {
				projectsImport: viewer.projectsImport ?? undefined,
				nextPageToken: viewer.savedDesigns.nextToken,
				designs,
				loadingIndex: undefined,
			};
		},
	);
});

const loadMoreSavedDesigns = createAsyncThunk<
	AppAuthUser["savedDesigns"],
	undefined,
	StoreThunkApiConfig<{ user: State }>
>(
	`${name}/loadMoreSavedDesigns`,
	async (_, { extra: { apiClient }, getState }) => {
		const { user } = getState();
		if (user.user.type !== "signedIn") {
			throw new Error("Expecting signed in user");
		}

		const {
			user: {
				user: { savedDesigns },
			},
		} = user;
		// This will be loading with data
		if (!savedDesigns.data) {
			throw new Error("Expected saved designs");
		}
		const { designs: previousDesigns, nextPageToken } = savedDesigns.data;

		return performUpdatableLoad(
			savedDesigns,
			() =>
				apiClient.request<GetSavedDesignsQuery, GetSavedDesignsVariables>(
					getSavedDesignsQuery,
					{
						limit: savedDesignsPageSize,
						pageToken: nextPageToken,
					},
				),
			({ viewer }) => {
				if (!viewer) {
					throw new Error("Error retrieving designs");
				}
				const { projectsImport, savedDesigns: newSavedDesigns } = viewer;
				const newDesigns = removeTypenames(newSavedDesigns.items);
				return {
					projectsImport: projectsImport ?? undefined,
					nextPageToken: newSavedDesigns.nextToken,
					designs: [...previousDesigns, ...newDesigns],
					loadingIndex: undefined,
				};
			},
		);
	},
);

const deleteSavedProject = createAsyncThunk<void, string, StoreThunkApiConfig>(
	`${name}/deleteSavedProject`,
	async (projectId, { extra: { apiClient } }) => {
		await apiClient.request<
			DeleteProjectMutation,
			DeleteProjectMutationVariables
		>(deleteProjectMutation, {
			id: projectId,
		});
	},
);

const loadSavedProject = createAsyncThunk<
	{
		readonly id: string;
		readonly sourceImageKey: string;
		readonly updatedAt: UnixTimestamp;
	},
	FullSavedProject,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(`${name}/loadSavedProject`, async (project, { getState, dispatch }) => {
	const {
		reference: { systemPalette, basePlateSizes },
	} = getState();
	const systemPaletteData = ensureLoadedOnceOffLoad(
		systemPalette,
		"System palette not loaded",
	);

	const { result, warnings } = transportProjectToCore(
		project,
		systemPaletteData,
	);
	if (warnings.size > 0) {
		console.warn("Got parse warnings", Array.from(warnings));
	}

	const startProjectOptions = produce(
		{
			project: {
				...result,
				...pick(project, "faceBoundingBoxes"),
			},
			...pick(
				project,
				"backgroundMaskImageUrl",
				"colorisationImageUrl",
				"enhanceFacesImageUrl",
			),
		},
		(draft) => {
			let hasChange = false;
			const [defaultBasePlateSize] = basePlateSizes;
			const maybeUpdatePicture = (picture: Draft<PictureConfiguration>) => {
				if (!basePlateSizes.includes(picture.basePlateSize)) {
					hasChange = true;
					picture.basePlateSize = defaultBasePlateSize;
					picture.numberOfBricks = multiplySize(
						picture.numberOfBasePlates,
						defaultBasePlateSize,
					);
					picture.pen = [];
				}
			};

			maybeUpdatePicture(draft.project.currentPicture);
			draft.project.otherVersions.forEach((v) => {
				maybeUpdatePicture(v);
			});

			if (hasChange) {
				draft.project.updatedAt = Date.now();
			}
		},
	);

	await dispatch(
		startProject({
			...startProjectOptions,
			sourceImageBitmapOrUrl: project.sourceImageUrl,
			backgroundMaskImageUrl:
				startProjectOptions.backgroundMaskImageUrl ?? undefined,
			colorisationImageUrl:
				startProjectOptions.colorisationImageUrl ?? undefined,
			enhanceFacesImageUrl:
				startProjectOptions.enhanceFacesImageUrl ?? undefined,
			project: {
				...startProjectOptions.project,
				faceBoundingBoxes:
					startProjectOptions.project.faceBoundingBoxes ?? undefined,
				otherVersions: startProjectOptions.project.otherVersions.map(
					(picture) => ({
						id: uniqueId(),
						picture,
					}),
				),
			},
		}),
	);
	return {
		id: project.id,
		sourceImageKey: project.sourceImageKey,
		updatedAt: startProjectOptions.project.updatedAt,
	};
});

const loadSavedProjectById = createAsyncThunk<
	void,
	string,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	`${name}/loadSavedProjectById`,
	async (projectId, { dispatch, extra: { apiClient } }) => {
		const result = await apiClient.request<
			GetSavedDesignByIdQuery,
			GetSavedDesignByIdVariables
		>(getSavedDesignByIdQuery, {
			id: projectId,
		});
		if (!result) {
			throw new Error(`Couldn't load saved design ${projectId}`);
		}
		await dispatch(
			loadSavedProject(removeTypenames(result.viewer.savedDesignById)),
		);
	},
);

const signOut = createAsyncThunk<void, undefined, StoreThunkApiConfig>(
	`${name}/signOut`,
	// async thunk needed for services access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (_, { extra: { authService } }) => {
		authService.signOutCurrentUser();
	},
);

type SignInDetails = {
	readonly emailAddress: string;
	readonly password: string;
};

const signIn = createAsyncThunk<
	AuthUser | undefined,
	SignInDetails,
	StoreThunkApiConfig
>(
	`${name}/signIn`,
	async ({ emailAddress, password }, { extra: { authService } }) => {
		try {
			const { authUser } = await authService.signIn(emailAddress, password);
			return authUser;
		} catch {
			return undefined;
		}
	},
);

const ensurePicPricesLoadedForCountryCode = createAsyncThunk<
	void,
	undefined,
	StoreThunkApiConfig<{ user: State; reference: ReferenceState }>
>(
	`${name}/ensurePicPricesLoadedForCountryCode`,
	// async thunk needed for cross slice state access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (_, { getState, dispatch }) => {
		const {
			user: { selectedCountryCode },
		} = getState();
		if (!selectedCountryCode) {
			throw new Error("No selected country");
		}
		dispatch(ensurePicPricesLoaded(selectedCountryCode));
	},
);

const setCountryCode = createAsyncThunk<
	void,
	string,
	StoreThunkApiConfig<{ user: State; reference: ReferenceState }>
>(
	`${name}/setCountryCode`,
	// async thunk needed for before/after dispatch
	// eslint-disable-next-line @typescript-eslint/require-await
	async (_, { dispatch }) => {
		dispatch(ensurePicPricesLoadedForCountryCode());
	},
);

type InitialiseInfoOptions = {
	readonly forceFetch: boolean;
};

const initialiseIpInfo = createAsyncThunk<
	{ readonly ipAddress: IpAddressValue; readonly country: string },
	undefined | InitialiseInfoOptions,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	`${name}/initialiseIpInfo`,
	async (
		options,
		{ getState, extra: { persistCountryService, ipGeolocationService } },
	) => {
		const {
			reference: { defaultCountryCode },
		} = getState();
		const countries = await waitForCountries(getState);

		if (!options?.forceFetch) {
			const persistedCode = persistCountryService.get();
			if (persistedCode) {
				const persistedFoundCountry = countries.find(
					(c) => c.code === persistedCode,
				);
				if (persistedFoundCountry) {
					return {
						ipAddress: {
							value: undefined,
							country: undefined,
							attempted: false,
						},
						country: persistedFoundCountry.code,
					};
				}
			}
		}

		const foundIpInfo = await ipGeolocationService.getIpInfo();
		const foundCountry = countries.find((c) => c.code === foundIpInfo?.country);
		if (foundIpInfo && foundCountry) {
			return {
				country: foundCountry.code,
				ipAddress: {
					value: foundIpInfo.ipAddress,
					country: foundIpInfo.country,
					attempted: true,
				},
			};
		}

		return {
			country: defaultCountryCode,
			ipAddress: {
				value: foundIpInfo?.ipAddress,
				country: foundIpInfo?.country,
				attempted: true,
			},
		};
	},
);

const initialState: State = {
	justSignedUp: false,
	user: { type: "loadingPrevious" },
	measurementsPreference: "metric",
	selectedCountryCode: undefined,
	ipAddress: undefined,
};

const slice = createSlice({
	name,
	initialState,
	reducers: {
		requestDeleteSavedProject(
			state: Draft<State>,
			action: PayloadAction<string>,
		) {
			state.deleteSavedProjectStatus = {
				type: "pending",
				id: action.payload,
			};
		},
		cancelDeleteSavedProject(state: Draft<State>) {
			state.deleteSavedProjectStatus = undefined;
		},
		changeEmailAddress(state: Draft<State>) {
			state.user = { type: "anonymous" };
		},
		guestLogin(state: Draft<State>) {
			state.user = { type: "guest" };
		},
		guestLogout(state: Draft<State>) {
			state.user = { type: "anonymous" };
		},
		seenSignUpWelcome(state: Draft<State>) {
			state.justSignedUp = false;
		},
		setMeasurementsPreference(
			state: Draft<State>,
			action: PayloadAction<MeasurementUnits>,
		) {
			state.measurementsPreference = action.payload;
		},
	},
	extraReducers: {
		[initialiseIpInfo.fulfilled.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof initialiseIpInfo)["fulfilled"]>,
		) => {
			state.selectedCountryCode = action.payload.country;
			state.ipAddress = action.payload.ipAddress;
		},
		[deleteSavedProject.pending.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof deleteSavedProject)["pending"]>,
		) => {
			const { user } = state;
			if (user.type !== "signedIn") {
				throw new Error("Expecting authenticated user");
			}
			if (!isUpdatableLoaded(user.user.savedDesigns)) {
				throw new Error("No saved designs");
			}

			const index = user.user.savedDesigns.data.designs.findIndex(
				(d) => d.id === action.meta.arg,
			);
			if (index >= 0) {
				user.user.savedDesigns.data.designs.splice(index, 1);
			}
			user.user.hasSavedDesign = user.user.savedDesigns.data.designs.length > 0;
			state.deleteSavedProjectStatus = undefined;
		},
		[setCountryCode.pending.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof setCountryCode)["pending"]>,
		) => {
			state.selectedCountryCode = action.meta.arg;
		},
		[signIn.pending.type]: (state: Draft<State>) => {
			state.user = { type: "checkingSignIn" };
		},
		[signIn.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof signIn)["fulfilled"]>>,
		) => {
			if (action.payload) {
				state.user = {
					type: "signedIn",
					user: {
						...action.payload,
						savedDesigns: pendingUpdatableLoad,
						isAdminAccount: isAdminUser(action.payload),
					},
				};
				return;
			}
			state.user = { type: "invalidSignIn" };
		},
		[signOut.fulfilled.type]: (state: Draft<State>) => {
			state.user = { type: "anonymous" };
		},
		[checkEmailAddress.pending.type]: (state: Draft<State>) => {
			state.user = { type: "checkingEmailAddress" };
		},
		[checkEmailAddress.rejected.type]: (state: Draft<State>) => {
			state.user = { type: "anonymous" };
		},
		[checkEmailAddress.fulfilled.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof checkEmailAddress)["fulfilled"]>,
		) => {
			state.user = {
				type: "checkedEmailAddress",
				isUsed: action.payload,
			};
		},
		[signUp.pending.type]: (state: Draft<State>) => {
			state.user = {
				type: "signingUp",
			};
		},
		[signUp.rejected.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof signUp)["rejected"]>>,
		) => {
			state.user = {
				type: "checkedEmailAddress",
				isUsed: false,
				error: action.error.message ?? "There was an error signing up",
			};
		},
		[signUp.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof signUp)["fulfilled"]>>,
		) => {
			state.justSignedUp = action.meta.arg.showJustSignedUpMessage;
			state.user = {
				type: "signedIn",
				user: {
					...action.payload,
					savedDesigns: pendingUpdatableLoad,
					isAdminAccount: isAdminUser(action.payload),
				},
			};
		},
		[loadAuthenticatedUser.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadAuthenticatedUser)["fulfilled"]>>,
		) => {
			if (action.payload) {
				state.user = { type: "signedIn", user: action.payload };
				return;
			}
			state.user = { type: "anonymous" };
		},
		[refreshAuthenticatedUser.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof refreshAuthenticatedUser)["fulfilled"]>>,
		) => {
			// Not a big deal, just ignore
			if (state.user.type !== "signedIn") {
				return;
			}
			state.user.user.jwtAccessToken = action.payload;
		},
		[loadAuthenticatedUser.rejected.type]: (state: Draft<State>) => {
			state.user = { type: "anonymous" };
		},
		[loadSavedDesigns.pending.type]: (state: Draft<State>) => {
			if (state.user.type !== "signedIn") {
				throw new Error("Not signed in");
			}
			state.user.user.savedDesigns = loadingUpdatableLoad(
				state.user.user.savedDesigns,
			);
		},
		[loadSavedDesigns.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadSavedDesigns)["fulfilled"]>>,
		) => {
			if (state.user.type !== "signedIn") {
				throw new Error("Not signed in");
			}
			state.user.user.hasSavedDesign =
				action.payload.data && action.payload.data.designs.length > 0;
			state.user.user.savedDesigns = action.payload;
		},
		[loadMoreSavedDesigns.pending.type]: (state: Draft<State>) => {
			if (state.user.type !== "signedIn") {
				throw new Error("Not signed in");
			}
			state.user.user.savedDesigns = loadingUpdatableLoad(
				state.user.user.savedDesigns,
			);
		},
		[loadMoreSavedDesigns.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadMoreSavedDesigns)["fulfilled"]>>,
		) => {
			if (state.user.type !== "signedIn") {
				throw new Error("Not signed in");
			}

			state.user.user.savedDesigns = action.payload;
		},
		[loadHasSavedDesignStatus.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadHasSavedDesignStatus)["fulfilled"]>>,
		) => {
			if (state.user.type === "guest") {
				return;
			}
			if (state.user.type !== "signedIn") {
				return;
			}
			state.user.user.hasSavedDesign = action.payload;
		},
		[loadSavedDesigns.rejected.type]: (state: Draft<State>) => {
			if (state.user.type !== "signedIn") {
				throw new Error("Not signed in");
			}
			state.user.user.savedDesigns = errorUpdatableLoad(
				"Error loading designs",
				state.user.user.savedDesigns.data,
			);
		},
		[loadSavedProject.pending.type]: (
			{ user }: Draft<State>,
			action: Draft<ReturnType<(typeof loadSavedProject)["pending"]>>,
		) => {
			if (
				user.type === "signedIn" &&
				isUpdatableLoaded(user.user.savedDesigns)
			) {
				const savedIndex = user.user.savedDesigns.data.designs.findIndex(
					(d) => d.id === action.meta.arg.id,
				);
				if (savedIndex >= 0) {
					user.user.savedDesigns.data.loadingIndex = savedIndex;
				} else {
					user.user.savedDesigns.data.loadingIndex = undefined;
				}
			}
		},
		[loadSavedProject.fulfilled.type]: ({ user }: Draft<State>) => {
			if (
				user.type === "signedIn" &&
				user.user.savedDesigns &&
				isUpdatableLoaded(user.user.savedDesigns)
			) {
				user.user.savedDesigns.data.loadingIndex = undefined;
			}
		},
		[loadSavedProject.rejected.type]: ({ user }: Draft<State>) => {
			if (
				user.type === "signedIn" &&
				user.user.savedDesigns &&
				isUpdatableLoaded(user.user.savedDesigns)
			) {
				user.user.savedDesigns.data.loadingIndex = undefined;
			}
		},
	},
});

export const {
	requestDeleteSavedProject,
	cancelDeleteSavedProject,
	changeEmailAddress,
	guestLogin,
	guestLogout,
	setMeasurementsPreference,
	seenSignUpWelcome,
} = slice.actions;
export {
	loadSavedProject,
	loadHasSavedDesignStatus,
	loadSavedProjectById,
	initialState,
	isSignedOutUserStatus,
	loadAuthenticatedUser,
	signIn,
	signOut,
	signUp,
	checkEmailAddress,
	loadMoreSavedDesigns,
	loadSavedDesigns,
	refreshAuthenticatedUser,
	setCountryCode,
	ensurePicPricesLoadedForCountryCode,
	deleteSavedProject,
	initialiseIpInfo,
};
export type { State, UserStatus };
export default slice.reducer;
