import { loadImage, createCanvas } from "canvas";
import type { Bitmap, WritableBitmap } from "./types.ts";
import { bitmapChannels } from "./types.ts";
import { imageDataToBitmap } from "./image-data.ts";
import { uint8ArrayAlloc } from "../utils/uint8-array.ts";

function createBlankWritableBitmap(
	width: number,
	height: number,
	rgbOrRgba: number,
): WritableBitmap {
	return {
		data: uint8ArrayAlloc(width * height * bitmapChannels, rgbOrRgba),
		width,
		height,
	};
}

function createBlankBitmap(
	width: number,
	height: number,
	rgbOrRgba: number,
): Bitmap {
	return createBlankWritableBitmap(width, height, rgbOrRgba);
}

async function loadBitmapFromSource(source: string | Buffer) {
	const img = await loadImage(source, { crossOrigin: "anonymous" });
	const canvas = createCanvas(img.naturalWidth, img.naturalHeight);
	const ctx = canvas.getContext("2d");
	ctx.drawImage(img, 0, 0);
	const imageData = ctx.getImageData(0, 0, img.naturalWidth, img.naturalHeight);
	return imageDataToBitmap(imageData);
}

async function loadBitmapFromUrl(url: string) {
	return loadBitmapFromSource(url);
}

async function createBitmapFromEncodedImage(encoded: Buffer) {
	return loadBitmapFromSource(encoded);
}

export {
	loadBitmapFromUrl,
	createBlankWritableBitmap,
	createBitmapFromEncodedImage,
	createBlankBitmap,
};
