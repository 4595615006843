import { createImageData } from "canvas";
import type { Bitmap } from "./types.ts";
import { bitmapChannels } from "./types.ts";
import { setBitmapPixelColour } from "./pixel.ts";
import { uint8ArrayAllocUnsafe } from "../utils/uint8-array.ts";

// Note: Image data interface almost identical to bitmap. Can we re-use underlying data
// in some circumstances to be more efficient?
function bitmapToImageData(image: Bitmap) {
	return createImageData(
		// Sometime the data itself is longer
		Uint8ClampedArray.from(
			image.data.subarray(0, image.width * image.height * bitmapChannels),
		),
		image.width,
		image.height,
	) as ImageData;
}

function imageDataToBitmap(
	imageData: Pick<ImageData, "data" | "width" | "height">,
): Bitmap {
	const bitmap = {
		data: uint8ArrayAllocUnsafe(
			imageData.width * imageData.height * bitmapChannels,
		),
		width: imageData.width,
		height: imageData.height,
	};
	for (let x = 0; x < imageData.width; x++) {
		for (let y = 0; y < imageData.height; y++) {
			const baseI = (x + y * imageData.width) * 4;
			const r = imageData.data[baseI];
			const g = imageData.data[baseI + 1];
			const b = imageData.data[baseI + 2];
			const a = 0xff;
			/* eslint-disable-next-line no-bitwise */
			const colour = ((r << 24) | (g << 16) | (b << 8) | a) >>> 0;
			setBitmapPixelColour(bitmap, colour, x, y);
		}
	}
	return bitmap;
}

export { bitmapToImageData, imageDataToBitmap };
