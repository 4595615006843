import type { NumberOfBricks } from "./picture.ts";
import { realWorldPictureSize } from "./picture.ts";
import type { Distance } from "./distance.ts";
import toWholeNumberFallbackToFixed from "../utils/to-whole-number-fallback-to-fixed.ts";

type MeasurementUnits = "metric" | "imperial";

function isMeasurementUnits(value: unknown): value is MeasurementUnits {
	return value === "metric" || value === "imperial";
}

const unitSuffixes: { [key in MeasurementUnits]: string } = {
	metric: "cm",
	imperial: "in",
};

function getUnitAmount(distance: Distance, units: MeasurementUnits): number {
	switch (units) {
		case "imperial":
			return distance.inches;
		case "metric":
			return distance.cm;
		default:
			throw new Error("Invalid units");
	}
}

function formatDimension(distance: Distance, units: MeasurementUnits): string {
	const formattedAmount = toWholeNumberFallbackToFixed(
		getUnitAmount(distance, units),
		1,
	);
	return `${formattedAmount}${unitSuffixes[units]}`;
}

function formattedProjectSize(
	numberOfBricks: NumberOfBricks,
	units: MeasurementUnits,
): string {
	const { width, height } = realWorldPictureSize(numberOfBricks);
	return `${formatDimension(width, units)} x ${formatDimension(height, units)}`;
}

function formattedProjectSizeRounded(
	numberOfBricks: NumberOfBricks,
	units: MeasurementUnits,
): string {
	const { width, height } = realWorldPictureSize(numberOfBricks);
	const roundedWidth = Math.round(getUnitAmount(width, units));
	const roundedHeight = Math.round(getUnitAmount(height, units));
	return `${roundedWidth}x${roundedHeight} ${unitSuffixes[units]}`;
}

export type { MeasurementUnits };
export {
	isMeasurementUnits,
	formattedProjectSize,
	formattedProjectSizeRounded,
};
