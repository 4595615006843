import React from "react";
import { clsx } from "clsx";
import { useField, useFormikContext } from "formik";
import FormItem from "./form-item.tsx";

type TextInputFieldProps = {
	readonly type?: "email" | "text" | "password";
	readonly name: string;
	readonly className?: string;
	readonly placeholder?: string;
	readonly disabled?: boolean;
};

function TextInputField({
	type = "text",
	name,
	disabled,
	className,
	placeholder,
}: TextInputFieldProps) {
	const [field] = useField<string>(name);
	const { isSubmitting } = useFormikContext();

	return (
		<FormItem name={name}>
			<input
				type={type}
				placeholder={placeholder}
				className={clsx("input", className)}
				disabled={!!disabled || isSubmitting}
				{...field}
			/>
		</FormItem>
	);
}

export default TextInputField;
