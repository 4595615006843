import { sortBy, compact } from "lodash-es";
import { firstOrThrow } from "@dhau/lang-extras";
import type { EncodedImage } from "../utils/encoded-image.ts";
import type { Bitmap } from "../bitmap/index.ts";
import { bitmapToImageData } from "../bitmap/index.ts";

async function tryToConvertCanvasToBufferType(
	canvas: Pick<HTMLCanvasElement, "toBlob">,
	mimeType: string,
	quality?: number,
) {
	return new Promise<EncodedImage | undefined>((resolve) => {
		canvas.toBlob(
			(blob: Blob | null) => {
				// eslint-disable-next-line no-null/no-null
				if (blob === null) {
					console.error(`Unable to encode image blob ${mimeType}`);
					resolve(undefined);
					return;
				}
				blob
					.arrayBuffer()
					.then((buffer) =>
						resolve({
							buffer,
							mimeType,
						}),
					)
					.catch((e) => {
						console.error(e);
						resolve(undefined);
					});
			},
			mimeType,
			quality,
		);
	});
}

// TODO: Should this be centralised somewhere?
const workableMimeTypes = ["image/jpeg", "image/png"];

// TODO: Add webp as option. All browsers except safari produce it with
// canvas.toBlob, so have an attempt at it on safari and fall back to png
// or jpeg
async function bitmapToSmallestSizeEncodedImage(
	image: Bitmap,
	originalFile: EncodedImage | undefined,
) {
	const canvas = document.createElement("canvas");
	canvas.width = image.width;
	canvas.height = image.height;
	const ctx = canvas.getContext("2d");
	// eslint-disable-next-line no-null/no-null
	if (ctx === null) {
		throw new Error("Unable to get context");
	}

	const imageData = bitmapToImageData(image);
	ctx.putImageData(imageData, 0, 0);

	const convertResults = await Promise.all([
		// Needs more testing. Specifically rekognition only accepts png or jpg.
		// Also have read node-canvas doesn't support it (needed for building
		// for medium image amongst other uses).
		// canvasToBufferType(canvas, "image/webp", "webp"),
		tryToConvertCanvasToBufferType(canvas, "image/png"),
		tryToConvertCanvasToBufferType(canvas, "image/jpeg", 90),
	]);
	const allResults = compact([
		...convertResults,
		originalFile?.mimeType && workableMimeTypes.includes(originalFile.mimeType)
			? originalFile
			: undefined,
	]);

	return firstOrThrow(sortBy(allResults, (option) => option.buffer.byteLength));
}

export default bitmapToSmallestSizeEncodedImage;
