import React from "react";

type FormErrorProps = {
	readonly children: string;
};

function FormError({ children }: FormErrorProps) {
	return <div className="form-error">{children}</div>;
}

export default FormError;
