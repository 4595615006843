import { z } from "zod";
import { Form, Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import type { ReactNode, MouseEvent, FormEvent } from "react";
import React, { useEffect, useState } from "react";
import useInput from "@rooks/use-input";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import {
	signIn,
	checkEmailAddress,
	signUp,
	changeEmailAddress,
} from "~/features/user/store-slice.ts";
import TextInputField from "~/components/forms/text-input-field.tsx";
import { selectUser } from "~/features/user/selectors.ts";
import { pushGtmData } from "~/features/analytics/index.ts";
import { useWebsiteUrl } from "~/context/website-url.tsx";
import type { NameValues } from "./name-form.tsx";
import NameForm from "./name-form.tsx";
import { openWhatsMyPassword } from "./store-slice.ts";

const validationSchema = z.object({
	emailAddress: z.string().min(1, "Required"),
});

type LoginOrSignUpFormProps = {
	readonly onSuccess?: () => void;
	readonly children?: ReactNode;
	readonly showJustSignedUpMessage: boolean;
	readonly finalSubmitLabel: string;
};

function LoginOrSignUpForm({
	finalSubmitLabel,
	showJustSignedUpMessage,
	onSuccess,
	children,
}: LoginOrSignUpFormProps) {
	const dispatch = useAppDispatch();

	const user = useAppSelector(selectUser);
	const isWorking =
		user.type === "checkingSignIn" ||
		user.type === "checkingEmailAddress" ||
		user.type === "signingUp";

	const showAskForName =
		user.type === "signingUp" ||
		(user.type === "checkedEmailAddress" && !user.isUsed);
	const showAskForPassword =
		user.type === "checkingSignIn" ||
		user.type === "invalidSignIn" ||
		(user.type === "checkedEmailAddress" && user.isUsed);

	const privacyUrl = useWebsiteUrl("/policies/privacy-policy");
	const termsUrl = useWebsiteUrl("/policies/terms-of-service");

	// GTM
	useEffect(() => {
		if (showAskForName) {
			dispatch(
				pushGtmData({
					event: "PageView",
					page_title: "App Name Request",
				}),
			);
		}
	}, [dispatch, showAskForName]);
	useEffect(() => {
		if (showAskForPassword) {
			dispatch(
				pushGtmData({
					event: "PageView",
					page_title: "App Password Request",
				}),
			);
		}
	}, [dispatch, showAskForPassword]);

	// Check email address
	const [pendingEmailAddress, setPendingEmailAddress] = useState<string>();
	const onEmailAddressSubmit = async ({
		emailAddress,
	}: z.output<typeof validationSchema>) => {
		setPendingEmailAddress(emailAddress);
		await dispatch(checkEmailAddress(emailAddress));
	};
	const onChangeEmailAddress = (e: MouseEvent) => {
		e.preventDefault();
		dispatch(changeEmailAddress());
	};

	// Sign in
	const passwordInput = useInput();
	const onPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
		if (pendingEmailAddress === undefined) {
			throw new Error("Invalid state - no pending email");
		}
		e.preventDefault();

		const result = await dispatch(
			signIn({
				emailAddress: pendingEmailAddress,
				password: passwordInput.value,
			}),
		);
		dispatch(
			pushGtmData({
				event: "AppLogin",
			}),
		);
		if (onSuccess && result.payload) {
			onSuccess();
		}
	};

	// Sign up
	const onSignUpSubmit = async (values: NameValues) => {
		if (pendingEmailAddress === undefined) {
			throw new Error("Invalid state - no pending email");
		}

		await dispatch(
			signUp({
				emailAddress: pendingEmailAddress,
				...values,
				showJustSignedUpMessage,
			}),
		);
		if (onSuccess) {
			onSuccess();
		}
		await dispatch(
			pushGtmData({
				event: "AppRegistration",
			}),
		);
	};

	const onWhatsMyPasswordClick = () => {
		dispatch(openWhatsMyPassword());
	};

	return (
		<>
			<Formik
				initialValues={{ emailAddress: "" }}
				validationSchema={toFormikValidationSchema(validationSchema)}
				onSubmit={onEmailAddressSubmit}
			>
				<Form className="default-modal__email-form email-form">
					<div className="email-form__field">
						<TextInputField
							type="email"
							name="emailAddress"
							className="email-form__input input"
							placeholder="Enter your email..."
							disabled={
								isWorking ||
								(user.type !== "anonymous" && user.type !== "guest")
							}
						/>
						{user.type === "anonymous" ||
						user.type === "checkingEmailAddress" ||
						user.type === "guest" ? (
							<button
								type="submit"
								className="email-form__submit-btn button"
								disabled={isWorking}
							>
								{user.type === "checkingEmailAddress" ? "Checking" : "GO"}
							</button>
						) : (
							<button
								type="button"
								className="email-form__submit-btn button"
								disabled={isWorking}
								onClick={onChangeEmailAddress}
							>
								Change
							</button>
						)}
					</div>
				</Form>
			</Formik>
			{showAskForPassword && (
				<>
					<form
						className="default-modal__email-form email-form"
						onSubmit={onPasswordSubmit}
					>
						<div className="email-form__field">
							<input
								type="password"
								name="password"
								className="email-form__input input"
								placeholder="Password"
								required
								disabled={isWorking}
								{...passwordInput}
							/>
							<button
								type="submit"
								className="email-form__submit-btn button"
								disabled={isWorking}
							>
								{isWorking ? "Signing in" : "Sign in"}
							</button>
						</div>
					</form>
					<div className="default-modal__text default-modal__privacy">
						<button
							type="button"
							className="default-modal__privacy-link"
							onClick={onWhatsMyPasswordClick}
						>
							What&apos;s my password?
						</button>
					</div>
				</>
			)}
			{user.type === "invalidSignIn" && (
				<div className="error-message">
					Please check your email address and/or password
				</div>
			)}
			{user.type === "checkedEmailAddress" && user.error && (
				<div className="error-message">{user.error}</div>
			)}
			{showAskForName && (
				<NameForm
					onSubmit={onSignUpSubmit}
					isWorking={isWorking}
					submitLabel={finalSubmitLabel}
				/>
			)}
			{children}
			<div className="default-modal__text default-modal__privacy">
				By continuing, you indicate that you have read and agree to our{" "}
				<a href={termsUrl} target="_blank" rel="noopener noreferrer">
					Terms of Use
				</a>{" "}
				&amp;{" "}
				<a href={privacyUrl} target="_blank" rel="noopener noreferrer">
					Privacy Policy
				</a>
				.
			</div>
		</>
	);
}

export default LoginOrSignUpForm;
