import { uniq, sum } from "lodash-es";

type Money = {
	readonly dollars: number;
	readonly currencyCode: string;
};

function moneyFromDollars(dollars: number, currencyCode: string): Money {
	return { dollars, currencyCode };
}

function sumMoney(moneys: readonly Money[]): Money {
	if (moneys.length === 0) {
		throw new Error("No moneys provided");
	}

	const currencies = uniq(moneys.map((m) => m.currencyCode));
	if (currencies.length !== 1) {
		throw new Error(
			`Trying to sum multiple curencies: ${currencies.join(", ")}`,
		);
	}
	return moneyFromDollars(sum(moneys.map((m) => m.dollars)), currencies[0]);
}

function multiplyMoney(
	{ dollars, currencyCode }: Money,
	multiplier: number,
): Money {
	return moneyFromDollars(dollars * multiplier, currencyCode);
}

function zeroMoney(currencyCode: string) {
	return moneyFromDollars(0, currencyCode);
}

export type { Money };
export { moneyFromDollars, multiplyMoney, zeroMoney, sumMoney };
