/* eslint-disable no-param-reassign */
import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { pick, uniqueId } from "lodash-es";
import {
	transportPictureConfigurationToCore,
	removeTypenames,
} from "@brickme/project-core/src";
import type {
	GetBrickArtDesignQuery,
	GetBrickArtDesignVariables,
} from "~/api/get-brick-art-design-query.ts";
import getBrickArtDesignQuery from "~/api/get-brick-art-design-query.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import {
	selectImageByUrl,
	startNewProject,
	selectImageByPicToBrick,
} from "~/features/workspace/store-slice.ts";
import startProject from "~/features/workspace/start-project.ts";
import type { Command } from "~/features/commands/command.ts";
import type { State as ReferenceState } from "~/features/reference/state.ts";
import type { State as UserState } from "~/features/user/store-slice.ts";
import { loadSavedProjectById } from "~/features/user/store-slice.ts";
import selectDefaultHangingType from "~/features/order/select-default-hanging-type.ts";
import { ensureLoadedOnceOffLoad } from "~/utils/loading.ts";
import type { EditorMenu } from "./editor-menu.ts";
import type { InitialAction } from "./initial-action.ts";

type InitialActionStatus = "pending" | "performing" | "done";

type LoginPromptDetails = {
	readonly title: string;
};

type State = {
	readonly hasDismissedMobileWarning: boolean;
	readonly selectedEditorMenu: EditorMenu;
	readonly showPreview: boolean;
	readonly showCreateBrickArt: boolean;
	readonly isTutorialOpen: boolean;
	readonly isCompleteOpen: boolean;
	readonly isSimpleCreatorOpen: boolean;
	readonly isSavedDesignsOpen: boolean;
	readonly showWhatsMyPassword: boolean;
	readonly changeSizeErasesPenMessageStatus?: Command | "seen";
	readonly premiumColoursMessageStatus?: "show" | "seen";
	readonly firstTimeDrawToolStatus?: "show" | "seen";
	readonly initialAction?: InitialAction;
	readonly initialActionStatus: InitialActionStatus;
	readonly guestPromoOpen: boolean;
	readonly showLoginPrompt: LoginPromptDetails | false;
	readonly isCompleteLoginPromptOpen: boolean;
	readonly basePlateConversionWarningOpen: boolean;
};

const initialState: State = {
	hasDismissedMobileWarning: false,
	selectedEditorMenu: "size",
	showPreview: false,
	showCreateBrickArt: false,
	isTutorialOpen: false,
	isSavedDesignsOpen: false,
	isCompleteOpen: false,
	showWhatsMyPassword: false,
	isSimpleCreatorOpen: false,
	changeSizeErasesPenMessageStatus: undefined,
	premiumColoursMessageStatus: undefined,
	firstTimeDrawToolStatus: undefined,
	initialActionStatus: "pending",
	initialAction: undefined,
	guestPromoOpen: false,
	showLoginPrompt: false,
	isCompleteLoginPromptOpen: false,
	basePlateConversionWarningOpen: false,
};

const name = "nav";

const loadBrickArtDesign = createAsyncThunk<
	void,
	string,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	`${name}/loadBrickArtDesign`,
	async (designId, { dispatch, getState, extra: { apiClient } }) => {
		const data = await apiClient.request<
			GetBrickArtDesignQuery,
			GetBrickArtDesignVariables
		>(getBrickArtDesignQuery, { id: designId });

		const {
			reference: { systemPalette },
		} = getState();
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);
		const design = data.brickArtDesign;
		// TODO: Something with warnings
		const [currentPicture, ...otherPictures] = design.variantPictures
			.map(removeTypenames)
			.map((picture) => {
				const { result, warnings } = transportPictureConfigurationToCore(
					picture,
					systemPaletteData,
				);
				if (warnings.size > 0) {
					console.warn("Got parse warnings", Array.from(warnings));
				}
				return result;
			});

		await dispatch(
			startProject({
				project: {
					faceBoundingBoxes: design.faceBoundingBoxes ?? undefined,
					currentPicture,
					otherVersions: otherPictures.map((picture) => ({
						id: uniqueId(),
						picture,
					})),
					updatedAt: Date.now(),
				},
				// TODO: Would probably want to optimise buffer to smallest size (when
				// pass in a url like this no optimisation done)
				sourceImageBitmapOrUrl: design.sourceImageUrl,
				...pick(
					design,
					"backgroundMaskImageUrl",
					"enhanceFacesImageUrl",
					"colorisationImageUrl",
					"faceBoundingBoxes",
				),
			}),
		);
	},
);

const performInitialAction = createAsyncThunk<
	void,
	undefined,
	StoreThunkApiConfig<{
		nav: State;
		reference: ReferenceState;
		user: UserState;
	}>
>(`${name}/performInitialAction`, async (_, { getState, dispatch }) => {
	const {
		nav: { initialAction },
	} = getState();
	if (!initialAction) {
		return;
	}

	switch (initialAction.type) {
		case "loadSourceImage":
			await dispatch(selectImageByUrl(initialAction.imageUrl));
			await dispatch(selectDefaultHangingType());
			break;
		case "startPicToBrick":
			await dispatch(selectImageByPicToBrick(initialAction.picId));
			await dispatch(selectDefaultHangingType());
			break;
		case "loadProject":
			await dispatch(loadSavedProjectById(initialAction.projectId));
			break;
		case "loadBrickArtDesign":
			await dispatch(loadBrickArtDesign(initialAction.designId));
			break;
		default:
			throw new Error("Unrecognised initial action");
	}
});

const slice = createSlice({
	name,
	initialState,
	reducers: {
		selectEditorMenu: (state, action: PayloadAction<EditorMenu>) => {
			state.selectedEditorMenu = action.payload;
		},
		acknowledgeMobileEditorMessage: (state) => {
			state.hasDismissedMobileWarning = true;
		},
		showPremiumColoursMessage: (state) => {
			state.premiumColoursMessageStatus = "show";
		},
		acknowledgePremiumColoursMessage: (state) => {
			state.premiumColoursMessageStatus = "seen";
		},
		showFirstTimeDrawToolStatus: (state) => {
			state.firstTimeDrawToolStatus = "show";
		},
		acknowledgeFirstTimeDrawToolStatus: (state) => {
			state.firstTimeDrawToolStatus = "seen";
		},
		showChangeSizeErasesPenMessage: (state, action: PayloadAction<Command>) => {
			state.changeSizeErasesPenMessageStatus = castDraft(action.payload);
		},
		acknowledgeChangeSizeErasesPenMessage: (state) => {
			state.changeSizeErasesPenMessageStatus = "seen";
		},
		showPreview: (state) => {
			state.showPreview = true;
		},
		hidePreview: (state) => {
			state.showPreview = false;
		},
		showCreateBrickArt: (state) => {
			state.showCreateBrickArt = true;
		},
		hideCreateBrickArt: (state) => {
			state.showCreateBrickArt = false;
		},
		openTutorial: (state) => {
			state.isTutorialOpen = true;
		},
		closeTutorial: (state) => {
			state.isTutorialOpen = false;
		},
		openSavedDesigns: (state) => {
			state.isSavedDesignsOpen = true;
		},
		closeSavedDesigns: (state) => {
			state.isSavedDesignsOpen = false;
		},
		openComplete: (state) => {
			state.isCompleteOpen = true;
		},
		showCompleteLoginPrompt: (state) => {
			state.isCompleteLoginPromptOpen = true;
		},
		hideCompleteLoginPrompt: (state) => {
			state.isCompleteLoginPromptOpen = false;
		},
		closeComplete: (state) => {
			state.isCompleteOpen = false;
		},
		closeBasePlatConversionWarning: (state) => {
			state.basePlateConversionWarningOpen = false;
		},
		openWhatsMyPassword: (state) => {
			state.showWhatsMyPassword = true;
		},
		closeWhatsMyPassword: (state) => {
			state.showWhatsMyPassword = false;
		},
		hideGuestPromo: (state) => {
			state.guestPromoOpen = false;
		},
		showLoginPrompt: (state, action: PayloadAction<LoginPromptDetails>) => {
			state.showLoginPrompt = action.payload;
		},
		hideLoginPrompt: (state) => {
			state.showLoginPrompt = false;
		},
		switchToAdvancedCreator: (state) => {
			state.isSimpleCreatorOpen = false;
		},
		switchToSimpleCreator: (state) => {
			state.isSimpleCreatorOpen = true;
		},
	},
	extraReducers: {
		[performInitialAction.pending.type]: (state: Draft<State>) => {
			state.initialActionStatus = "performing";
		},
		[performInitialAction.rejected.type]: (state: Draft<State>) => {
			state.initialActionStatus = "done";
		},
		[performInitialAction.fulfilled.type]: (state: Draft<State>) => {
			state.initialActionStatus = "done";
		},
		[startNewProject.type]: (state: Draft<State>) => {
			state.showPreview = false;
			state.selectedEditorMenu = initialState.selectedEditorMenu;
		},
		// [loadSavedProject.fulfilled.type]: (
		//   state: Draft<State>,
		//   action: Draft<ReturnType<typeof loadSavedProject["fulfilled"]>>
		// ) => {
		// Disabled atm, but keep here in case need again in future
		// state.basePlateConversionWarningOpen =
		//   action.payload.transformedToSmallBaseplates;
		// },
	},
});

export const {
	acknowledgeMobileEditorMessage,
	selectEditorMenu,
	showPreview,
	hidePreview,
	showPremiumColoursMessage,
	acknowledgePremiumColoursMessage,
	showChangeSizeErasesPenMessage,
	acknowledgeChangeSizeErasesPenMessage,
	showFirstTimeDrawToolStatus,
	acknowledgeFirstTimeDrawToolStatus,
	openTutorial,
	closeTutorial,
	closeBasePlatConversionWarning,
	closeComplete,
	openComplete,
	openSavedDesigns,
	closeSavedDesigns,
	openWhatsMyPassword,
	closeWhatsMyPassword,
	hideGuestPromo,
	showLoginPrompt,
	hideLoginPrompt,
	showCompleteLoginPrompt,
	hideCompleteLoginPrompt,
	switchToAdvancedCreator,
	switchToSimpleCreator,
	showCreateBrickArt,
	hideCreateBrickArt,
} = slice.actions;
export { initialState, performInitialAction };
export type { InitialActionStatus };
export default slice.reducer;
