import {
	cloneBitmapToWritable,
	iterateBitmapIndexGenerator,
} from "../bitmap/index.ts";
import type { BrickColour } from "../model/index.ts";
import { premadeKit } from "../premade-kit.ts";
import { writeUInt32BE } from "../utils/uint8-array.ts";
import type { BuildOperationInput, BuildOperationResult } from "./operation.ts";

function* removeBackgroundOperation({
	sources: { backgroundMask },
	config: { removeBackground },
	intermediate: {
		image: { noBrickColour: previousNoBrickColour, ...image },
	},
}: BuildOperationInput): Generator<undefined, BuildOperationResult> {
	if (!removeBackground) {
		return { type: "no-change" };
	}

	if (!backgroundMask) {
		return {
			type: "no-change",
			operationsMissingSources: ["Remove background"],
		};
	}

	// Find colour to use where bg removed
	let bgColour: Pick<BrickColour, "rgba" | "r" | "g" | "b">;
	if ("newColour" in removeBackground) {
		bgColour = removeBackground.newColour;
	} else {
		// If we're using a bright pink colour for instance as cut out, then don't
		// want to merge it. Instead use baseplate colour for merge.
		// We want to add this early so it's present for the blending that
		// happens during resizing. We'll add no brick colour during palette
		// operation.
		bgColour = {
			rgba: premadeKit.basePlateColour.rgba,
			r: (premadeKit.basePlateColour.rgba >> 24) & 0xff,
			g: (premadeKit.basePlateColour.rgba >> 16) & 0xff,
			b: (premadeKit.basePlateColour.rgba >> 8) & 0xff,
		};
	}
	const { r: bgRed, g: bgGreen, b: bgBlue, rgba: bgRgba } = bgColour;

	const result = yield* cloneBitmapToWritable(image);
	// TODO: Potentially a big performance win here if can write a whole
	// background row or col at a time
	yield* iterateBitmapIndexGenerator(
		result,
		(i, x, y) => {
			const pictureRatio = backgroundMask.foregroundRatio[x][y];
			if (pictureRatio === 1) {
				return;
			}

			if (pictureRatio === 0) {
				writeUInt32BE(result.data, bgRgba, i);
				return;
			}

			const bgRatio = 1 - pictureRatio;

			// See https://stackoverflow.com/questions/34318650/color-blending-per-pixel
			// sqrt(((AlphaA*ColA)^2 + (AlphaB*ColB)^2)/(AlphaA + AlphaB))
			// This is a bit heavier and more accurate, but result looks fine for simple
			// result.data[i + 0] = Math.sqrt((bgRed * bgRatio)**2 + (result.data[i + 0] * pictureRatio)**2);
			// result.data[i + 1] = Math.sqrt((bgGreen * bgRatio)**2 + (result.data[i + 1] * pictureRatio)**2);
			// result.data[i + 2] = Math.sqrt((bgBlue * bgRatio)**2 + (result.data[i + 2] * pictureRatio)**2);
			result.data[i + 0] = bgRed * bgRatio + result.data[i + 0] * pictureRatio;
			result.data[i + 1] =
				bgGreen * bgRatio + result.data[i + 1] * pictureRatio;
			result.data[i + 2] = bgBlue * bgRatio + result.data[i + 2] * pictureRatio;
		},
		5_000,
	);
	return {
		type: "cloned",
		clonedImage: { ...result, noBrickColour: previousNoBrickColour },
	};
}

export default removeBackgroundOperation;
