/* eslint-disable no-param-reassign */
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { BrickColour } from "@brickme/project-core/src";
import type { StoreThunkApiConfig } from "~/store-config.ts";
import type { State as ReferenceState } from "~/features/reference/state.ts";
import { ensureLoadedOnceOffLoad } from "~/utils/loading.ts";
import type { State } from "../state.ts";

const deselectCustomModePaletteBrick = createAsyncThunk<
	readonly BrickColour[],
	BrickColour,
	StoreThunkApiConfig<{ workspace: State; reference: ReferenceState }>
>(
	"workspace/deselectCustomModePaletteBrick",
	// async thunk needed for cross slice state access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (deselectBrick, { getState }) => {
		const {
			workspace: { openProject },
			reference: { systemPalette },
		} = getState();
		if (!openProject) {
			throw new Error("No project");
		}
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);
		const { project, build } = openProject;

		let bricks: BrickColour[];
		if (project.currentPicture.paletteMode.type === "custom") {
			bricks = project.currentPicture.paletteMode.palette.slice();
		} else {
			// TODO: Race condition here. If already building then won't have latest brickCount
			bricks = systemPaletteData
				.map(({ brick }) => brick)
				.filter((c) => c.hexString in build.brickCounts);
		}

		return bricks.filter((b) => b.hexString !== deselectBrick.hexString);
	},
);

export default deselectCustomModePaletteBrick;
