import type { ReactNode } from "react";
import React from "react";

type StatusMainPanelProps = {
	readonly children: ReactNode;
};

function StatusMainPanel({ children }: StatusMainPanelProps) {
	return (
		<main className="app-main">
			<div
				style={{
					flex: 1,
					paddingTop: 50,
					paddingBottom: 50,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{children}
			</div>
		</main>
	);
}

export default StatusMainPanel;
