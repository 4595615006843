import {
	overlayBlend,
	vividLightBlend,
	cloneOpenCvToBitmap,
	bitmapToOpenCv,
	bitmapChannels,
} from "../bitmap/index.ts";
import { readUInt32BE, writeUInt32BE } from "../utils/uint8-array.ts";
import { floatFloor } from "../utils/float.ts";
import compileMaskingProps from "./compile-masking-props.ts";
import type { BuildOperationInput, BuildOperationResult } from "./operation.ts";

// Note: Detail filter should not apply to removed background areas. However it
// doesn't impact the flat bg colours
function* detailFilterOperation({
	openCv,
	config,
	intermediate: { image },
	sources: { backgroundMask },
}: BuildOperationInput): Generator<undefined, BuildOperationResult> {
	const { detailFilter, removeBackground } = config;
	if (!detailFilter) {
		return { type: "no-change" };
	}

	let result = bitmapToOpenCv(openCv, image);
	yield;

	openCv.cvtColor(result, result, openCv.COLOR_BGRA2BGR);

	const amplitude = 3;
	const scale = detailFilter.sigma;
	const iterations = 1; // 2 is causing errors

	// When this goes below 1 it takes a long time
	const blurD = Math.max(
		1,
		detailFilter.radiusRatio * Math.min(image.width, image.height),
	);

	// 3,8.7,2
	// repeat $! l[$>]
	//   repeat $3
	//     repeat $1 bilateral. $2,{1.5*$2} done
	//     blend[-2,-1] vividlight blend overlay
	//   done
	// endl done n 0,255
	// - amplitude: Default 2. Range 1-5. Number of applications of blur
	// - scale: Default 10. Range 10-100. sd for bilateral blur
	// - iterations: Default 1. Range 1-4. Repeat the process this many times

	for (let j = 0; j < iterations; j++) {
		const blurInput = result;
		for (let i = 0; i < amplitude; i++) {
			const inverted = blurInput.clone();
			yield;
			openCv.bitwise_not(inverted, inverted);
			yield;
			openCv.bilateralFilter(inverted, blurInput, blurD, scale, scale * 1.5);
			yield;
			// Takes the harshness off edges. Might be a way to do it in bilateral config
			// cv.blur(blurInput, blurInput, new cv.Size(5, 5));
		}
		const vividIn = cloneOpenCvToBitmap(blurInput);
		yield;
		const vividBlended = vividLightBlend(vividIn, image, 2);
		yield;
		const resultBitmap = overlayBlend(vividBlended, image);
		result = bitmapToOpenCv(openCv, resultBitmap);
		result = result.clone();
	}

	// openCv.cvtColor(result, result, openCv.COLOR_BGR2BGRA);

	const resultImage = cloneOpenCvToBitmap(result);
	// We don't want to apply detail filter to background.
	if (removeBackground && backgroundMask) {
		// Background mask is in scale of original source, so need some re-calc.
		const maskingProps = compileMaskingProps(config, backgroundMask, image);
		if (maskingProps) {
			const numPixelChannels = image.width * image.height * bitmapChannels;
			for (let i = 0; i < numPixelChannels; i += bitmapChannels) {
				const pixelIndex = i / bitmapChannels;
				const x = pixelIndex % image.width;
				const y = (pixelIndex - x) / image.width;
				const maskX = floatFloor(
					(x + maskingProps.croppedXOffset) / maskingProps.usedResizeScale,
				);
				const maskY = floatFloor(
					(y + maskingProps.croppedYOffset) / maskingProps.usedResizeScale,
				);

				// If total background, then use previous result. We don't mind if
				// there's some detail handling of the partial opacity mask pixels.
				if (maskingProps.foregroundRatio[maskX][maskY] === 0) {
					writeUInt32BE(resultImage.data, readUInt32BE(image.data, i), i);
					continue;
				}
			}
		}
	}

	return {
		type: "cloned",
		clonedImage: {
			noBrickColour: image.noBrickColour,
			...resultImage,
		},
	};
}

export default detailFilterOperation;
