import type {
	Middleware,
	CurriedGetDefaultMiddleware,
	AnyAction,
} from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import {
	save as saveToLocalStorageMiddleware,
	load as loadFromLocalStorageMiddleware,
} from "redux-localstorage-simple";
import { defaultsDeep, omit } from "lodash-es";
import { captureMessage as sentryCaptureMessage } from "@sentry/react";
import { initialState as navInitialState } from "~/features/nav/store-slice.ts";
import type { StoreServices } from "~/store-config.ts";
import type { AppState } from "./root-reducer.ts";
import rootReducer from "./root-reducer.ts";

const errorHandlerMiddleware: Middleware =
	() => (next) => (action: AnyAction) => {
		if (
			"type" in action &&
			action.type.endsWith("/rejected") &&
			"error" in action
		) {
			/* eslint-disable-next-line no-console */
			console.warn("Unhandled thunk error", action.error);
			sentryCaptureMessage(`${action.error.name}: ${action.error.message}`);
		}
		return next(action);
	};

type PreloadedState = Partial<AppState>;

const localStorageNamespace = "brick.me-creator-app";

const localStorageStates = ["nav.hasDismissedMobileWarning"];

function createBaseMiddleware<TState>(
	getDefaultMiddleware: CurriedGetDefaultMiddleware<TState>,
	services: StoreServices,
) {
	return getDefaultMiddleware({
		thunk: {
			extraArgument: services,
		},
		immutableCheck: false, // Slows down things too much
		serializableCheck: false, // Slows down things too much
		// serializableCheck: {
		//   ignoredActionPaths: [
		//     "meta.arg",
		//     "payload.output.image",
		//     "payload.build.image",
		//     "payload.source.image",
		//     "payload.project.source.image",
		//     "payload.image",
		//     "payload.birthdate",
		//   ],
		//   ignoredPaths: [
		//     "workspace.openProject.project.source.image",
		//     "workspace.openProject.build.image",
		//     "user.user.user.birthdate",
		//   ],
		// },
	}).prepend(errorHandlerMiddleware);
}

function createStore(services: StoreServices, preloadedState?: PreloadedState) {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			createBaseMiddleware(getDefaultMiddleware, services),
		preloadedState,
	});
}

function createStoreWithLocalStoragePersistence(
	services: StoreServices,
	preloadedState: PreloadedState,
) {
	const { persistCountryService } = services;
	const fullPreloadedState: PreloadedState = defaultsDeep(
		omit(preloadedState, "nav"),
		loadFromLocalStorageMiddleware({
			namespace: localStorageNamespace,
			states: localStorageStates,
			disableWarnings: true,
		}),
		{
			nav: preloadedState.nav ?? navInitialState,
		},
	);
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			createBaseMiddleware(getDefaultMiddleware, services)
				.concat(
					saveToLocalStorageMiddleware({
						namespace: localStorageNamespace,
						states: localStorageStates,
					}),
				)
				.concat(
					// Save user selected country code
					(store) => (next) => (action) => {
						const returnValue = next(action);
						const state = store.getState();
						if (state.user.selectedCountryCode) {
							persistCountryService.set(state.user.selectedCountryCode);
						}

						return returnValue;
					},
				),
		preloadedState: fullPreloadedState,
	});
}

export type { PreloadedState };
export { createStore, createStoreWithLocalStoragePersistence };
