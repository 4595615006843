const runtimeVarPrefix = "VITE_";

function optionalRuntimeEnv(name: string) {
	return import.meta.env[runtimeVarPrefix + name] as string | undefined;
}

function requiredEnv(name: string) {
	const value = import.meta.env[name];
	if (value === undefined) {
		throw new Error(`Environment variable ${name} missing`);
	}
	return value;
}

function optionalRuntimeEnvInt(name: string) {
	const rawValue = optionalRuntimeEnv(name);
	if (rawValue === undefined || rawValue === "") {
		return undefined;
	}
	return parseInt(rawValue, 10);
}

function requiredRuntimeEnv(name: string) {
	return requiredEnv(runtimeVarPrefix + name) as string;
}

export {
	optionalRuntimeEnv,
	optionalRuntimeEnvInt,
	requiredRuntimeEnv,
	requiredEnv,
};
