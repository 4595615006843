import type { Bitmap } from "@brickme/project-core/src";
import { loadBitmapFromUrl } from "@brickme/project-core/src";

async function encodedImageArrayBufferToBitmap(
	source: ArrayBuffer,
	mimeType: string,
): Promise<Bitmap> {
	const blob = new Blob([source], { type: mimeType });
	const url = await new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = ({ target }) => {
			if (!target) {
				reject(new Error("No target"));
				return;
			}
			const { result } = target;
			if (typeof result !== "string") {
				reject(new Error("Result is not a string"));
				return;
			}

			resolve(result);
		};
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
	return loadBitmapFromUrl(url);
}

export default encodedImageArrayBufferToBitmap;
