import { gql } from "graphql-request";
import type { TransportBrickColour } from "@brickme/project-core/src";

export type GetSystemPaletteQuery = {
	readonly brickPalette: readonly TransportBrickColour[];
};

export default gql`
	query getSystemPaletteQuery {
		brickPalette {
			colour
			identifier
		}
	}
`;
