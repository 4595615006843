import type { ReactElement, ReactNode } from "react";
import React from "react";
import { clsx } from "clsx";

type ButtonProps = {
	readonly children: ReactNode;
	readonly className?: string;
	readonly size?: "small" | "medium";
	readonly color?: "yellow" | "darkblue" | "red";
	readonly disabled?: boolean;
	readonly onClick: () => void;
	readonly leftIcon?: ReactElement;
	readonly rightIcon?: ReactElement;
	readonly hideTextOnMobile?: boolean;
};

function Button({
	children,
	onClick,
	className,
	disabled,
	size,
	color,
	leftIcon,
	rightIcon,
	hideTextOnMobile,
}: ButtonProps): ReactElement {
	return (
		<button
			className={clsx([
				"button",
				!!size && `button_size_${size}`,
				!!color && `button_color_${color}`,
				className,
			])}
			type="button"
			onClick={onClick}
			disabled={disabled}
		>
			{leftIcon}
			<span
				className={clsx(["button__text", hideTextOnMobile && "hidden_mobile"])}
			>
				{children}
			</span>
			{rightIcon}
		</button>
	);
}

export default Button;
