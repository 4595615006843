import { combineReducers } from "@reduxjs/toolkit";
import navReducer from "~/features/nav/store-slice.ts";
import workspaceReducer from "~/features/workspace/store-slice.ts";
import userReducer from "~/features/user/store-slice.ts";
import commandsReducer from "~/features/commands/store-slice.ts";
import referenceReducer from "~/features/reference/store-slice.ts";
import previewReducer from "~/features/preview/store-slice.ts";
import editorReducer from "~/features/editor/store-slice.ts";
import simpleEditorReducer from "~/features/simple-editor/store-slice.ts";
import saveProjectReducer from "~/features/save-project/store-slice.ts";
import orderReducer from "~/features/order/store-slice.ts";

const rootReducer = combineReducers({
	nav: navReducer,
	user: userReducer,
	workspace: workspaceReducer,
	commands: commandsReducer,
	reference: referenceReducer,
	preview: previewReducer,
	editor: editorReducer,
	simpleEditor: simpleEditorReducer,
	saveProject: saveProjectReducer,
	order: orderReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
