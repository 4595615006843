// Used to use floor. Encountered one issue when an extremely small negative number.
// Floor took it to -1 which threw everything out.
const epsilon = 0.0000001;

function floatFloor(value: number): number {
	if (value < 0 && value > -epsilon) {
		return 0;
	}
	return Math.floor(value);
}

export { floatFloor };
