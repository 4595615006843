import type { ReactElement } from "react";
import React, { useCallback } from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import { closeWhatsMyPassword } from "./store-slice.ts";

type WhatsMyPasswordModalProps = {
	readonly show: boolean;
};

function WhatsMyPasswordModal({
	show,
}: WhatsMyPasswordModalProps): ReactElement {
	// TODO: Use context
	const resetPasswordUrl =
		import.meta.env.VITE_ACCOUNT_WEBSITE_URL + "/forgot-password/";
	const dispatch = useAppDispatch();
	const onCloseClick = useCallback(() => {
		dispatch(closeWhatsMyPassword());
	}, [dispatch]);
	return (
		<Modal show={show} title="What's My Password?">
			<Modal.Text>
				You were sent an email when you signed up with instructions to set a
				password.
			</Modal.Text>
			<div className="default-modal__text plaintext">
				If you can&apos;t find your email then you can{" "}
				<a href={resetPasswordUrl} rel="noopener noreferrer" target="_blank">
					Reset your Password
				</a>
				.
			</div>
			<Modal.Actions
				actions={[{ text: "Close", onClick: onCloseClick, type: "ghost" }]}
			/>
		</Modal>
	);
}

export default WhatsMyPasswordModal;
