// This is a 32-bit RGBA image.
// Might be good to switch to 24 bit instead since we don't use alpha channel?
// Would cut down on processing and use less memory. However, interop with:
//  - open cv
//  - canvas
//  - parsing png/jpeg, etc.
// might be slower which might negate any gains. Test it out.
// Note that there are 2 methods of using this bitmap. One is if a build image
// the other is for rendering. 32 is probably better for rendering, but 24 might
// be better for building.
type Bitmap = {
	readonly data: Pick<
		Uint8Array,
		"slice" | "subarray" | "copyWithin" | "length"
	> & {
		readonly [key in number]: number;
	};
	readonly width: number;
	readonly height: number;
};

type WritableBitmap = {
	// One day maybe use Uint8Array instead?
	readonly data: Uint8Array;
	readonly width: number;
	readonly height: number;
};

const bitmapChannels = 4;

export { bitmapChannels };
export type { WritableBitmap, Bitmap };
