import { gql } from "graphql-request";

const getCountriesQuery = gql`
	query getCountriesQuery {
		localization {
			availableCountries {
				currency {
					isoCode
					symbol
				}
				isoCode
				name
			}
		}
	}
`;

type GetCountriesQuery = {
	readonly localization: {
		readonly availableCountries: readonly {
			readonly currency: {
				readonly isoCode: string;
				readonly symbol: string;
			};
			readonly isoCode: string;
			readonly name: string;
		}[];
	};
};

export type { GetCountriesQuery };
export { getCountriesQuery };
