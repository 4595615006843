import { createAsyncThunk } from "@reduxjs/toolkit";
import type { DataLayerEvent } from "~/frontend-common/gtm.ts";
import type { StoreThunkApiConfig } from "~/store-config.ts";

const name = "analytics";

const pushGtmData = createAsyncThunk<void, DataLayerEvent, StoreThunkApiConfig>(
	`${name}.pushGtmData`,
	// async thunk needed for service access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (event, { extra: { gtm } }) => {
		gtm.dataLayerPush(event);
	},
);

export { pushGtmData };
