import React, { useState } from "react";
import useAppSelector from "~/hooks/use-app-selector.ts";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import Button from "~/components/ui/button.tsx";
import SpriteIcon from "~/components/ui/sprite-icon.tsx";
import FullHeader from "~/components/header/full-header.tsx";
import { undoMostRecentCommand } from "~/features/commands/store-slice.ts";
import { showPreview } from "~/features/nav/store-slice.ts";
import { selectSelectedEditorMenu } from "~/features/nav/selectors.ts";

function EditorHeader() {
	const selectedEditorMenu = useAppSelector(selectSelectedEditorMenu);
	const dispatch = useAppDispatch();

	// Undo
	const hasCommand = useAppSelector(
		(s) => s.commands.commandSnapshots.length > 0,
	);
	const [isUndoing, setUndoing] = useState(false);
	const onUndoClick = async () => {
		setUndoing(true);
		try {
			await dispatch(undoMostRecentCommand());
		} finally {
			setUndoing(false);
		}
	};

	return (
		<FullHeader
			actions={[
				<Button
					key="preview"
					className="main-header__actions-btn main-header__actions-btn_preview"
					size="small"
					color="yellow"
					onClick={() => dispatch(showPreview())}
					leftIcon={
						<span className="button__icon hidden_mobile">
							<SpriteIcon className="button__icon-img" name="eye" />
						</span>
					}
					rightIcon={
						<span className="button__right">
							<SpriteIcon
								className="button__right-arrow"
								name="chevron-right"
							/>
						</span>
					}
				>
					Preview
				</Button>,
				<Button
					key="undo"
					className="main-header__actions-btn main-header__actions-btn_undo"
					size="small"
					color="darkblue"
					disabled={isUndoing || !hasCommand}
					onClick={onUndoClick}
					leftIcon={
						<span className="button__undo">
							<SpriteIcon className="button__undo-icon" name="undo" />
						</span>
					}
					hideTextOnMobile
				>
					Undo
				</Button>,
			]}
		>
			{selectedEditorMenu === "size" && (
				<h1 className="main-header__info main-header__info_type_size plaintext">
					Adjust the size of your brick pic
				</h1>
			)}
			{selectedEditorMenu === "adjustments" && (
				<h1 className="main-header__info main-header__info_type_adjustments plaintext">
					Adjust the brick colours and style
				</h1>
			)}
			{selectedEditorMenu === "draw" && (
				<h1 className="main-header__info main-header__info_type_pen plaintext">
					Click on a brick to change its colour
				</h1>
			)}
		</FullHeader>
	);
}

export default EditorHeader;
