import { debounce } from "lodash-es";
import type { AppStore } from "~/store/types.d.ts";
import {
	uploadSource,
	clearProjectSave,
	updateSaveOpenProject,
	createProject,
	switchGuestProjectToUser,
} from "./store-slice.ts";

function createAutoSaveListener(
	store: AppStore,
	timeout?: number,
): () => Promise<void> | undefined {
	const listener = async () => {
		const {
			workspace: { openProject },
			saveProject: { openedProjectSaveState },
			user: { user },
		} = store.getState();

		// No open project
		if (!openProject && openedProjectSaveState) {
			// TODO: Cancel current save
			store.dispatch(clearProjectSave());
			return;
		}

		// No project, nothing to save
		if (!openProject) {
			return;
		}

		// Always upload the source image, whether logged in or not
		if (!openedProjectSaveState) {
			try {
				await store.dispatch(uploadSource());
			} catch (e) {
				console.error("Error upload", e);
				throw e;
			}

			// Force a create project immediately (otherwise have to wait for debounce)
			await listener();
			return;
		}

		// Loading a saved design
		if (openedProjectSaveState.type === "loadingApiProject") {
			return;
		}

		// Uploading the image itself
		if (openedProjectSaveState.type === "uploadingSourceImage") {
			return;
		}

		// Error uploading. Note sure how to handle.
		if (openedProjectSaveState.type === "uploadSourceImageError") {
			return;
		}

		// Not logged in. Currently only logged in and guest users can save
		if (user.type !== "signedIn" && user.type !== "guest") {
			return;
		}

		// Uploaded successfully, now we should create the project (logged in users)
		if (openedProjectSaveState.type === "uploadedSourceImage") {
			await store.dispatch(createProject());
			return;
		}

		// Still creating
		if (openedProjectSaveState.type === "creatingApiProject") {
			return;
		}

		// Error creating. Note sure how to handle.
		if (openedProjectSaveState.type === "createApiProjectError") {
			return;
		}

		// Updating atm
		if (openedProjectSaveState.type === "updatingToBackend") {
			return;
		}

		const currentUserType = user.type === "signedIn" ? "signedIn" : "guest";
		const savedUserType = openedProjectSaveState.userType;
		if (currentUserType !== savedUserType && currentUserType === "signedIn") {
			store.dispatch(switchGuestProjectToUser());
			return;
		}

		// Already saved the current version
		if (openProject.project.updatedAt === openedProjectSaveState.updatedAt) {
			return;
		}

		// Tried saving this version, but had error. Don't try this version again (otherwise ends up
		// thrashing backend)
		if (
			openedProjectSaveState.type === "updatingError" &&
			openedProjectSaveState.updatedAt === openProject.project.updatedAt
		) {
			return;
		}

		store.dispatch(updateSaveOpenProject());
	};

	if (timeout === 0) {
		return listener;
	}

	const useTimeout = timeout ?? 3000;
	return debounce(listener, useTimeout, { maxWait: useTimeout * 1.5 });
}

export default createAutoSaveListener;
