import { gql } from "graphql-request";

export default gql`
	mutation shareSavedProjectMutation($savedProjectId: ID!) {
		shareSavedProject(savedProjectId: $savedProjectId) {
			url
			mediaUrl
		}
	}
`;

export type ShareSavedProjectMutation = {
	readonly shareSavedProject: {
		readonly url: string;
		readonly mediaUrl: string;
	};
};

export type ShareSavedProjectMutationVariables = {
	readonly savedProjectId: string;
};
