import React, { useMemo, useCallback, useEffect } from "react";
import type { ModalActionsProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { closeComplete } from "~/features/nav/store-slice.ts";
import { pushGtmData } from "~/features/analytics/index.ts";
import { selectOptionalOpenProject } from "~/features/workspace/selectors.ts";
import { isOnceOffLoaded } from "~/utils/loading.ts";
import { usePreventUnloadWarningCallback } from "~/context/before-unload.tsx";
import { useWebsiteUrl } from "~/context/website-url.tsx";
import { completeProject } from "./store-slice.ts";

type CompleteModalProps = {
	readonly show: boolean;
};

function CompleteModal({ show }: CompleteModalProps) {
	const dispatch = useAppDispatch();
	const status = useAppSelector((s) => s.order.completion);
	const onCancel = useCallback(() => {
		dispatch(closeComplete());
	}, [dispatch]);

	const openProject = useAppSelector(selectOptionalOpenProject);
	const missingResources = openProject?.build.operationsMissingSources ?? [];
	const hasMissingResources = missingResources.length > 0;
	useEffect(() => {
		if (!show) {
			return;
		}

		if (hasMissingResources) {
			return;
		}

		dispatch(
			pushGtmData({
				event: "Finish",
			}),
		);
		const action = dispatch(completeProject());
		return () => {
			action.abort();
		};
	}, [show, hasMissingResources, dispatch]);

	const addPath = (() => {
		if (!isOnceOffLoaded(status)) {
			return "";
		}

		// Shopify website format
		const params = new URLSearchParams();
		status.data.forEach((item) => {
			params.append(
				"addProductItem",
				JSON.stringify({
					id: item.variantId,
					quantity: item.quantity,
					properties: item.properties,
				}),
			);
		});
		return `/pages/add-to-cart/?${params.toString()}`;
	})();
	const addUrl = useWebsiteUrl(addPath);

	const preventUnloadWarning = usePreventUnloadWarningCallback();
	const actions = useMemo((): ModalActionsProps["actions"] => {
		const cancel = {
			text: "Cancel",
			onClick: onCancel,
			type: "ghost" as const,
		};
		if (status.type === "error" || hasMissingResources) {
			return [cancel];
		}
		return [
			status.type === "loading"
				? {
						text: "Building design...",
						disabled: true,
					}
				: {
						text: "Add to cart",
						href: addUrl,
						onMouseDown: preventUnloadWarning,
					},
			cancel,
		];
	}, [onCancel, hasMissingResources, status, addUrl, preventUnloadWarning]);

	return (
		<Modal title="Creating product" show={show} onClose={onCancel}>
			<Modal.Text>
				We&apos;re creating the blueprints for your design. Once we&apos;re done
				you can add it to your cart
			</Modal.Text>
			{status.type === "error" && (
				<Modal.Text className="form-error">{status.message}</Modal.Text>
			)}
			{missingResources.length > 0 && (
				<Modal.Text className="form-error">
					Some AI filters are still running ({missingResources.join(", ")}). One
					these are completed we can add to cart. (Or you can turn them off if
					you no longer want them)
				</Modal.Text>
			)}
			<Modal.Actions actions={actions} />
		</Modal>
	);
}

export default CompleteModal;
