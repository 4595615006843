import { gql } from "graphql-request";

const createBrickArtDesignFromSavedProjectMutation = gql`
	mutation createBrickArtDesignFromSavedProjectMutation(
		$id: ID!
		$title: String!
		$description: String!
		$collectionShopifyIds: [ID!]!
		$tags: [String!]!
	) {
		createBrickArtDesignFromSavedProject(
			id: $id
			title: $title
			description: $description
			collectionShopifyIds: $collectionShopifyIds
			tags: $tags
		)
	}
`;

type CreateBrickArtDesignFromSavedProjectMutation = {
	readonly createBrickArt: true;
};

type CreateBrickArtDesignFromSavedProjectVariables = {
	readonly id: string;
	readonly title: string;
	readonly description: string;
	readonly collectionShopifyIds: readonly string[];
	readonly tags: readonly string[];
};

export type {
	CreateBrickArtDesignFromSavedProjectMutation,
	CreateBrickArtDesignFromSavedProjectVariables,
};
export default createBrickArtDesignFromSavedProjectMutation;
