const isSupported = (() => {
  try {
    if (typeof WebAssembly === "object"
      && typeof WebAssembly.instantiate === "function") {
      const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
      if (module instanceof WebAssembly.Module)
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
  } catch (e) {
    console.error("Open CV not supported", e)
  }
  return false;
})();

function notSupportedFunc() {
  console.error("Open CV not supported");
}

const notSupportedStub = {
  COLOR_BGRA2BGR: -1,
  COLOR_BGR2BGRA: -1,

  matFromImageData: notSupportedFunc,
  cvtColor: notSupportedFunc,
  bitwise_not: notSupportedFunc,
  bilateralFilter: notSupportedFunc
}

export { isSupported, notSupportedStub };
