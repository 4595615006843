import type { ReactNode } from "react";
import React from "react";
import { useField } from "formik";
import FormError from "./form-error.tsx";

type FormItemProps = {
	readonly name: string;
	readonly hint?: string;
	readonly children: ReactNode;
};

function FormItem({ name, children, hint }: FormItemProps) {
	const [, { error, touched }] = useField(name);
	return (
		<div>
			{children}
			{hint && <em className="form-hint">{hint}</em>}
			{error && touched && <FormError>{error}</FormError>}
		</div>
	);
}

export default FormItem;
