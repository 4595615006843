import { gql } from "graphql-request";
import type { BaseplateSize } from "@brickme/project-core/src";

const getCreatorPicPricesQuery = gql`
	query getCreatorPicCountryPricesQuery($countryCode: String!) {
		creatorPicCountryPrices(countryCode: $countryCode) {
			prices {
				colourPremium
				basePlateSize
				size

				colourPremiumRatio
				discountRatio

				price
			}
		}
	}
`;

type CreatorPicPrice = {
	readonly colourPremium: boolean;
	readonly size: Readonly<[number, number]>;
	readonly basePlateSize: BaseplateSize;

	readonly colourPremiumRatio: string;
	readonly discountRatio: string;

	readonly price: string;
};

type CreatorPicPrices = {
	readonly prices: readonly CreatorPicPrice[];
};

type GetCreatorPicPricesQuery = {
	readonly creatorPicCountryPrices: CreatorPicPrices;
};

type GetCreatorPicPricesVariables = {
	readonly countryCode: string;
};

export type { GetCreatorPicPricesQuery, GetCreatorPicPricesVariables };
export default getCreatorPicPricesQuery;
