import { gql } from "graphql-request";
import type { TransportPricingModel } from "@brickme/project-core/src";

type GetPricingModelQuery = {
	readonly pricingModel: TransportPricingModel;
};

const getPricingModelQuery = gql`
	query getPricingModelQuery {
		pricingModel {
			numColoursPremiumThreshold
			numColoursPremium
			discountBrackets {
				areaMetres
				discount
			}
			basePlateSizePrices {
				size
				price {
					amount
					currencyCode
				}
			}
		}
	}
`;

export type { GetPricingModelQuery };
export default getPricingModelQuery;
