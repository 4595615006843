import type { GenericSize, GenericPosition } from "../model/geom.ts";
import type { Distance } from "../model/index.ts";
import { distanceFromMetres, distanceFromMillimeters } from "../model/index.ts";
import type { PictureConfiguration } from "../model/picture.ts";
import { realWorldBrickSize } from "../model/picture.ts";

type SceneImage = {
	readonly imageSrc: string;
	readonly realWorldSize: GenericSize<Distance>;
	readonly mountBoundsRatios: {
		readonly centrePosition: GenericPosition<number>;
		readonly size: GenericSize<number>;
	};
};

type Scene = {
	readonly id: string;
	readonly name: string;
	readonly fullSize: SceneImage;
	readonly mobileSize: SceneImage;
	readonly minPictureRealWorldSize?: GenericSize<Distance>;
	readonly allowForBrickArt: boolean;
};

const scenes: readonly Scene[] = [
	{
		id: "living-room-1",
		name: "Living room 1",
		allowForBrickArt: true,
		fullSize: {
			imageSrc: "scenes/Living Room 1.jpg",
			realWorldSize: {
				width: distanceFromMetres(4.5),
				height: distanceFromMetres(3.112),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.485,
					y: 0.32,
				},
				size: {
					width: 0.47,
					height: 0.45,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Living Room 1.jpg",
			realWorldSize: {
				width: distanceFromMetres((3.1 * 1106) / 1383),
				height: distanceFromMetres(3.1),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.485,
					y: 0.32,
				},
				size: {
					width: 0.96,
					height: 0.45,
				},
			},
		},
	},
	{
		id: "baby-room",
		name: "Baby room",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Baby room.jpg",
			realWorldSize: {
				width: distanceFromMetres(5.5),
				height: distanceFromMetres(4.125),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.3,
				},
				size: {
					width: 0.96,
					height: 0.51,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Baby room.jpg",
			realWorldSize: {
				width: distanceFromMetres(3.3),
				height: distanceFromMetres(4.125),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.3,
				},
				size: {
					width: 0.96,
					height: 0.51,
				},
			},
		},
	},
	{
		id: "bedroom",
		name: "Bedroom",
		allowForBrickArt: true,
		fullSize: {
			imageSrc: "scenes/Bedroom.jpg",
			realWorldSize: {
				width: distanceFromMetres(4),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.265,
				},
				size: {
					width: 0.96,
					height: 0.46,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Bedroom.jpg",
			realWorldSize: {
				width: distanceFromMetres(2.4),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.265,
				},
				size: {
					width: 0.96,
					height: 0.46,
				},
			},
		},
	},
	{
		id: "boardroom",
		name: "Boardroom",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Boardroom.jpg",
			realWorldSize: {
				width: distanceFromMetres(6.5),
				height: distanceFromMetres(4),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.57,
					y: 0.438,
				},
				size: {
					width: 0.53,
					height: 0.38,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Boardroom.jpg",
			realWorldSize: {
				width: distanceFromMetres(2.88),
				height: distanceFromMetres(3.6),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.52,
					y: 0.43,
				},
				size: {
					width: 0.95,
					height: 0.39,
				},
			},
		},
	},
	{
		id: "dining-room",
		name: "Dining room",
		allowForBrickArt: true,
		fullSize: {
			imageSrc: "scenes/Dining room.jpg",
			realWorldSize: {
				width: distanceFromMetres(5.5),
				height: distanceFromMetres(4.125),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.32,
				},
				size: {
					width: 0.96,
					height: 0.56,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Dining room.jpg",
			realWorldSize: {
				width: distanceFromMetres((999 * 4) / 1250),
				height: distanceFromMetres(4),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.32,
				},
				size: {
					width: 0.96,
					height: 0.56,
				},
			},
		},
	},
	{
		id: "empty-room",
		name: "Empty room",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Empty room.jpg",
			realWorldSize: {
				width: distanceFromMetres(6),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.505,
					y: 0.445,
				},
				size: {
					width: 0.6,
					height: 0.695,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Empty room.jpg",
			realWorldSize: {
				width: distanceFromMetres((3 * 800) / 1000),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.505,
					y: 0.445,
				},
				size: {
					width: 0.96,
					height: 0.695,
				},
			},
		},
	},
	{
		id: "kids-room",
		name: "Kids room",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Kids room.jpg",
			realWorldSize: {
				width: distanceFromMetres(4.5),
				height: distanceFromMetres(3.375),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.31,
				},
				size: {
					width: 0.96,
					height: 0.53,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Kids room.jpg",
			realWorldSize: {
				width: distanceFromMetres((3.1 * 1000) / 1250),
				height: distanceFromMetres(3.1),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.31,
				},
				size: {
					width: 0.96,
					height: 0.53,
				},
			},
		},
	},
	{
		id: "kids-room-pink",
		name: "Kids room pink",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Kids room pink.jpg",
			realWorldSize: {
				width: distanceFromMetres(5),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.56,
					y: 0.3,
				},
				size: {
					width: 0.495,
					height: 0.55,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Kids room pink.jpg",
			realWorldSize: {
				width: distanceFromMetres((3 * 960) / 1200),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.56,
					y: 0.3,
				},
				size: {
					width: 0.96,
					height: 0.55,
				},
			},
		},
	},
	{
		id: "brick-wall",
		name: "Brick wall",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Brick Wall.jpg",
			realWorldSize: {
				width: distanceFromMetres(8),
				height: distanceFromMetres(5.34),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.5,
				},
				size: {
					width: 0.97,
					height: 0.97,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Brick Wall.jpg",
			realWorldSize: {
				width: distanceFromMetres((5.34 * 1000) / 1250),
				height: distanceFromMetres(5.34),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.5,
				},
				size: {
					width: 0.97,
					height: 0.97,
				},
			},
		},
		minPictureRealWorldSize: {
			width: distanceFromMetres(2),
			height: distanceFromMetres(2),
		},
	},
	{
		id: "living-room-2",
		name: "Living room 2",
		allowForBrickArt: true,
		fullSize: {
			imageSrc: "scenes/Living Room 2.jpg",
			realWorldSize: {
				width: distanceFromMetres(4.5),
				height: distanceFromMetres(3.375),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.528,
					y: 0.418,
				},
				size: {
					width: 0.656,
					height: 0.425,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Living Room 2.jpg",
			realWorldSize: {
				width: distanceFromMetres((3.3 * 1000) / 1250),
				height: distanceFromMetres(3.3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.418,
				},
				size: {
					width: 0.96,
					height: 0.425,
				},
			},
		},
	},
	{
		id: "living-room-blue",
		name: "Living room blue",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Living room blue.jpg",
			realWorldSize: {
				width: distanceFromMetres(6.5),
				height: distanceFromMetres(3.6595),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.464,
					y: 0.313,
				},
				size: {
					width: 0.4935,
					height: 0.465,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Living room blue.jpg",
			realWorldSize: {
				width: distanceFromMetres((3.6 * 900) / 1125),
				height: distanceFromMetres(3.6),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.464,
					y: 0.313,
				},
				size: {
					width: 0.96,
					height: 0.465,
				},
			},
		},
	},
	{
		id: "living-room-dark-grey",
		name: "Living room dark grey",
		allowForBrickArt: true,
		fullSize: {
			imageSrc: "scenes/Living room dark grey.jpg",
			realWorldSize: {
				width: distanceFromMetres(5.5),
				height: distanceFromMetres(3.09375),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.35,
				},
				size: {
					width: 0.45,
					height: 0.61,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Living room dark grey.jpg",
			realWorldSize: {
				width: distanceFromMetres((3 * 900) / 1125),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.35,
				},
				size: {
					width: 0.96,
					height: 0.61,
				},
			},
		},
	},
	{
		id: "living-room-green",
		name: "Living room green",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Living room green.jpg",
			realWorldSize: {
				width: distanceFromMetres(5),
				height: distanceFromMetres(3),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.4715,
					y: 0.296,
				},
				size: {
					width: 0.42,
					height: 0.5,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Living room green.jpg",
			realWorldSize: {
				width: distanceFromMetres((2.9 * 960) / 1200),
				height: distanceFromMetres(2.9),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.4715,
					y: 0.296,
				},
				size: {
					width: 0.85,
					height: 0.5,
				},
			},
		},
	},
	{
		id: "office",
		name: "Office",
		allowForBrickArt: false,
		fullSize: {
			imageSrc: "scenes/Office.jpg",
			realWorldSize: {
				width: distanceFromMetres(6.5),
				height: distanceFromMetres(4),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.377,
				},
				size: {
					width: 0.7875,
					height: 0.389,
				},
			},
		},
		mobileSize: {
			imageSrc: "scenes/mobile/Office.jpg",
			realWorldSize: {
				width: distanceFromMetres((3.9 * 960) / 1200),
				height: distanceFromMetres(3.9),
			},
			mountBoundsRatios: {
				centrePosition: {
					x: 0.5,
					y: 0.377,
				},
				size: {
					width: 0.96,
					height: 0.4,
				},
			},
		},
	},
];

// Atm there's an assumption that if compatible with full size then mobile setup will be compatible
function getCompatibleScenes(
	picture: Pick<PictureConfiguration, "numberOfBricks">,
): readonly Scene[] {
	const pictureRealWorldSize = {
		width: distanceFromMillimeters(
			picture.numberOfBricks.width * realWorldBrickSize.mm,
		),
		height: distanceFromMillimeters(
			picture.numberOfBricks.height * realWorldBrickSize.mm,
		),
	};
	return scenes.filter((s) => {
		const mountMaxSize = {
			width: distanceFromMillimeters(
				s.fullSize.realWorldSize.width.mm *
					s.fullSize.mountBoundsRatios.size.width,
			),
			height: distanceFromMillimeters(
				s.fullSize.realWorldSize.height.mm *
					s.fullSize.mountBoundsRatios.size.height,
			),
		};
		if (
			s.minPictureRealWorldSize &&
			pictureRealWorldSize.width.mm < s.minPictureRealWorldSize.width.mm &&
			pictureRealWorldSize.height.mm < s.minPictureRealWorldSize.height.mm
		) {
			return false;
		}
		return (
			mountMaxSize.width.mm >= pictureRealWorldSize.width.mm &&
			mountMaxSize.height.mm >= pictureRealWorldSize.height.mm
		);
	});
}

export type { Scene, SceneImage };
export { scenes, getCompatibleScenes };
