import { gql } from "graphql-request";

const switchGuestProjectToUserMutation = gql`
	mutation switchGuestProjectToUserMutation($id: ID!) {
		switchGuestProjectToUser(id: $id)
	}
`;

type SwitchGuestProjectToUserMutation = unknown;

type SwitchGuestProjectToUserVariables = {
	readonly id: string;
};

export type {
	SwitchGuestProjectToUserMutation,
	SwitchGuestProjectToUserVariables,
};
export default switchGuestProjectToUserMutation;
