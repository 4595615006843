/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import {
	uint8ArrayAllocUnsafe,
	readUInt32BE,
	writeUInt32BE,
} from "../utils/uint8-array.ts";
import type { Bitmap } from "./types.ts";
import { bitmapChannels } from "./types.ts";

// Saves about 7ms on 4x3 32 zoom 2 compared to image.cloneQuiet
// Basically just a direct port of code from jimp with some small optimisations
function* cropBitmap<T extends Bitmap>(
	image: T,
	x: number,
	y: number,
	w: number,
	h: number,
): Generator<undefined, T> {
	if (process.env.NODE_ENV === "development") {
		if (w !== Math.round(w) || h !== Math.round(h)) {
			throw new Error(`Crop expects int width height ${w}x${h}`);
		}
	}

	if (x === 0 && w === image.width) {
		const start = (w * y + x) << 2;
		const end = (start + h * w) << 2;

		return {
			...image,
			data: image.data.slice(start, end),
			width: w,
			height: h,
		};
	}

	const bitmap = uint8ArrayAllocUnsafe(w * h * bitmapChannels);
	let offset = 0;

	// Note: take reference just to be safe (modify the underlying data around the place
	// even though i don't think they do for this example)
	const { width, data } = image;
	for (let yi = y; yi < y + h; yi++) {
		for (let xi = x; xi < x + w; xi++) {
			const idx = (width * yi + xi) << 2;
			const pixel = readUInt32BE(data, idx);
			writeUInt32BE(bitmap, pixel, offset);
			offset += 4;
		}
		yield;
	}

	return {
		...image,
		data: bitmap,
		width: w,
		height: h,
	};
}

export default cropBitmap;
