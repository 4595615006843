import React from "react";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import type { TransportPictureConfiguration } from "@brickme/project-core/src";
import { basePlateSizeAbbreviation } from "@brickme/project-core/src";
import fallbackImage from "./saved-design-fallback.jpg";

type SavedDesignCellProps = {
	readonly design: {
		readonly currentPicture: TransportPictureConfiguration & {
			readonly buildNumberOfColours: number;
		};
		readonly mediumImageUrl: string | null | undefined;
		readonly updatedAt: string;
	};
	readonly status?: "disabled" | "current";
	readonly onClick: () => void;
	readonly onDeleteClick: () => void;
};

function SavedDesignCell({
	design,
	status,
	onClick,
	onDeleteClick,
}: SavedDesignCellProps) {
	const { basePlateSize, numberOfBasePlates, buildNumberOfColours } =
		design.currentPicture;
	const updatedAt = DateTime.fromISO(design.updatedAt);
	return (
		<div className="saved-design">
			<button type="button" onClick={onClick} disabled={!!status}>
				<img
					src={design.mediumImageUrl ?? fallbackImage}
					alt={design.updatedAt}
					loading="lazy"
					decoding="async"
				/>
				<div className="saved-design-label">
					{basePlateSizeAbbreviation(basePlateSize)}, {numberOfBasePlates.width}
					x{numberOfBasePlates.height},{" "}
					{pluralize("colour", buildNumberOfColours, true)}
				</div>
				<div className="saved-design-label">
					Saved on {updatedAt.toFormat("dd/LL")}{" "}
					{updatedAt.toLocaleString(DateTime.TIME_WITH_SECONDS)}
				</div>
			</button>
			{status === "current" ? (
				<div className="saved-design-label">Currently open</div>
			) : (
				<button type="button" className="subtitle" onClick={onDeleteClick}>
					<span
						className="button__text"
						style={{ color: "#8a8a8a", textDecoration: "underline" }}
					>
						Delete
					</span>
				</button>
			)}
		</div>
	);
}

export default SavedDesignCell;
