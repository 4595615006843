import type { Image } from "canvas";
import { loadImage } from "canvas";
import { isEqual } from "lodash-es";
import type { Bitmap } from "../bitmap/index.ts";
import { imageDataToBitmap } from "../bitmap/index.ts";
import type { EncodedImage } from "../utils/encoded-image.ts";
import createCanvasContext2d from "../utils/create-canvas-context-2d.ts";
import type { GenericSize } from "../model/index.ts";

function drawImageToMaxSizedImageData(
	image: Image,
	maxResolution: number,
): ImageData {
	const xScale = maxResolution / image.naturalWidth;
	const yScale = maxResolution / image.naturalHeight;
	const useScale = Math.min(1, Math.max(xScale, yScale));
	const width = image.naturalWidth * useScale;
	const height = image.naturalHeight * useScale;
	const ctx = createCanvasContext2d(width, height);
	ctx.drawImage(image, 0, 0, width, height);
	return ctx.getImageData(0, 0, width, height) as ImageData;
}

async function loadBitmapAndDataFromUrlToMaxSize(
	src: string,
	maxResolution: number,
) {
	try {
		const image = await loadImage(src, { crossOrigin: "anonymous" });
		const imageData = drawImageToMaxSizedImageData(image, maxResolution);
		return {
			bitmap: imageDataToBitmap(imageData),
			originalSize: {
				width: image.naturalWidth,
				height: image.naturalHeight,
			},
		};
	} catch (e) {
		console.error(e);
		throw new Error("There was an error reading your image file");
	}
}

async function loadBitmapFromUrlToMaxSize(
	src: string,
	maxResolution: number,
): Promise<Bitmap> {
	const { bitmap } = await loadBitmapAndDataFromUrlToMaxSize(
		src,
		maxResolution,
	);
	return bitmap;
}

async function readFileAsDataUrl(file: File): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			const result = event.target?.result;
			if (typeof result !== "string") {
				reject(new Error("We were unable to read this image file"));
				return;
			}

			resolve(result);
		};
		fileReader.onerror = reject;
		fileReader.readAsDataURL(file);
	});
}

async function readFileAsBuffer(file: File): Promise<ArrayBuffer> {
	return new Promise<ArrayBuffer>((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			const result = event.target?.result;
			if (!result || !(result instanceof ArrayBuffer)) {
				reject(new Error("We were unable to read this image file"));
				return;
			}

			resolve(result);
		};
		fileReader.onerror = reject;
		fileReader.readAsArrayBuffer(file);
	});
}

async function parseUploadedFileToBitmap(file: File, maxResolution: number) {
	const dataUrl = await readFileAsDataUrl(file);
	return loadBitmapAndDataFromUrlToMaxSize(dataUrl, maxResolution);
}

type ParsedUploadedFile = {
	readonly bitmap: Bitmap;
	readonly originalEncodedImage: EncodedImage;
	readonly originalSize: GenericSize<number>;
};

const fileTypeHeaders = [
	{
		mimeType: "image/png",
		header: new Uint8Array([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]),
	},
	{
		mimeType: "image/jpeg",
		header: new Uint8Array([0xff, 0xd8, 0xff]),
	},
];

// Mime reported by File is unreliable and have seen some say it's based on extension only.
// We check for a match of both file type and buffer header to be sure. Okay to have some
// misses here because then it will result in us just re-encoding the image which is fine.
function determineFileType(file: File, buffer: ArrayBuffer) {
	const byteMatch = fileTypeHeaders.find(({ mimeType, header }) => {
		return (
			mimeType === file.type &&
			isEqual(new Uint8Array(buffer.slice(0, header.length)), header)
		);
	});
	if (byteMatch) {
		return byteMatch.mimeType;
	}
	return "application/octet-stream";
}

async function parseUploadedFile(
	file: File,
	maxResolution: number,
): Promise<ParsedUploadedFile> {
	// TODO: Is there a way to use array buffer to render to canvas so don't have to read twice?
	const [{ bitmap, originalSize }, buffer] = await Promise.all([
		parseUploadedFileToBitmap(file, maxResolution),
		readFileAsBuffer(file),
	]);
	return {
		bitmap,
		originalSize,
		originalEncodedImage: {
			buffer,
			mimeType: determineFileType(file, buffer),
		},
	};
}

export type { ParsedUploadedFile };
export { loadBitmapFromUrlToMaxSize, parseUploadedFile };
