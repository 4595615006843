import { isEqual } from "lodash-es";
import { z } from "zod";
import type { DetailFilter, OptionsList } from "./picture.ts";
import { pictureConfigurationSchema } from "./picture.ts";
import { unixTimestampSchema } from "./time.ts";

// const bitmapSchema: z.Schema<Bitmap> = z.object({
//   data: z.any() as z.Schema<>,
//   width: z.number().int().positive(),
//   height: z.number().int().positive(),
// });

// const userImageProjectSourceSchema = z.object({
//   type: z.literal("userImage"),
//   image: bitmapSchema,
//   fileName: z.string(),
// });

// type UserImageProjectSource = z.infer<typeof userImageProjectSourceSchema>;

// const projectSourceSchema = userImageProjectSourceSchema
//   .or(
//     z.object({
//       type: z.literal("libraryImage"),
//       id: z.string(),
//       image: bitmapSchema,
//       fileName: z.string(),
//     })
//   )
//   .or(
//     z.object({
//       type: z.literal("brickArtImage"),
//       image: bitmapSchema,
//       fileName: z.string(),
//     })
//   );

const faceBoundingBoxSchema = z.object({
	x: z.number().min(0).max(1),
	y: z.number().min(0).max(1),
	width: z.number().min(0).max(1),
	height: z.number().min(0).max(1),
	confidence: z.number().min(0).max(100).optional(),
	imageQuality: z.object({
		brightness: z.number().min(0).max(100).optional(),
		sharpness: z.number().min(0).max(100).optional(),
	}),
	pose: z.object({
		pitch: z.number().min(-180).max(180).optional(),
		roll: z.number().min(-180).max(180).optional(),
		yaw: z.number().min(-180).max(180).optional(),
	}),
});

type FaceBoundingBox = z.infer<typeof faceBoundingBoxSchema>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const projectSchema = z.object({
	currentPicture: pictureConfigurationSchema,
	otherVersions: z.array(pictureConfigurationSchema),
	updatedAt: unixTimestampSchema,
});

type Project = z.infer<typeof projectSchema>;

const baseEnhanceDetailOptions: OptionsList<DetailFilter | undefined> = [
	{ value: undefined, label: "None" },
	{
		value: {
			sigma: 60,
			radiusRatio: 0.025,
		},
		label: "Low",
	},
	{
		value: {
			sigma: 100,
			radiusRatio: 0.0444,
		},
		label: "Medium",
	},
	{
		value: {
			sigma: 120,
			radiusRatio: 0.06,
		},
		label: "High",
	},
];

function getEnhanceDetailOptions(
	current?: DetailFilter | null,
): OptionsList<DetailFilter | undefined> {
	if (!current) {
		return baseEnhanceDetailOptions;
	}
	if (baseEnhanceDetailOptions.some((o) => isEqual(o.value, current))) {
		return baseEnhanceDetailOptions;
	}
	// Custom handles detail filter settings that are no longer present
	return [
		...baseEnhanceDetailOptions,
		{
			value: current,
			label: "Custom",
		},
	];
}

export { getEnhanceDetailOptions, faceBoundingBoxSchema };
export type { Project, FaceBoundingBox };
