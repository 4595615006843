import type { PictureConfiguration } from "@brickme/project-core/src";
import { isEqual } from "lodash-es";
import type { PictureAddOnVariant } from "~/model.d.ts";

function getAddOnVariantCompatibleWithPicture(
	prices: readonly PictureAddOnVariant[],
	picture: Pick<PictureConfiguration, "basePlateSize" | "numberOfBasePlates">,
): PictureAddOnVariant | undefined {
	return prices.find(
		(p) =>
			p.basePlateSize === picture.basePlateSize &&
			(isEqual(picture.numberOfBasePlates, {
				width: p.basePlateDimensions[0],
				height: p.basePlateDimensions[1],
			}) ||
				isEqual(picture.numberOfBasePlates, {
					width: p.basePlateDimensions[1],
					height: p.basePlateDimensions[0],
				})),
	);
}

export { getAddOnVariantCompatibleWithPicture };
