import { gql } from "graphql-request";

export type GetPicToBrickByIdQuery = {
	readonly picToBrickById?: {
		readonly imageUrl: string;
	};
};

export type GetPicToBrickByIdVariables = {
	readonly id: string;
};

export default gql`
	query getPicToBrickByIdQuery($id: ID!) {
		picToBrickById(id: $id) {
			imageUrl
		}
	}
`;
