import type { ReactElement, MutableRefObject, ReactNode } from "react";
import React, { createContext, useRef, useMemo, useContext } from "react";

type BeforeUnloadValue = {
	readonly showWarningRef: MutableRefObject<boolean>;
	readonly preventUnloadWarning: () => void;
};

const Context = createContext<BeforeUnloadValue | undefined>(undefined);

function useBeforeUnloadContext(): BeforeUnloadValue {
	const value = useContext(Context);
	if (value === undefined) {
		throw new Error("No context found");
	}
	return value;
}

function usePreventUnloadWarningCallback(): () => void {
	return useBeforeUnloadContext().preventUnloadWarning;
}

function useShowUnloadWarningRef(): MutableRefObject<boolean> {
	return useBeforeUnloadContext().showWarningRef;
}

type BeforeUnloadProviderProps = {
	readonly children: ReactNode;
};

function BeforeUnloadProvider({
	children,
}: BeforeUnloadProviderProps): ReactElement {
	const showWarningRef = useRef<boolean>(true);
	const value = useMemo(
		() => ({
			preventUnloadWarning: () => {
				showWarningRef.current = false;
			},
			showWarningRef,
		}),
		[showWarningRef],
	);
	return <Context.Provider value={value}>{children}</Context.Provider>;
}

export {
	useShowUnloadWarningRef,
	BeforeUnloadProvider,
	usePreventUnloadWarningCallback,
};
