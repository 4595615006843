import type { Bitmap } from "../bitmap/index.ts";
import { bitmapChannels } from "../bitmap/index.ts";
import type { FaceBoundingBox } from "./project.ts";

type BitmapMask = {
	readonly foregroundBoundingBox: {
		readonly left: number;
		readonly right: number;
		readonly top: number;
		readonly bottom: number;
	};
	readonly foregroundRatio: readonly (readonly number[])[];
	readonly width: number;
	readonly height: number;
};

function createBitmapMaskFromBitmap({
	data,
	width,
	height,
}: Bitmap): BitmapMask {
	const foregroundRatio: number[][] = new Array<number[]>(width);
	let boundingLeft = width - 1;
	let boundingRight = 0;
	let boundingTop = height - 1;
	let boundingBottom = 0;

	for (let x = 0; x < width; x++) {
		foregroundRatio[x] = new Array<number>(height);
		for (let y = 0; y < height; y++) {
			const idx = (width * y + x) << 2;

			// Assume each pixel the same because grayscale
			const pixelForegroundRatio = data[idx] / 255;

			foregroundRatio[x][y] = pixelForegroundRatio;

			if (pixelForegroundRatio !== 0) {
				if (x < boundingLeft) {
					boundingLeft = x;
				}
				if (x > boundingRight) {
					boundingRight = x;
				}
				if (y < boundingTop) {
					boundingTop = y;
				}
				if (y > boundingBottom) {
					boundingBottom = y;
				}
			}
		}
	}

	return {
		width,
		height,
		foregroundRatio,
		foregroundBoundingBox: {
			left: Math.min(boundingLeft, boundingRight),
			top: Math.min(boundingTop, boundingBottom),
			right: boundingRight,
			bottom: boundingBottom,
		},
	};
}

function createFaceBoundingBoxMatcher(
	{ width, height }: Pick<BitmapMask, "width" | "height">,
	faceBoundingBoxes: readonly FaceBoundingBox[],
) {
	if (faceBoundingBoxes.length === 0) {
		return () => true;
	}

	const faceBoundingBoxPositions = faceBoundingBoxes.map((bb) => {
		const x = Math.round(bb.x * width);
		const y = Math.round(bb.y * height);
		return {
			x,
			y,
			right: Math.round((bb.x + bb.width) * width),
			bottom: Math.round((bb.y + bb.height) * height),
		};
	});

	return (x: number, y: number) =>
		faceBoundingBoxPositions.some(
			(bb) => x >= bb.x && x <= bb.right && y >= bb.y && y <= bb.bottom,
		);
}

// TODO: Could be optimised based on bg mask bounding masks
function createFacesMask(
	backgroundMask: BitmapMask,
	faceBoundingBoxes: readonly FaceBoundingBox[],
): BitmapMask {
	if (faceBoundingBoxes.length === 0) {
		return backgroundMask;
	}

	const { width, height, foregroundBoundingBox } = backgroundMask;

	// If there's no foreground then use the whole face area
	if (
		foregroundBoundingBox.left >= foregroundBoundingBox.right ||
		foregroundBoundingBox.top >= foregroundBoundingBox.bottom
	) {
		return {
			width,
			height,
			foregroundRatio: new Array<number[]>(width).fill(
				new Array<number>(height).fill(1),
			),
			foregroundBoundingBox: {
				left: 0,
				top: 0,
				right: width - 1,
				bottom: height - 1,
			},
		};
	}

	const isWithinMask = createFaceBoundingBoxMatcher(
		backgroundMask,
		faceBoundingBoxes,
	);

	const foregroundRatio: number[][] = new Array<number[]>(width);
	let boundingLeft = width - 1;
	let boundingRight = 0;
	let boundingTop = height - 1;
	let boundingBottom = 0;

	// TODO: For more efficiency could get one of these from previous bitmap mask.
	// note: could save a columnIndex on data structure to help
	const emptyColumn = Array<number>(height).fill(0);

	const midColForegroundInitial = [
		...Array<number>(backgroundMask.foregroundBoundingBox.top).fill(0),
		...Array<number>(
			backgroundMask.foregroundBoundingBox.bottom -
				backgroundMask.foregroundBoundingBox.top -
				1,
		).fill(0),
		...Array<number>(
			height - backgroundMask.foregroundBoundingBox.bottom - 1,
		).fill(0),
	];

	for (let x = 0; x < width; x++) {
		if (
			x < backgroundMask.foregroundBoundingBox.left ||
			x > backgroundMask.foregroundBoundingBox.right
		) {
			foregroundRatio[x] = emptyColumn;
			continue;
		}

		foregroundRatio[x] = midColForegroundInitial.slice();
		for (
			let y = backgroundMask.foregroundBoundingBox.top;
			y <= backgroundMask.foregroundBoundingBox.bottom;
			y++
		) {
			// Assume each pixel the same because grayscale
			const pixelForegroundRatio = isWithinMask(x, y)
				? backgroundMask.foregroundRatio[x][y]
				: 0;

			foregroundRatio[x][y] = pixelForegroundRatio;

			if (pixelForegroundRatio !== 0) {
				if (x < boundingLeft) {
					boundingLeft = x;
				}
				if (x > boundingRight) {
					boundingRight = x;
				}
				if (y < boundingTop) {
					boundingTop = y;
				}
				if (y > boundingBottom) {
					boundingBottom = y;
				}
			}
		}
	}

	return {
		width,
		height,
		foregroundRatio,
		foregroundBoundingBox: {
			// left: Math.min(boundingLeft, boundingRight),
			// top: Math.min(boundingTop, boundingBottom),
			left: boundingLeft,
			top: boundingTop,
			right: boundingRight,
			bottom: boundingBottom,
		},
	};
}

function* iterateBitmapMaskForegroundGenerator(
	{ foregroundBoundingBox, foregroundRatio, width }: BitmapMask,
	op: (foregroundRatio: number, index: number) => void,
	yieldSize: number,
) {
	if (yieldSize % bitmapChannels !== 0) {
		throw new Error(`yield size must be divisible by bitmapchannels`);
	}
	for (
		let y = foregroundBoundingBox.top;
		y <= foregroundBoundingBox.bottom;
		y++
	) {
		for (
			let x = foregroundBoundingBox.left;
			x <= foregroundBoundingBox.right;
			x++
		) {
			const idx = (width * y + x) << 2;
			const ratio = foregroundRatio[x][y];
			if (ratio === 0) {
				continue;
			}

			op(ratio, idx);
			if (idx % yieldSize === 0) {
				yield;
			}
		}
	}
}

export {
	createFacesMask,
	iterateBitmapMaskForegroundGenerator,
	createBitmapMaskFromBitmap,
};
export type { BitmapMask };
