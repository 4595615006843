import type { ReactElement } from "react";
import React from "react";
import Modal from "~/components/modal.tsx";

type ConfirmResetProjectModalProps = {
	readonly show: boolean;
	readonly onConfirm: () => void;
	readonly onCancel: () => void;
};

export default function ConfirmResetProjectModal({
	show,
	onConfirm,
	onCancel,
}: ConfirmResetProjectModalProps): ReactElement {
	return (
		<Modal show={show} title="WARNING!" onClose={onCancel}>
			<Modal.Text>
				Are you sure you want to reset your changes? This will remove any
				changes you&apos;ve made to your image.
			</Modal.Text>
			<Modal.Actions
				actions={[
					{ text: "Cancel", onClick: onCancel, type: "ghost" },
					{ text: "Reset", onClick: onConfirm },
				]}
			/>
		</Modal>
	);
}
