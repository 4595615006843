import React from "react";
import loadingWebp from "./loading.webp";
import loadingApng from "./loading.apng";
import "./loading-animation.scss";

type LoadingAnimationProps = {
	readonly size?: number;
	readonly children?: string;
};

function LoadingAnimation({ size = 150, children }: LoadingAnimationProps) {
	return (
		<div className="loading-animation">
			<picture>
				<source srcSet={loadingWebp} type="image/webp" />
				<source srcSet={loadingApng} type="image/apng" />
				<img
					src={loadingWebp}
					width={size}
					height={size}
					alt="Loading"
					decoding="async"
					loading="lazy"
				/>
			</picture>
			{children}
		</div>
	);
}

export default LoadingAnimation;
